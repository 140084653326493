import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import useSWR from 'swr'
import { authStateI } from './Context/AuthContext'
import { UserInterface } from './Interfaces'
require('dotenv').config({ path: './src/.env' }) // Pour DEV en dehors du docker

const API_URL: string = process.env.REACT_APP_API_URL || 'http://localhost:3333'

export default function useAuth(): [
	authStateI,
	(val: authStateI) => void,
	() => void,
	(jwt: string) => void,
	() => void
] {
	let history = useHistory()
	let localAuth: string | null = localStorage.getItem('authState')
	let currentAuth: authStateI = localAuth != null ? JSON.parse(localAuth) : { connected: false }
	const [request, setRequest] = useState<string | null>(null)
	const [auth, setAuth] = useState<authStateI>(currentAuth)
	const setAuthCookie = (newAuthState: authStateI) => {
		setAuth(newAuthState)
		localStorage.setItem('authState', JSON.stringify(newAuthState))
	}

	// delete la connexion (déconnection)
	const clearAuth = () => {
		console.info('Déconnection')
		history.push('/')
		// clear authstate
		setAuth({ connected: false })
		// remove cookie
		localStorage.removeItem('authState')
	}

	const jwtCallback = (jwt: string) => {
		setAuthCookie({ connected: true, jwtoken: jwt })
	}

	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + auth.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					clearAuth()
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())
			.then((r) => {
				return r
			})

	const setUserData = (user: UserInterface) => {
		setAuthCookie({ ...auth, user: user })
	}
	const { data: user, error, mutate } = useSWR<UserInterface>(request, fetcher)

	useEffect(() => {
		setRequest(auth.connected && auth.jwtoken != undefined ? `${API_URL}/users/me` : null)
		if (user) setUserData(user)
	}, [user, auth.connected, auth.jwtoken])

	const setAuthTwo = (val: authStateI) => {
		console.log('EXTERNAL SET AUTH')
	}

	return [auth, setAuthTwo, clearAuth, jwtCallback, mutate]
}
