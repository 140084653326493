import { makeStyles, useMediaQuery } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { authContext } from '../../Context/AuthContext'
import { ClubInterface, ClubPost } from '../../Interfaces'
import HomeDesktopLayout from './HomeTab/HomeDesktopLayout'
import HomeMobileLayout from './HomeTab/HomeMobileLayout'
import ClubInfo from './Clubs/ClubInfo'

interface UrlParams {
	clubParam: string
	postParam: string
}

export default function HomeTab() {
	const useStyles = makeStyles({
		leftSideContainer: {
			position: 'sticky'
		}
	})
	const classes = useStyles()
	let { clubParam, postParam } = useParams<UrlParams>()

	const smallScreen = useMediaQuery('(max-width:600px)')
	const biggerScreen = useMediaQuery('(min-width:1000px)')

	const contextAuth = useContext(authContext)

	const [preSelectedsClub, setPreSelectedsClub] = useState<ClubInterface | undefined>(undefined)
	const [preSelectedPost, setPreSelectedPost] = useState<ClubPost | undefined>(undefined)

	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	// Requetes pour clubs
	const { data: zones, error: zonesError } = useSWR<ClubInterface[]>(
		`${contextAuth.apiUrl}/clubs/all?adminBde=true`,
		fetcher
	)
	// request pour le club dans l'url
	const { data: preZone, error: preZoneError } = useSWR<ClubInterface>(
		clubParam && `${contextAuth.apiUrl}/clubs/search/${clubParam}`,
		fetcher
	)
	const { data: prePost, error: prePostError } = useSWR<ClubPost>(
		postParam && `${contextAuth.apiUrl}/news/posts?post=${postParam}`,
		fetcher
	)

	useEffect(() => {
		if (clubParam !== undefined && zones !== undefined && preZone !== undefined) {
			let filteredClubs: ClubInterface[] = zones.filter((zone) => zone.id === preZone.id)
			if (filteredClubs.length === 1) setPreSelectedsClub(filteredClubs[0])
		}

		if (postParam !== undefined && prePost) {
			setPreSelectedPost(prePost)
		}

		if (postParam === undefined && clubParam === undefined && zones !== undefined) {
			let filteredClubs: ClubInterface[] = zones.filter((zone) => zone.name === 'BDE Eseo')
			if (filteredClubs.length === 1) setPreSelectedsClub(filteredClubs[0])
		}
	}, [zones, preZone, prePost])

	const [clubSelected, setClubSelected] = useState<ClubInterface>()

	let location = useLocation()
	let history = useHistory()
	if (location.pathname == '/home') {
		// replace au lieu de push pour pouvoir utiliser le go back du navigateur
		history.replace('/home/news')
	}

	return (
		<div>
			<Helmet>
				<title>{`BDE ESEO`}</title>
			</Helmet>

			<Route
				path={'/home/news'}
				render={() => (
					<Switch>
						{biggerScreen ? (
							<HomeDesktopLayout
								clubs={zones}
								setClubSelected={setClubSelected}
								preSelectedClub={preSelectedsClub}
								preSelectedPost={preSelectedPost}
							/>
						) : (
							<HomeMobileLayout
								clubs={zones}
								preSelectedClub={preSelectedsClub}
								preSelectedPost={preSelectedPost}
							/>
						)}
					</Switch>
				)}
			/>

			<Route
				path={'/home/infos/:club'}
				render={() => <ClubInfo clubSelected={clubSelected} />}
			/>
		</div>
	)
}
