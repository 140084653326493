import React, { useEffect, useState } from 'react';
import ClubPreview from '../../../Components/Clubs/ClubPreview';
import { ClubInterface, ClubPost } from '../../../Interfaces';
import './News.css';
import NewsFeed from './NewsFeed';
import NewsLeftSideBar from './NewsLeftSideBar';

interface NewsDesktopLayout{
    clubs: ClubInterface[] | undefined
    preSelectedClub?: ClubInterface
    preSelectedPost?: ClubPost
}

export default function NewsDesktopLayout(Props: NewsDesktopLayout) {

    const emptyClub: ClubInterface = {
        "name": "",
        "id": '',
        "president": { displayName: '', givenName: '', email: '', surname: '', id: '' },
        "description": "",
        "imagePresentation": null,
        "images": [],
        "members": [],
        "vps": [],
        "tresoriers": [],
        "secretaires": [],
        "shortPresentation": "",
        "recrute": false,
        "avatar": null,
        "contactUrl": ""
    }


    const [clubSelected, setClubSelected] = useState<ClubInterface>(emptyClub)

    useEffect(() => {
        if(Props.preSelectedClub) setClubSelected(Props.preSelectedClub)
        // if (postParam) console.log("PostParam: " + postParam)
    }, [Props.preSelectedClub])


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        }}>
            <div className="oneThirdContainer">
                <NewsLeftSideBar clubs={Props.clubs} clubSelected={clubSelected} setClubSelected={setClubSelected}/>
            </div>

            <div className="oneThirdContainer flex-center">
                <NewsFeed club={Props.clubs !== undefined && Props.clubs.length > 0 ? clubSelected : emptyClub} preSelectedPost={Props.preSelectedPost}/>
            </div>
            
            <div className="oneThirdContainer zonePreviewContainer">
                <ClubPreview loaded={Props.clubs !== undefined} club={Props.clubs !== undefined && Props.clubs.length > 0 ? clubSelected : emptyClub}/>
            </div>
        </div>
    )
}