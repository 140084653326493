import React from 'react'
import { ClubInterface } from '../../Interfaces'
import ClubPreview from './ClubPreview'

interface ClubsGridProps {
  clubs: ClubInterface[] | undefined
  setClubSelected: (val: ClubInterface) => void
}

const ClubsGrid: React.FC<ClubsGridProps> = ({
	clubs,
	setClubSelected
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'row',
				gap: '30px'
			}}
		>
			{clubs?.map((club, i) => (
				<ClubPreview
					key={i}
					club={club}
					loaded={true}
					setClubSelected={(val: ClubInterface) => setClubSelected(val)}
				/>
			))}
		</div>
	)
}

export default ClubsGrid
