import React from 'react'

interface PostPreviewTitre{
    children?: React.ReactNode,
}

export default function PostPreviewTitre(Props: PostPreviewTitre){
    return(
        <p style={{
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: 'Product Sans'
        }}>
            {Props.children}
        </p>
    )
}