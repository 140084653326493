import { SwipeableDrawer } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { styleContext } from '../../../Context/StyleContext'
import { ClubInterface, ClubPost } from '../../../Interfaces'
import './News.css'
import NewsFeed from './NewsFeed'
import NewsLeftSideBar from './NewsLeftSideBar'

interface NewsMobileLayout {
	clubs: ClubInterface[] | undefined
	preSelectedClub?: ClubInterface
	preSelectedPost?: ClubPost
}

export default function NewsMobileLayout(Props: NewsMobileLayout) {
	const emptyClub: ClubInterface = {
		name: '',
		id: '',
		president: { displayName: '', givenName: '', email: '', surname: '', id: '' },
		description: '',
		imagePresentation: null,
		images: [],
		members: [],
		vps: [],
		tresoriers: [],
		secretaires: [],
		shortPresentation: '',
		recrute: false,
		avatar: null,
		contactUrl: ''
	}
	const themeContext = useContext(styleContext)

	const [clubSelected, setClubSelected] = useState<ClubInterface>(emptyClub)

	useEffect(() => {
		if (Props.preSelectedClub) setClubSelected(Props.preSelectedClub)
		// if (postParam) console.log("PostParam: " + postParam)
	}, [Props.preSelectedClub])

	const [drawerOpened, setDrawerOpened] = useState<boolean>(false)

	return (
		<>
			<React.Fragment key={'left'}>
				<SwipeableDrawer
					anchor={'left'}
					open={drawerOpened}
					onClose={() => setDrawerOpened(false)}
					onOpen={() => setDrawerOpened(true)}
				>
					<NewsLeftSideBar
						clubs={Props.clubs}
						clubSelected={clubSelected}
						setClubSelected={setClubSelected}
						setDrawerState={setDrawerOpened}
					/>
				</SwipeableDrawer>
			</React.Fragment>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-evenly'
				}}
			>
				<div className="flex-center" style={{ width: '100%' }}>
					<NewsFeed
						mobile={true}
						club={
							Props.clubs !== undefined && Props.clubs.length > 0
								? clubSelected
								: emptyClub
						}
						preSelectedPost={Props.preSelectedPost}
						setDrawerState={setDrawerOpened}
					/>
				</div>
			</div>
		</>
	)
}
