import { Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Snackbar, Tabs, TextField, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SwipeableViews from 'react-swipeable-views';
import useSWR from 'swr';
import '../../../Assets/Fonts/fonts.css';
import ButtonType from '../../../Components/BoutonType';
import CommanderTabButton from '../../../Components/Cafeteria/CommanderTabButton';
import { authContext } from '../../../Context/AuthContext';
import { styleContext } from '../../../Context/StyleContext';
import './Commander.css';
import SubCommand from './SubCommand';

/**
 * Correspond a soit une boisson ou un accompagnement
 */
export interface Side {
    _id: string;
    name: string;
    available: boolean;
    type: string;
    price: number;
    createdAt: string;
    updatedAt: string;
    nombre: number
}

export interface Plat {
    _id: string;
    ingredients: Ingredient[];
    sauces: Ingredient[];
    name: string;
    description: string;
    available: boolean;
    price: number;
    createdAt: string;
    updatedAt: string;
}

export interface Ingredient {
    _id: string;
    chosen: boolean
    name: string;
    available: boolean;
    type: string;
    price: number;
    createdAt: string;
    updatedAt: string;
}

export interface Commande{
    boissons: Side[]
    accompagnements: Side[]
    plats: Plat[]
}

interface CommandeRequestContent {
    boissons: string[]
    accompagnements: string[]
    plats: PlatRequest[]
    additionalMessage: string
}
interface PlatRequest {
    id: string
    ingredients: string[]
    sauces: string[]
}

interface CafetState {
    state: boolean
}

export default function Commander(){
    // get depuis l'api
    const contextAuth = useContext(authContext)

    // définition du fetcher pour SWR
    const fetcher = (url: string) => fetch(url, {
        headers: new Headers({
            'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
        })
    }).then(r => { if (!r.ok) { throw new Error(r.statusText) } else { return r } }).then(r => r.json()).then(r => {
        return r
    })

    const { data: accompagnements, error: accompError } = useSWR<Side[]>(`${contextAuth.apiUrl}/cafet/allIngredients?type=accompagnement&available=true`, fetcher)
    const { data: boissons, error: boissonsError } = useSWR<Side[]>(`${contextAuth.apiUrl}/cafet/allIngredients?type=boisson&available=true`, fetcher)
    const { data: plats, error: platsError } = useSWR<Plat[]>(`${contextAuth.apiUrl}/cafet/allPlats?available=true`, fetcher)

    const { data: cafetOpened, error: cafetOpenedError } = useSWR<CafetState>(`${contextAuth.apiUrl}/cafet/getCafetState`, fetcher)

    // logique de commande
    const [commande, setCommande] = useState<Commande>({
        boissons: [],
        accompagnements: [],
        plats: [],
    })

    const [boissonsBag, setBoissonsBag] = useState<Side[]>([])
    const [accompagnementsBag, setAccompagnementsBag] = useState<Side[]>([])
    const [platsBag, setPlatsBag] = useState<Plat[]>([])
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [additionalMessage, setAdditionalMessage] = useState<string>('')

    const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false)

    const ajouterElement = (type: 'boissons' | 'plats' | 'accompagnements', elementSide?: Side, elementPlat?: Plat) => {
        console.log("add element")
        // ajout du plat au panier en fonction du type
        if (type === 'plats' && elementPlat !== undefined){
            setPlatsBag([...platsBag, elementPlat])
        }
        else if (type === 'accompagnements' && elementSide !== undefined) {
            setAccompagnementsBag([...accompagnementsBag, elementSide])
        }
        else if (elementSide !== undefined){
            setBoissonsBag([...boissonsBag, elementSide])
        }
        // refreshTotalPrice()
    }

    const getFirstIndex = (name: string, bag: Side[] | Plat[]): number => {
        for(let i = 0; i < bag.length; i++){
            if(bag[i].name === name){
                return i
            }
        }
        return 0
    }

    const removeElement = (type: 'boissons' | 'plats' | 'accompagnements', name: string) => {
        console.log("remove element")
        console.log({
            type,
            name
        })
        // remove plat au panier en fonction du type
        console.log(boissonsBag)
        let arrayTemp
        let itemIndex: number
        switch (type) {
            case 'boissons':
                arrayTemp = [...boissonsBag]
                itemIndex = getFirstIndex(name, boissonsBag)
                // arrayTemp = JSON.parse(JSON.stringify(boissonsBag))
                arrayTemp.splice(itemIndex, 1)
                setBoissonsBag(arrayTemp)
                console.log(arrayTemp)
                break;
            case 'plats':
                arrayTemp = [...platsBag]
                // arrayTemp = JSON.parse(JSON.stringify(platsBag))
                itemIndex = getFirstIndex(name, platsBag)
                arrayTemp.splice(itemIndex, 1)
                setPlatsBag(arrayTemp)
                break;
            default:
                arrayTemp = [...accompagnementsBag]
                itemIndex = getFirstIndex(name, accompagnementsBag)
                // arrayTemp = JSON.parse(JSON.stringify(accompagnementsBag))
                arrayTemp.splice(itemIndex, 1)
                setAccompagnementsBag(arrayTemp)
                break;
        }
        // refreshTotalPrice()
    }

    const refreshTotalPrice = () => {
        console.log("refresh price")
        let tempPrice: number = 0
        boissonsBag.forEach((boisson) => tempPrice += boisson.price)
        accompagnementsBag.forEach((accompagnement) => tempPrice += accompagnement.price)
        platsBag.forEach((plat) => {
            tempPrice += plat.price
            let chosenIngredients: number = plat.ingredients.filter(ingredient => ingredient.chosen).length
            if (chosenIngredients > 4) tempPrice += 0.8 * (chosenIngredients - 4)
        })
        setTotalPrice(tempPrice)
    }

    const addIngredientToPlat = (platIndex: number, ingredientIndex: number, type: 'ingredient' | 'sauce') => {
        // add ingredient to play correspond a check la condition "chosen"
        console.log("CHOSEN: ", platIndex, ingredientIndex)
        console.log(platsBag[platIndex].ingredients[ingredientIndex])
        console.log(platsBag[platIndex].ingredients)
        let arrayTemp = [...platsBag]
        console.dir(arrayTemp)
        switch (type) {
            case 'ingredient':
                arrayTemp[platIndex].ingredients[ingredientIndex].chosen = !arrayTemp[platIndex].ingredients[ingredientIndex].chosen
                break;
            default:
                arrayTemp[platIndex].sauces[ingredientIndex].chosen = !arrayTemp[platIndex].sauces[ingredientIndex].chosen
                break;
        }
        console.log(arrayTemp[platIndex].ingredients[ingredientIndex].chosen)
        setPlatsBag(arrayTemp)
    }

    const buildCommandRequest = (): CommandeRequestContent => {
        let boissonsID: string[] = boissonsBag.map((boisson) => boisson._id)
        let accompagnementsID: string[] = accompagnementsBag.map((accompagnement) => accompagnement._id)
        let platsR: PlatRequest[] = platsBag.map((plat) => {
            return{
                id: plat._id,
                ingredients: plat.ingredients.filter(ingredient => ingredient.chosen).map(ing => ing._id),
                sauces: plat.sauces.filter(sauce => sauce.chosen).map(ing => ing._id)
            }
        })
        return{
            boissons: boissonsID,
            accompagnements: accompagnementsID,
            plats: platsR,
            additionalMessage: additionalMessage
        }
    }

    const [alertSnackbar, setAlertSnackbar] = useState<string>('')

    const [successSnackbar, setSuccessSnackbar] = useState<string>('')

    const executeCommand = () => {
        let content: CommandeRequestContent = buildCommandRequest()
        console.log(JSON.stringify(content))
        fetch(`${contextAuth.apiUrl}/cafet/addCommande`, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(content)
        }).then(async function (response) {
            if(response.status !== 200){

                let data: {message: string} = await response.json()
                setAlertSnackbar('Erreur lors de la commande: ' + data.message)
            }else{
                setSuccessSnackbar('Commande enregistrée!')
            }
        })
    }

    const handleTextChange = (event: Object) => {
        //@ts-ignore
        setAdditionalMessage(event.target.value)
    }

    const themeContext = useContext(styleContext)

    const [value, setValue] = useState(0);
    
    useEffect(() => {
        refreshTotalPrice()
    }, [platsBag, accompagnementsBag, boissonsBag])

    const smallScreen = useMediaQuery('(max-width:600px)');

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexDirection: smallScreen ? 'column' : 'row',
            alignItems: 'flex-start',
            paddingTop: smallScreen ? 0 : 40,
            justifyContent: 'left',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: 'auto',
            },
            [theme.breakpoints.up('md')]: {
                width: '85%',
                marginLeft: '15%',
            },
        },
        indicator: {
            backgroundColor: themeContext.theme.primary
        },
        dialogRoot: {
            color: themeContext.theme.textColor,

        }
    }))
    const classes = useStyles()

    return(
        <Container classes={{root: classes.container}}>
            <Helmet>
                <title>{`BDE ESEO | COMMANDE`}</title>
            </Helmet>
            <Container style={{ flex: 1}}>
                <Tabs 
                    style={{ minWidth: 'fit-content'}}
                    orientation={smallScreen ? "horizontal" : "vertical"} 
                    value={value} 
                    classes={{indicator: classes.indicator}}
                    onChange={(event, newValue) => setValue(newValue)}>
                    <CommanderTabButton index={1} currentSelection={value + 1} label="Plats" onClick={() => setValue(0)} labelPosition={smallScreen ? "under" : "right"}/>
                    <CommanderTabButton index={2} currentSelection={value + 1} label="Boissons" onClick={() => setValue(1)} labelPosition={smallScreen ? "under" : "right"}/>
                    <CommanderTabButton index={3} currentSelection={value + 1} label="Accompagnements" onClick={() => setValue(2)} labelPosition={smallScreen ? "under" : "right"}/>
                    <CommanderTabButton index={4} currentSelection={value + 1} label="Confirmation" onClick={() => setConfirmationDialog(true)} disabled={totalPrice === 0 || cafetOpened?.state === false} labelPosition={smallScreen ? "under" : "right"}/>
                </Tabs>
                {!smallScreen &&
                    <div style={{textAlign: 'right', paddingRight: 40}}>
                        Total: {totalPrice}€
                    </div>
                }
            </Container>
            <div style={{overflowX: 'hidden', width: '100%'}}>
                <SwipeableViews
                    disableLazyLoading={true}
                    axis={'x'}
                    index={value} onChangeIndex={(val) => setValue(val)}>
                    <SubCommand type='plats' plats={plats} platsBag={platsBag} removeElement={removeElement} ajouterElement={ajouterElement} commande={commande} addIngredientToPlat={addIngredientToPlat}/>
                    <SubCommand type='boissons' sides={boissons} sidesBag={boissonsBag} removeElement={removeElement} ajouterElement={ajouterElement} commande={commande}/>
                    <SubCommand type='accompagnements' sides={accompagnements} sidesBag={accompagnementsBag} removeElement={removeElement} ajouterElement={ajouterElement} commande={commande}/>
                </SwipeableViews>
                {smallScreen &&
                    <div style={{ textAlign: 'right', paddingRight: 40, fontSize: 25 }}>
                        Total: {totalPrice}€
                    </div>
                }
            </div>
            <Dialog
                open={confirmationDialog}
                onClose={() => setConfirmationDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{root: classes.dialogRoot}}
            >
                <DialogTitle id="alert-dialog-title">Confirmer la commande?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Une fois confirmée la commande ne peut être annulée.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Un message?"
                        type="text"
                        fullWidth
                        onChange={handleTextChange}
                    />
                </DialogContent>
                <DialogActions style={{width: '70%', alignSelf: 'flex-end'}}>
                    <ButtonType onClick={() => setConfirmationDialog(false)}>
                    Annuler
                    </ButtonType>
                    <ButtonType onClick={() => {
                            setConfirmationDialog(false)
                            executeCommand()
                        }}>
                        Confirmer
                    </ButtonType>
                </DialogActions>
            </Dialog>

            <Snackbar open={cafetOpened !== undefined ? !cafetOpened?.state : false}>
                <Alert severity="error" variant="filled">
                    La cafet est fermée, revenez plus tard!
                </Alert>
            </Snackbar>
            <Snackbar open={alertSnackbar !== ''} autoHideDuration={4000} onClose={() => setAlertSnackbar('')}>
                <Alert onClose={() => setAlertSnackbar('')} severity="error" variant="filled">
                    {alertSnackbar}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbar !== ''} autoHideDuration={4000} onClose={() => setSuccessSnackbar('')}>
                <Alert onClose={() => setSuccessSnackbar('')} severity="success" variant="filled">
                    {successSnackbar}
                </Alert>
            </Snackbar>
        </Container>
    )
}