import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	FormControl,
	makeStyles,
	Snackbar,
	Typography
} from '@material-ui/core'
import { Create } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import useSWR, { mutate } from 'swr'
import ButtonType from '../../../Components/BoutonType'
import { Ingredient } from '../../../Components/Cafeteria/IngredientsTable'
import PlatCreator from '../../../Components/Cafeteria/PlatCreator'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'

export interface Plat {
	_id: string
	name: string
	description: string
	price: number
	available: boolean
	ingredients: Ingredient[]
	stock: number
}

export default function Plats() {
	const themeContext = useContext(styleContext)
	const useStyles = makeStyles((theme) => ({
		cardRoot: {
			textAlign: 'left',
			padding: '20px',
			filter: themeContext.theme.name === 'dark' ? 'invert(1)' : 'invert(0)',
			backgroundColor: 'transparent',
			boxShadow: 'none',
			[theme.breakpoints.down('md')]: {
				width: '90vw'
			},
			[theme.breakpoints.up('sm')]: {
				width: '70vw'
			},
			marginTop: '10px'
		},
		typoFont: {
			marginBottom: '4px'
		},
		textFieldRoot: {
			'&.Mui-focused fieldset': {
				// borderColor: 'green',
				borderColor: themeContext.theme.primary
			}
		},
		divider: {
			marginTop: '8px',
			marginBottom: '8px'
		},
		bullet: {
			display: 'inline-block',
			margin: '0 2px',
			transform: 'scale(0.8)'
		}
	}))
	const classes = useStyles()
	const contextAuth = useContext(authContext)
	const [dialogOpened, setDialogOpened] = useState<boolean>(false)
	const [stringError, setStringError] = useState<string | undefined>()
	const [creatorType, setCreatorType] = useState<'editing' | 'creating'>('creating')
	const [currentEditing, setCurrentEditing] = useState<Plat>()

	//middleware request
	const adminPost = (url: string, options: any, method: string) =>
		fetch(`${contextAuth.apiUrl}${url}`, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			method: method,
			body: new URLSearchParams(options)
		}).then((r) => {
			if (!r.ok) {
				setStringError("Erreur lors de l'action: " + r.statusText)
				console.error(r.statusText)
			} else {
				mutate(`${contextAuth.apiUrl}/cafet/allIngredients`)
				mutate(`${contextAuth.apiUrl}/cafet/allPlats`)
				return r
			}
		})

	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	const { data: plats, error: platsError } = useSWR<Plat[]>(
		`${contextAuth.apiUrl}/cafet/allPlats`,
		fetcher
	)

	const bullet = <span className={classes.bullet}>•</span>

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Helmet>
				<title>{`CAFET | Plats`}</title>
			</Helmet>
			<Card classes={{ root: classes.cardRoot }}>
				<Typography variant="h4">Plats</Typography>
				<FormControl style={{ width: 'inherit', marginTop: '12px' }}>
					<Typography
						variant="caption"
						style={{
							marginBottom: '16px',
							lineHeight: 2,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						Changements appliqués en temps réel.
					</Typography>
					<ButtonType
						onClick={() => {
							setDialogOpened(true)
							setCreatorType('creating')
						}}
						style={{ alignSelf: 'left', minWidth: 200 }}
					>
						<Typography variant="subtitle2">Ajouter un plat</Typography>
						<Create />
					</ButtonType>

					<Divider classes={{ root: classes.divider }} />
					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							flexWrap: 'wrap'
						}}
					>
						{plats?.map((plat, index) => (
							<Card
								style={{
									minWidth: 275,
									width: 'max-content',
									margin: 10,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between'
								}}
								variant="outlined"
							>
								<CardContent>
									<Typography variant="h5" component="h2">
										{plat.name}
									</Typography>
									<Typography
										style={{ fontSize: 14 }}
										gutterBottom
										color="textSecondary"
									>
										Prix: {plat.price}€ | stock: {plat.stock}
									</Typography>
									<Typography variant="body2" gutterBottom>
										{plat.description}
									</Typography>
									<Typography variant="body2" gutterBottom>
										Ingrédients possibles:
										{plat.ingredients.map((ingredient, index) => (
											<div>
												{bullet} {ingredient.name}{' '}
												{ingredient.available
													? '(Disponible)'
													: '(Non disponible)'}
											</div>
										))}
									</Typography>
								</CardContent>
								<CardActions>
									<Button
										size="small"
										onClick={() => {
											setCreatorType('editing')
											setCurrentEditing(plat)
											setDialogOpened(true)
										}}
									>
										Editer
									</Button>
									<Button
										size="small"
										onClick={() => {
											adminPost(
												'/cafet/removePlat',
												{
													id: plat._id
												},
												'DELETE'
											)
										}}
									>
										Supprimer
									</Button>
								</CardActions>
							</Card>
						))}
					</div>
				</FormControl>
			</Card>

			<PlatCreator
				onClose={() => setDialogOpened(false)}
				opened={dialogOpened}
				type={creatorType}
				adminPost={adminPost}
				plat={currentEditing}
			/>

			<Snackbar
				open={stringError !== undefined}
				autoHideDuration={2000}
				onClose={() => setStringError(undefined)}
			>
				<Alert
					onClose={() => setStringError(undefined)}
					severity="warning"
					variant="filled"
				>
					{stringError}
				</Alert>
			</Snackbar>
		</div>
	)
}
