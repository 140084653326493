import React, { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import useSWR from 'swr'
import './App.css'
import './Assets/Fonts/fonts.css'
import { authContext } from './Context/AuthContext'
import { styleContext, styleContextI } from './Context/StyleContext'
import HomeNotConnected from './pages/Home/HomeNotConnected'
import './pages/Home/HomeParent'
import Home from './pages/Home/HomeParent'
import light from './themes/light'
import useAuth from './useAuth'
require('dotenv').config({ path: './src/.env' }) // Pour DEV en dehors du docker

const API_URL: string = process.env.REACT_APP_API_URL || 'http://localhost:3333'

/**
 * Entry point, type de props vide
 */
export default function App() {
	const [theme, setTheme] = useState(light)

	const toggleTheme = () => {
		let newTheme: styleContextI = light
		setTheme(newTheme)
		// sauvegarde dans les cookies
		localStorage.setItem('theme', newTheme.name)
	}
	const [auth, setAuth, clearAuth, jwtCallback, mutate] = useAuth()

	const {
		data: voteState,
		error: votesError,
		mutate: mutateVotes
	} = useSWR<boolean>(`${API_URL}/votes/getVoteState`, fetcher)

	let history = useHistory()
	useEffect(() => {
		let pathname: string = history.location.pathname
		let isJwt: boolean = pathname.split('/')[1] === 'jwt'
		console.log(isJwt ? 'Ecran jwt' : 'pas ecran jwt')
		if (isJwt) jwtCallback(pathname.split('/')[2])
	}, [history])

	return (
		<styleContext.Provider value={{ theme: theme, toggle: toggleTheme }}>
			<styleContext.Consumer>
				{(themeContext) => (
					<authContext.Provider
						value={{
							authState: auth,
							setAuth: setAuth,
							clearAuth: clearAuth,
							apiUrl: API_URL,
							mutateAuth: mutate,
							mutateVotes: mutateVotes,
							votesOpened: voteState || false
						}}
					>
						<div
							className="App"
							style={{
								height: '100%',
								backgroundColor: themeContext.theme.backgroundColor
							}}
						>
							<authContext.Consumer>
								{(value) => (
									<ConnectCheck
										connected={value.authState.connected}
										isBde={value.authState.user?.isBde}
									/>
								)}
							</authContext.Consumer>
						</div>
					</authContext.Provider>
				)}
			</styleContext.Consumer>
		</styleContext.Provider>
	)
}

if (process.env.NODE_ENV === 'production') {
	console.log = function () {}
}

/**
 * Cette fonction est simplement une fonction qui redirige vers l'home de présentation ou l'home de l'app
 * @param param0
 */
function ConnectCheck({ connected, isBde = false }: { connected: boolean; isBde?: boolean }) {
	if (connected)
		return (
			<div>
				<Home />
			</div>
		)
	else return <Route path="" component={HomeNotConnected} />
}

const fetcher = (url: string) =>
	fetch(url, {})
		.then((r) => {
			if (!r.ok) {
				throw new Error(r.statusText)
			} else {
				return r
			}
		})
		.then((r) => r.json())
		.then((r) => {
			return r.state
		})
