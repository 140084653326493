import { GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { authContext } from '../../Context/AuthContext'
import { FileUpload } from '../../Interfaces'
import FullImageModal from '../FullImageModal'

interface ImageGallery{
    images: FileUpload[]
    delete?: (file: FileUpload) => void
}

export default function ImageGallery(Props: ImageGallery){
    const contextAuth = useContext(authContext)
    const [imageOpened, setImageOpened] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    const imageClickHandler = (event: React.MouseEvent, index: number) => {
        setSelectedIndex(index)
        event.stopPropagation()
        setImageOpened(true)
    }

    return(
        <GridList cellHeight={140} cols={2} style={{margin: 15}}>
            {Props.images.map((image, index) => (
                <GridListTile key={index} cols={1}>
                    <img src={contextAuth.apiUrl + image.url} alt={image.name} onClick={(event) => imageClickHandler(event, index)} 
                    style={{
                        cursor: 'pointer',
                        width: '320',
                        height: 'auto',
                        objectFit: 'contain',
                    }}/>
                    {Props.delete !== undefined && 
                        <GridListTileBar
                            style={{backgroundColor: 'transparent'}}
                            actionIcon={Props.delete !== undefined &&
                                <IconButton aria-label={'Supprimer'} onClick={() => {if(Props.delete !== undefined) Props.delete(image)}}>
                                    <DeleteOutline color={'secondary'}/>
                                </IconButton>
                            }
                        />
                    }
                </GridListTile>
            ))}
            <FullImageModal images={Props.images} opened={imageOpened} onClose={() => setImageOpened(false)} selectedIndex={selectedIndex} onChange={(index) => setSelectedIndex(index)}/>
        </GridList>
    )
}