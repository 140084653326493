import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Route, useHistory, useLocation } from 'react-router-dom'
import useSWR from 'swr'
import { authContext } from '../../Context/AuthContext'
import { ClubInterface } from '../../Interfaces'
import './Clubs.css'
import ClubAdmin from './Clubs/ClubAdmin'
import ClubInfo from './Clubs/ClubInfo'
import Header from '../../Components/Header'
import ClubsGrid from '../../Components/Clubs/ClubsGrid'

export default function Clubs() {
	let location = useLocation()
	let history = useHistory()
	if (location.pathname == '/clubs') {
		// replace au lieu de push pour pouvoir utiliser le go back du navigateur
		history.replace('/clubs/all')
	}

	const contextAuth = useContext(authContext)

	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	// Requetes pour clubs
	const { data: allClubs, error: allError } = useSWR<ClubInterface[]>(
		`${contextAuth.apiUrl}/clubs/all?adminBde=true`,
		fetcher
	)

	// hook selected pour passer les infos a clubInfo sans refaire de requete
	const [clubSelected, setClubSelected] = useState<ClubInterface>()

	const [randomizedClubs, setRandomizedClubs] = useState<ClubInterface[] | undefined>(undefined)

	useEffect(() => {
		// Déclencher le tri aléatoire lorsque les données changent
		if (allClubs) {
			//remove Administation
			const filteredClubs = allClubs.filter((club) => club.name !== 'Administration')
			const sortedClubs = [...filteredClubs].sort(() => Math.random() - 0.5)
			setRandomizedClubs(sortedClubs)
		}
	}, [allClubs])

	return (
		<div>
			<Helmet>
				<title>{`BDE ESEO | CLUBS`}</title>
			</Helmet>

			{/* simple regex pour la route match */}
			<Route
				path={'/clubs/all'}
				render={() => (
					<div
						className="page"
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '30px',
							padding: '30px 10vw'
						}}
					>
						<Header title="Clubs" subtitle="Découvrir les nombreux clubs" />
						<ClubsGrid clubs={randomizedClubs} setClubSelected={setClubSelected} />
					</div>
				)}
			/>

			{/* route pour les infos spec d'un club */}
			<Route
				path={'/clubs/infos/:club'}
				render={() => <ClubInfo clubSelected={clubSelected} />}
			/>
			{/* route pour l'administration d'un club */}
			<Route
				path={'/clubs/admin/:club'}
				render={() => <ClubAdmin clubSelected={clubSelected} />}
			/>
		</div>
	)
}
