import React from 'react'
import { UserInterface } from '../Interfaces'
require('dotenv').config({path: './src/.env'}) // Pour DEV en dehors du docker

export interface authContextI {
	authState: authStateI
	setAuth: Function
	clearAuth: Function
	apiUrl: string
	votesOpened: boolean
	mutateAuth: Function
	mutateVotes: Function
}

let baseContext: authContextI = {
	authState: { connected: false },
	votesOpened: false,
	setAuth: () => {},
	clearAuth: () => {},
	mutateAuth: () => {},
	mutateVotes: () => {},
	apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3333'
}

export const authContext = React.createContext(baseContext)

// interface pour le contexte global
export interface authStateI {
	connected: boolean
	jwtoken?: string
	user?: UserInterface
}
