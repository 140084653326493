import {
	Collapse,
	Divider,
	Fade,
	IconButton,
	InputAdornment,
	OutlinedInput
} from '@material-ui/core'
import { Delete, Reply, SendOutlined } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { authContext } from '../../Context/AuthContext'
import { PostComment } from '../../Interfaces'
import Upvotes from './Upvotes'

interface Commentaire {
	commentaire: PostComment
	mutate: () => void
}

export default function Commentaire(Props: Commentaire) {
	let commentDate = new Date(Props.commentaire.createdAt)

	const contextAuth = useContext(authContext)

	const [replyOpened, setReplyOpened] = useState<boolean>(false)
	const [replyContent, setReplyContent] = useState<string>('')

	const [shiftPressed, setShiftPressed] = useState<boolean>(false)
	const [enterPressed, setEnterPressed] = useState<boolean>(false)

	const handleReplyComment = () => {
		setReplyOpened(true)
	}

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.keyCode === 16) setShiftPressed(true)
		if (event.keyCode === 13) setEnterPressed(true)
		event.stopPropagation()
	}

	const handleKeyUp = (event: React.KeyboardEvent) => {
		if (event.keyCode === 16) setShiftPressed(false)
		if (event.keyCode === 13) setEnterPressed(false)
		event.stopPropagation()
	}

	useEffect(() => {
		if (shiftPressed && enterPressed) setReplyContent(replyContent + '\r\n')
		else if (enterPressed) handleEnvoiComment()
	}, [shiftPressed, enterPressed])

	const handleEnvoiComment = () => {
		if (replyContent === '') return
		fetch(`${contextAuth.apiUrl}/news/createcomment`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken,
				'Content-Type': 'application/x-www-form-urlencoded'
			}),
			body: new URLSearchParams({
				isEnfant: 'true',
				post: Props.commentaire.post,
				parent: Props.commentaire.id,
				content: replyContent
			})
		})
			.then(() => setReplyOpened(false))
			.then(() => setReplyContent(''))
			.then(() => Props.mutate())
	}

	const handleDeleteComment = () => {
		fetch(`${contextAuth.apiUrl}/news/comment`, {
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken,
				'Content-Type': 'application/x-www-form-urlencoded'
			}),
			body: new URLSearchParams({
				type: 'delete',
				id: Props.commentaire.id
			})
		}).then(() => Props.mutate())
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginLeft: 25,
					marginRight: 25,
					marginTop: 10,
					marginBottom: 10,
					alignItems: 'flex-start'
				}}
			>
				{Props.commentaire.isEnfant && (
					<Divider
						orientation="vertical"
						flexItem
						style={{ marginRight: 15, marginLeft: 15 }}
					/>
				)}

				<div style={{ margin: 5 }}>
					<Upvotes
						score={Props.commentaire.upvotes}
						voteState={Props.commentaire.voteState}
						comment={Props.commentaire}
					/>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<p
							style={{
								fontFamily: 'Roboto',
								fontSize: 16,
								fontWeight: 'bold',
								margin: 0,
								marginRight: 5
							}}
						>
							{Props.commentaire.auteur.displayName + ' '}
						</p>
						<p
							style={{
								fontFamily: 'Roboto',
								fontSize: 16,
								fontWeight: 'normal',
								margin: 0
							}}
						>
							{`- ${commentDate.getDate()}/${
								commentDate.getMonth() + 1
							}/${commentDate.getFullYear()} - ${('0' + commentDate.getHours()).slice(
								-2
							)}:${('0' + commentDate.getMinutes()).slice(-2)}`}
						</p>
					</div>
					<div style={{ whiteSpace: 'break-spaces' }}>{Props.commentaire.content}</div>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						flexDirection: 'row',
						flex: 1
					}}
				>
					{Props.commentaire.auteur.id === contextAuth.authState.user?.id && (
						<IconButton onClick={handleDeleteComment}>
							<Delete />
						</IconButton>
					)}
					{!Props.commentaire.isEnfant && (
						<Fade in={!replyOpened}>
							<IconButton onClick={handleReplyComment}>
								<Reply />
							</IconButton>
						</Fade>
					)}
				</div>
			</div>
			<Collapse
				in={replyOpened}
				style={{
					alignSelf: 'center',
					width: '70%',
					marginBottom: 20
				}}
			>
				<OutlinedInput
					id="outlined-adornment-reply"
					type="text"
					style={{ width: '100%', borderRadius: 15, margin: 0 }}
					placeholder="Votre réponse"
					multiline
					value={replyContent}
					onKeyDown={handleKeyDown}
					onKeyUp={handleKeyUp}
					onChange={(event) => setReplyContent(event.target.value)}
					endAdornment={
						<InputAdornment position="end">
							<IconButton edge="end" onClick={handleEnvoiComment}>
								<SendOutlined />
							</IconButton>
						</InputAdornment>
					}
				/>
			</Collapse>
		</div>
	)
}
