import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { styleContext } from '../../Context/StyleContext'
import Appbar from '../../Components/Appbar/Appbar'
import Sidemenu from '../../Components/Appbar/Sidemenu'
import Cafeteria from '../Tabs/Cafeteria'
import Clubs from '../Tabs/Clubs'
import Plans from '../Tabs/Dashboard/Plans'
import MesInfos from '../Tabs/MesInfos'
import News from '../Tabs/News'
import Votes from '../Tabs/Votes'
import HomeTab from '../Tabs/HomeTab'
import Calendrier from '../Tabs/Calendrier'

/**
 * Ce composant est le daron de tout le site une fois connecté
 */
export default function Home() {
	const [largeur, setLargeur] = useState(window.innerWidth)

	const resizeEvent = () => {
		setLargeur(window.innerWidth)
	}

	window.addEventListener('resize', resizeEvent)

	//  si on est déjà connectés le jwt reste dans l'url donc on l'enleve là et en même temps on défini une route de base
	let location = useLocation()
	let history = useHistory()
	if (location.pathname === '/' || location.pathname.startsWith('/jwt/')) {
		history.push('/home')
	}

	return (
		<div>
			{largeur > 960 ? <Appbar /> : <Sidemenu />}
			<styleContext.Consumer>
				{(themeContext) => (
					<div
						style={{
							backgroundColor: themeContext.theme.backgroundColor,
							color: themeContext.theme.textColor,
							height: 'inherit'
						}}
					>
						<Helmet>
							<meta name="theme-color" content={themeContext.theme.primary}></meta>
						</Helmet>
						<Switch>
							<Route path={'/home'} render={() => <HomeTab />} />
							<Route path={'/calendrier'} render={() => <Calendrier />} />
							{/* route avec param si précisé */}
							<Route
								path={'/news/:clubParam/:postParam'}
								render={() => <News />}
								exact={false}
							/>
							<Route
								path={'/news/:clubParam'}
								render={() => <News />}
								exact={false}
							/>
							{/* route par défault */}
							<Route path={'/news'} render={() => <News />} exact={false} />
							<Route path={'/cafeteria'} render={() => <Cafeteria />} />
							<Route path={'/clubs'} render={() => <Clubs />} />
							<Route path={'/moi'} render={() => <MesInfos />} />
							<Route path={'/votes'} render={() => <Votes />} />
							<Route path={'/plans'} render={() => <Plans />} />
						</Switch>
					</div>
				)}
			</styleContext.Consumer>
		</div>
	)
}
