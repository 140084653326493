import {
	Divider,
	Fab,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Snackbar,
	Typography,
	useMediaQuery
} from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import useSWR from 'swr'
import ButtonType from '../../Components/BoutonType'
import { authContext } from '../../Context/AuthContext'
import { styleContext } from '../../Context/StyleContext'

export interface Liste {
	_id: string
	name: string
	__v: number
	createdAt: string
	type: string
	updatedAt: string
	votes: number
}

export default function Votes() {
	const themeContext = useContext(styleContext)
	const smallScreen = useMediaQuery('(max-width:600px)')
	const contextAuth = useContext(authContext)
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())
	const { data: Listes, error: ListesError } = useSWR<Liste[]>(
		`${contextAuth.apiUrl}/votes/getListes`,
		fetcher
	)

	const [fakeListe, setFakeListe] = useState<Liste>()
	const [currentListe, setListe] = useState<Liste>()
	const [message, setMessage] = useState<String>()

	const handleSubmit = () => {
		console.log(fakeListe, currentListe)
		if (fakeListe === undefined || currentListe === undefined) return
		var urlencoded = new URLSearchParams()
		urlencoded.append('fakeliste', fakeListe?._id)
		urlencoded.append('liste', currentListe?._id)
		fetch(`${contextAuth.apiUrl}/votes/sendVote`, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			body: urlencoded,
			method: 'POST'
		})
			.then((r) => r.json())
			.then((r) => {
				if (r.success) {
					setMessage('Vote envoyé.')
					contextAuth.mutateAuth()
				}
			})
	}

	const handleChangeVotes = (state: boolean) => {
		var urlencoded = new URLSearchParams()
		urlencoded.append('state', JSON.stringify(state))
		fetch(`${contextAuth.apiUrl}/votes/updateVotes`, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			body: urlencoded,
			method: 'POST'
		})
			.then((r) => r.json())
			.then((r) => {
				if (r.success) {
					contextAuth.mutateVotes()
				}
			})
	}
	if (!contextAuth.votesOpened && !contextAuth.authState.user?.calendrierAdmin) {
		return (
			<div>
				<Typography
					style={{
						color: themeContext.theme.primary,
						fontSize: 30,
						fontWeight: 'bold'
					}}
				>
					Les votes ne sont pas encore ouverts!
				</Typography>
			</div>
		)
	} else if (contextAuth.authState.user?.hasVoted) {
		return (
			<div>
				<Typography
					style={{
						color: themeContext.theme.primary,
						fontSize: 30,
						fontWeight: 'bold'
					}}
				>
					Vous avez déjà voté!
				</Typography>
				<Snackbar
					open={message !== undefined}
					autoHideDuration={2000}
					onClose={() => setMessage(undefined)}
				>
					<Alert
						onClose={() => setMessage(undefined)}
						severity="success"
						variant="filled"
					>
						{message}
					</Alert>
				</Snackbar>
				{/* bouton d'ouverture des votes */}
				{contextAuth.authState.user?.calendrierAdmin === true && (
					<Fab
						variant="extended"
						style={{
							position: 'fixed',
							right: 20,
							bottom: 20,
							padding: 0,
							zIndex: 600,
							backgroundColor: contextAuth.votesOpened ? 'green' : 'red'
						}}
					>
						<IconButton
							onClick={() => handleChangeVotes(!contextAuth.votesOpened)}
							style={{ color: 'white' }}
						>
							<Typography variant="caption">
								{contextAuth.votesOpened ? 'Ouverts' : 'Fermés'}
							</Typography>
							{contextAuth.votesOpened ? <Close /> : <Check />}
						</IconButton>
					</Fab>
				)}
			</div>
		)
	} else
		return (
			<form
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<Helmet>
					<title>{`BDE ESEO | VOTES`}</title>
				</Helmet>
				{/* page container */}
				<FormControl
					style={{
						width: smallScreen ? '100%' : '60%',
						minWidth: 400,
						alignItems: 'center'
					}}
				>
					{/* listes */}
					<Typography
						style={{
							color: themeContext.theme.primary,
							fontSize: 30,
							fontWeight: 'bold'
						}}
					>
						Votre vote de meilleure liste
					</Typography>
					<RadioGroup
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center'
						}}
					>
						{Listes?.filter((liste) => liste.type === 'liste').map((liste) => (
							<FormControlLabel
								value={liste._id}
								control={<Radio style={{ color: themeContext.theme.primary }} />}
								label={liste.name}
								labelPlacement="end"
								onClick={() => setListe(liste)}
							/>
						))}
					</RadioGroup>
					<Divider />
					{/* fake listes */}
					<Typography
						style={{
							color: themeContext.theme.primary,
							fontSize: 30,
							fontWeight: 'bold'
						}}
					>
						Votre vote de meilleure fakeliste
					</Typography>
					<RadioGroup
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center'
						}}
						value={fakeListe}
						onChange={(e) => console.log(e.target.value)}
					>
						{Listes?.filter((liste) => liste.type === 'fakeliste').map((liste) => (
							<FormControlLabel
								style={{
									color: themeContext.theme.textColor,
									fontSize: 15
								}}
								value={liste._id}
								control={<Radio style={{ color: themeContext.theme.primary }} />}
								label={liste.name}
								labelPlacement="end"
								onClick={() => setFakeListe(liste)}
							/>
						))}
					</RadioGroup>
					<ButtonType
						onClick={handleSubmit}
						disabled={fakeListe === undefined || currentListe === undefined}
					>
						Voter
					</ButtonType>
				</FormControl>

				{/* bouton d'ouverture des votes */}
				{contextAuth.authState.user?.calendrierAdmin === true && (
					<Fab
						variant="extended"
						style={{
							position: 'fixed',
							right: 20,
							bottom: 20,
							padding: 0,
							zIndex: 600,
							backgroundColor: contextAuth.votesOpened ? 'green' : 'red'
						}}
					>
						<IconButton
							onClick={() => handleChangeVotes(!contextAuth.votesOpened)}
							style={{ color: 'white' }}
						>
							<Typography variant="caption">
								{contextAuth.votesOpened ? 'Ouverts' : 'Fermés'}
							</Typography>
							{contextAuth.votesOpened ? <Close /> : <Check />}
						</IconButton>
					</Fab>
				)}
			</form>
		)
}
