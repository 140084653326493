import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CreatePost from '../../../Components/Home/CreatePost'
import SelectButton from '../../../Components/Home/SelectButton'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'
import { ClubInterface } from '../../../Interfaces'
import './Home.css'

interface HomeLeftSideBar {
	clubs: ClubInterface[] | undefined
	clubSelected: ClubInterface
	setClubSelected: (club: ClubInterface) => void
	setDrawerState?: (state: boolean) => void
}

export default function HomeLeftSideBar(Props: HomeLeftSideBar) {
	const themeContext = useContext(styleContext)

	const handleClickCat = (club: ClubInterface, index: number) => {
		// enlever le selected de toutes
		if (Props.setDrawerState !== undefined) {
			Props.setDrawerState(false)
		}
		Props.setClubSelected(club)
	}

	const handleClickAdd = (club: ClubInterface, index: number) => {
		setCreatePost(club)
		// if (Props.setDrawerState !== undefined) Props.setDrawerState(false)
		Props.setClubSelected(club)
	}
	const contextAuth = useContext(authContext)

	const [createPost, setCreatePost] = useState<ClubInterface | undefined>()

	const [clubsImportants, setClubsImportants] = useState<ClubInterface[]>()
	const [mesClubs, setMesClubs] = useState<ClubInterface[]>()
	const [clubsSuivis, setClubsSuivis] = useState<ClubInterface[]>()
	const [autreClubs, setAutreClubs] = useState<ClubInterface[]>()

	useEffect(() => {
		setClubsImportants(
			Props.clubs?.filter((club) => club.name === 'BDE Eseo' || club.name === 'Administration')
		)
		setMesClubs(Props.clubs?.filter((club) => club.isMember))
		setAutreClubs(Props.clubs?.filter((club) => !club.isMember))
		setClubsSuivis(Props.clubs?.filter((club) => club.suivi))
		if (Props.clubSelected.name === '' && Props.clubs !== undefined)
			Props.setClubSelected(Props.clubs[0])
	}, [Props.clubs])

	const boutonSelection = (club: ClubInterface, index: number) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', width: 275 }} key={club.id}>
				<Link
					to={`/news/${club.name}`}
					style={{ margin: '7px', width: club.canPost ? '80%' : '100%' }}
				>
					<SelectButton
						style={{ width: '100%' }}
						texte={club.name}
						onClick={() => handleClickCat(club, index)}
						selected={club.id === Props.clubSelected.id}
					/>
				</Link>

				{club.canPost && (
					<SelectButton
						style={{ margin: '7px', width: '20%', padding: 0, textAlign: 'center' }}
						texte={'+'}
						onClick={() => handleClickAdd(club, index)}
						selected={false}
					/>
				)}
			</div>
		)
	}

	return (
		<div className="sideBarContainer">
			<p className="sideBarSectionTitre" style={{ color: themeContext.theme.primary }}>
				Le plus important
			</p>
			{clubsImportants?.map(boutonSelection)}
			<p className="sideBarSectionTitre" style={{ color: themeContext.theme.primary }}>
				Mes clubs
			</p>
			{mesClubs?.map((club, index) => boutonSelection(club, index + 2))}
			<p className="sideBarSectionTitre" style={{ color: themeContext.theme.primary }}>
				Clubs suivis
			</p>
			{clubsSuivis?.map((club, index) =>
				boutonSelection(club, index + (mesClubs?.length || 0) + 2)
			)}
			<p className="sideBarSectionTitre" style={{ color: themeContext.theme.primary }}>
				Tous
			</p>
			{autreClubs?.map((club, index) =>
				boutonSelection(
					club,
					index + (mesClubs?.length || 0) + (clubsSuivis?.length || 0) + 2
				)
			)}
			{createPost !== undefined && (
				<CreatePost
					opened={createPost !== undefined}
					onClose={() => setCreatePost(undefined)}
					selectedClub={createPost}
				/>
			)}
		</div>
	)
}
