import React from 'react'
import { COLORS } from '../../constants'

interface PastilleLinkInterface {
	url?: string
	text: string
	image?: string
	onClick?: () => void
  icon?: JSX.Element
}

const PastilleLink: React.FC<PastilleLinkInterface> = ({ url, text, image, onClick, icon }) => {
	return (
		<a
			onClick={onClick}
			href={url}
			target="_blank"
			style={{
				backgroundColor: COLORS.gray,
				width: 'fit-content',
				color: COLORS.onBg,
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				padding: '8px 15px',
        gap: 8,
				borderRadius: 100,
        cursor: 'pointer',
				transition: 'all 0.1s ease-in-out',
			}}
			onMouseOver={(e) => {
				e.currentTarget.style.backgroundColor = COLORS.gray2
			}}
			onMouseOut={(e) => {
				e.currentTarget.style.backgroundColor = COLORS.gray
			}}
		>
			{image && (
				<img
					src={image}
					height={20}
					width={20}
					style={{borderRadius: 100 }}
				/>
			)}
      {icon}
			{text}
		</a>
	)
}

export default PastilleLink
