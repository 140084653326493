import { Avatar, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { styleContext } from '../Context/StyleContext'
import { COLORS } from '../constants'

interface ClubAvatar {
	src: string
	size?: number
}

export default function ClubAvatar(Props: ClubAvatar) {

	return (
		<Avatar
			src={Props.src}
			style={{
				...{
					width: Props.size ? Props.size : 50,
					height: Props.size ? Props.size : 50,
				},
				objectFit: 'cover',
				borderRadius: '50%',
				backgroundColor: COLORS.gray2,
				position: 'relative'
			}}
		/>
	)
}
