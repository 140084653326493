import { Dialog, makeStyles, Tab, Tabs } from '@material-ui/core'
import {
	AccountCircle,
	Dashboard,
	HowToVote,
	InsertChart,
	Language,
	LocalDining,
	Settings
} from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { Link, Route } from 'react-router-dom'
import '../Assets/Fonts/fonts.css'
import { authContext } from '../Context/AuthContext'
import { styleContext } from '../Context/StyleContext'
import './Appbar/Appbar.css'
import ContenuSettingsButton from './ContenuSettingsButton'
import ThemeSwitcher from './ThemeSwitcher'

interface DrawerProps {
	closeDrawer: () => void
}

export default function CustomDrawer(props: DrawerProps) {
	const contextAuth = useContext(authContext)

	const themeContext = useContext(styleContext)
	const [dialogOpened, openDialog] = useState(false)
	const useStyles = makeStyles({
		root: {
			color: themeContext.theme.primary
		},
		indicator: {
			width: '0px'
		},
		tabs: {
			// borderLeft: `1px solid ${themeContext.theme.primary}`,
		},
		tabWrapper: {
			display: 'flex',
			// @ts-ignore
			flexDirection: 'row-reverse!important',
			justifyContent: 'end',
			fontSize: '17px',
			fontWeight: 400
		},
		tabSelected: {
			color: themeContext.theme.backgroundColor,
			backgroundColor: themeContext.theme.primary
		},
		icon: {
			fontSize: '50px'
		}
	})

	const iconStyle = { marginLeft: '15px' }

	const classes = useStyles()
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'flex-end',
				height: '100%',
				flexDirection: 'column',
				width: 'auto',
				justifyContent: 'space-between',
				color: themeContext.theme.primary,
				backgroundColor: themeContext.theme.backgroundColor
			}}
		>
			<div>
				<div
					style={{
						width: '100%',
						height: '63px',
						borderBottom: '2.5px solid ' + themeContext.theme.primary,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-evenly',
						fontSize: '40px'
					}}
				>
					<AccountCircle classes={{ root: classes.icon }} />
					<authContext.Consumer>
						{(auth) => (
							<div
								style={{
									margin: '0 10px',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'baseline',
									justifyContent: 'center',
									lineHeight: 'normal',
									fontSize: '1rem',
									fontWeight: 600
								}}
							>
								<div>Hey, &nbsp;</div>
								<div>
									{auth.authState.user?.givenName}{' '}
									{auth.authState.user?.surname !== undefined
										? auth.authState.user?.surname.charAt(0) + '.'
										: ''}
								</div>
							</div>
						)}
					</authContext.Consumer>
				</div>

				<Route
					path="/"
					render={({ location }) => (
						<React.Fragment>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexDirection: 'column',
									alignItems: 'center'
								}}
							>
								<Tabs
									value={location.pathname.split('/')[1]}
									classes={{ root: classes.root, indicator: classes.indicator }}
									orientation="vertical"
									TabIndicatorProps={{
										style: { background: themeContext.theme.third, height: 5 }
									}}
								>
									<Tab
										className={'notDraggable'}
										disableRipple={true}
										label="Accueil"
										value="home"
										component={Link}
										to={'/home'}
										classes={{
											wrapper: classes.tabWrapper,
											selected: classes.tabSelected
										}}
										onClick={props.closeDrawer}
										icon={<Language fontSize="large" style={{ margin: 0 }} />}
									/>
									<Tab
										className={'notDraggable'}
										disableRipple={true}
										label="Calendrier"
										value="calendrier"
										component={Link}
										to={'/calendrier'}
										classes={{
											wrapper: classes.tabWrapper,
											selected: classes.tabSelected
										}}
										onClick={props.closeDrawer}
										icon={<Dashboard fontSize="large" style={iconStyle} />}
									/>
									<Tab
										className={'notDraggable'}
										disableRipple={true}
										label="Clubs"
										value="clubs"
										component={Link}
										to={'/clubs'}
										classes={{
											wrapper: classes.tabWrapper,
											selected: classes.tabSelected
										}}
										onClick={props.closeDrawer}
										icon={<InsertChart fontSize="large" style={iconStyle} />}
									/>
									{contextAuth.authState.user?.cafetAdmin && (
										<Tab
											className={'notDraggable'}
											disableRipple={true}
											label="Caféteria"
											value="cafeteria"
											component={Link}
											to={'/cafeteria'}
											classes={{
												wrapper: classes.tabWrapper,
												selected: classes.tabSelected
											}}
											onClick={props.closeDrawer}
											icon={
												<LocalDining fontSize="large" style={iconStyle} />
											}
										/>
									)}
									{contextAuth.authState.user?.calendrierAdmin ||
									contextAuth.votesOpened ? (
										<Tab
											className={'notDraggable'}
											disableRipple={true}
											label="Votes"
											value="votes"
											component={Link}
											to={'/votes'}
											classes={{
												wrapper: classes.tabWrapper,
												selected: classes.tabSelected
											}}
											onClick={props.closeDrawer}
											icon={
												<HowToVote fontSize="large" style={{ margin: 0 }} />
											}
										/>
									) : (
										<></>
									)}
								</Tabs>
							</div>
						</React.Fragment>
					)}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginRight: '10px',
					marginBottom: '10px',
					alignItems: 'center'
				}}
			>
				<ThemeSwitcher />
				<Settings onClick={() => openDialog(true)} style={{ fontSize: 30 }} />
			</div>
			<Dialog open={dialogOpened} onClose={() => openDialog(false)}>
				<ContenuSettingsButton
					handleClose={() => openDialog(false)}
				></ContenuSettingsButton>
			</Dialog>
		</div>
	)
}
