import { useContext, useState } from 'react';
import useSWR from 'swr';
import { authContext } from '../../Context/AuthContext';
import { UserInterface } from '../../Interfaces';

export default function useAutoCompleteData():[UserInterface[] | undefined, string, any, boolean, any]{
    const contextAuth = useContext(authContext)

    const [autoCompleteEntry, setAutocompleteEntry] = useState<string>('')

    const fetcher = (url: string) => fetch(url, {
        headers: new Headers({
            'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
        })
    }).then(r => { if (!r.ok) { throw new Error(r.statusText) } else { return r } }).then(r => r.json()).then(r => {
        return r
    })

    const { data: autoCompleteData, error, isValidating } = useSWR<UserInterface[]>(autoCompleteEntry !== '' ? `${contextAuth.apiUrl}/users/autocomplete/${autoCompleteEntry}` : null, fetcher)

    return [autoCompleteData, autoCompleteEntry, setAutocompleteEntry, isValidating, error]
};