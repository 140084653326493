import { IconButton, MenuItem, Select } from '@material-ui/core'
import { Menu, Sort } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useHistory } from 'react-router-dom'
import { useSWRInfinite } from 'swr'
import PostPreview from '../../../Components/News/PostPreview'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'
import { ClubInterface, ClubPost } from '../../../Interfaces'
import './News.css'
import PostPopUp from './PostPopUp'

interface NewsFeed {
	club: ClubInterface
	preSelectedPost?: ClubPost
	mobile?: boolean
	setDrawerState?: (state: boolean) => void
}

export default function NewsFeed(Props: NewsFeed) {
	const contextAuth = useContext(authContext)

	const [popupPost, setPopupPost] = useState<ClubPost | undefined>()
	const [opened, setOpened] = useState<boolean>(false)
	const [openedOnce, setOpenedOnce] = useState<boolean>(false)
	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	// Requetes pour clubs
	// const { data: posts, error: postsError, mutate } = useSWR<ClubPost[]>(`${contextAuth.apiUrl}/news/posts?club=${Props.club.id}`, fetcher)

	// scroll
	const scrollCallback = () => {
		setSize(size + 1)
	}

	useBottomScrollListener(scrollCallback)

	const [sort, setSort] = useState<'date' | 'score'>('date')
	const [order, setOrder] = useState<1 | -1>(-1)

	const {
		data: postsPage,
		error: postsError,
		setSize,
		size,
		mutate
	} = useSWRInfinite<ClubPost[]>(
		(index) =>
			`${contextAuth.apiUrl}/news/posts?club=${Props.club.id}&sort=${sort}&index=${index}&order=${order}`,
		fetcher
	)

	useEffect(() => {
		setOpened(Props.preSelectedPost !== undefined)
		console.log(Props.preSelectedPost !== undefined)
	}, [Props.preSelectedPost])

	let history = useHistory()

	const handleClosing = () => {
		history.replace(`/news/${Props.club.name}/`, { state: { deleted: false } })
		setOpened(false)
		// setOpenedOnce(true)
	}

	const handlePreviewClick = (post: ClubPost) => {
		history.push(`/news/${Props.club.name}/${post.id}`)
		setOpened(true)
	}

	const themeContext = useContext(styleContext)

	const [orderSelectOpened, setOrderSelectOpened] = useState<boolean>(false)

	const handleSortChange = (val: string) => {
		switch (val) {
			case '+ Date':
				setSort('date')
				setOrder(-1)
				break
			case '- Date':
				setOrder(1)
				setSort('date')
				break
			case '+ Score':
				setSort('score')
				setOrder(-1)

				break
			case '- Score':
				setOrder(1)
				setSort('score')

				break
			default:
				break
		}
	}

	return (
		<div style={{ width: '100%' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'row',
					alignItems: 'center'
				}}
			>
				{Props.mobile && Props.setDrawerState !== undefined ? (
					<IconButton
						style={{ color: themeContext.theme.primary }}
						onClick={() => {
							if (Props.setDrawerState) Props.setDrawerState(true)
						}}
					>
						<Menu />
					</IconButton>
				) : null}
				<p className="sideBarSectionTitre" style={{ color: themeContext.theme.primary }}>
					Mon feed
				</p>
				<IconButton
					onClick={() => setOrderSelectOpened(!orderSelectOpened)}
					style={{
						height: 'fit-content',
						color: themeContext.theme.primary
					}}
				>
					<Sort />
					<Select
						labelId="demo-controlled-open-select-label"
						id="demo-controlled-open-select"
						open={orderSelectOpened}
						onClose={() => setOrderSelectOpened(false)}
						onOpen={() => setOrderSelectOpened(true)}
						value={sort}
						style={{ width: 0, opacity: 0 }}
						// @ts-ignore
						onChange={(val) => handleSortChange(val.target.value)}
					>
						<MenuItem value={'+ Date'}>+ Récent</MenuItem>
						<MenuItem value={'- Date'}>- Récent</MenuItem>
						<MenuItem value={'+ Score'}>+ Score</MenuItem>
						<MenuItem value={'- Score'}>- Score</MenuItem>
					</Select>
				</IconButton>
			</div>

			{postsPage?.map((posts, index) => (
				<div
					key={index}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					{posts.map((post) => (
						<PostPreview
							mobile={Props.mobile}
							key={post.id}
							post={post}
							mutate={mutate}
							onClick={() => handlePreviewClick(post)}
							onClose={handleClosing}
							openPopup={false}
						/>
					))}
				</div>
			))}
			{/* Au cas où un post est déjà séléctionné */}
			{Props.preSelectedPost !== undefined && openedOnce === false && (
				<PostPopUp
					mobile={Props.mobile}
					opened={opened}
					onClose={handleClosing}
					selectedPost={Props.preSelectedPost}
				/>
			)}
		</div>
	)
}
