import { useEffect, useState } from 'react'
import { ClubInterface, UserInterface } from '../../../Interfaces'

export default function useAdminCheck(
	club: ClubInterface | undefined,
	user: UserInterface | undefined
) {
	const [isAdmin, setAdmin] = useState(false)
	let loopUsers = (userList: UserInterface[]): boolean => {
		if (!user) return false
		for (let i = 0; i < userList.length; i++) {
			if (user.id === userList[i].id) {
				return true
			}
		}
		return false
	}
	useEffect(() => {
		if (!user || !club) {
			setAdmin(false)
		} else {
			setAdmin(
				club.president.id === user.id ||
					loopUsers(club.secretaires) ||
					loopUsers(club.tresoriers) ||
					loopUsers(club.vps)
			)
		}
	}, [club, user])
	return isAdmin
}
