import React, { useContext } from 'react'
import { styleContext } from '../../Context/StyleContext'

interface PostPreviewContainer{
    children?: React.ReactNode,
    onClick?: (event: React.MouseEvent) => void,
    style?: React.CSSProperties
}

export default function PostPreviewContainer(Props: PostPreviewContainer) {
    const themeContext = useContext(styleContext)
    const style: React.CSSProperties = {
        marginTop: 60,
        marginBottom: 60,
        width: 450,
        borderRadius: 15,
        border: '0.75px solid rgb(112 112 112 / 22%)',
        boxShadow: '3px 3px 10px 0px rgba(0, 0, 0, 0.16)',
        backgroundColor: themeContext.theme.backgroundColor,
        color: themeContext.theme.textColor
    }
    return(
        <div onClick={Props.onClick} style={{...style,...Props.style}}>
            {Props.children}
        </div>
    )
}