import { Collapse, IconButton, MenuItem, Select } from '@material-ui/core'
import { Sort } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { authContext } from '../../Context/AuthContext'
import { ClubPost, PostComment } from '../../Interfaces'
import Commentaire from './Commentaire'
import PostPreviewAuteur from './PostPreviewAuteur'
import PostPreviewCommentBox from './PostPreviewCommentBox'
import PostPreviewContainer from './PostPreviewContainer'
import PostPreviewTitleContainer from './PostPreviewTitleContainer'

interface PostPopUpComments {
	post: ClubPost
	mobile?: boolean
}

export default function PostPopUpComments(Props: PostPopUpComments) {
	const contextAuth = useContext(authContext)
	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	const [parentComments, setParentComments] = useState<PostComment[]>()
	const [childComments, setChildComments] = useState<PostComment[]>()
	const [orderSelectOpened, setOrderSelectOpened] = useState<boolean>(false)
	const [sort, setSort] = useState<string>('+ Date')
	// Requetes pour clubs
	const {
		data: comments,
		error: commentsError,
		mutate
	} = useSWR<PostComment[]>(`${contextAuth.apiUrl}/news/comments?post=${Props.post.id}`, fetcher)

	useEffect(() => {
		setParentComments(comments?.filter((comment) => !comment.isEnfant))
		setChildComments(comments?.filter((comment) => comment.isEnfant))
	}, [comments])

	const handleSortChange = (a: PostComment, b: PostComment) => {
		switch (sort) {
			case '- Date':
				return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				break
			case '+ Date':
				return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				break
			case '- Score':
				return a.upvotes - b.upvotes
				break
			case '+ Score':
				return b.upvotes - a.upvotes
				break
			default:
				break
		}
		return 1
	}

	return (
		<PostPreviewContainer
			onClick={(event) => event.stopPropagation()}
			style={{
				border: 'none',
				marginLeft: Props.mobile ? 0 : 10,
				marginTop: Props.mobile ? 0 : 60,
				width: Props.mobile ? '100%' : 450
			}}
		>
			<PostPreviewTitleContainer>
				<PostPreviewAuteur
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						marginRight: 35
					}}
				>
					Les coms
					<IconButton onClick={() => setOrderSelectOpened(!orderSelectOpened)}>
						<Sort />
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={orderSelectOpened}
							onClose={() => setOrderSelectOpened(false)}
							onOpen={() => setOrderSelectOpened(true)}
							value={sort}
							style={{ width: 0, opacity: 0 }}
							// @ts-ignore
							onChange={(val) => setSort(val.target.value || '- Score')}
						>
							<MenuItem value={'+ Date'}>+ Récent</MenuItem>
							<MenuItem value={'- Date'}>- Récent</MenuItem>
							<MenuItem value={'+ Score'}>+ Score</MenuItem>
							<MenuItem value={'- Score'}>- Score</MenuItem>
						</Select>
					</IconButton>
				</PostPreviewAuteur>
			</PostPreviewTitleContainer>
			<PostPreviewCommentBox post={Props.post} />
			<Collapse in={parentComments !== undefined}>
				<div>
					{parentComments
						?.sort((a, b) => handleSortChange(a, b))
						.map((comment, index) => (
							<div key={index}>
								<Commentaire commentaire={comment} mutate={mutate} />
								{childComments
									?.filter((childComment) => childComment.parent === comment.id)
									.map((childComment, index2) => (
										<Commentaire
											key={index2}
											commentaire={childComment}
											mutate={mutate}
										/>
									))}
							</div>
						))}
				</div>
			</Collapse>
		</PostPreviewContainer>
	)
}
