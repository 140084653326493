import {
	Avatar,
	Card,
	CardMedia,
	Divider,
	makeStyles,
	Snackbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@material-ui/core'
import { Check, Mail, Settings } from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import { useHistory } from 'react-router'
import useSWR from 'swr'
import ButtonType from '../../../Components/BoutonType'
import MemberButton from '../../../Components/MemberButton'
import PostPreview from '../../../Components/News/PostPreview'
import VerticalList from '../../../Components/VerticalList'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'
import { ClubInterface, ClubPost, UserInterface } from '../../../Interfaces'
import './ClubInfo.css'
import useAdminCheck from './useAdminCheck'
import useLoadedClub from './useLoadedClub'

interface ClubInfoProps {
	clubSelected: ClubInterface | undefined
}

export default function ClubInfo(Props: ClubInfoProps) {
	const theme = useTheme()
	const smallScreen = useMediaQuery('(max-width:600px)')
	const mediumScreen = useMediaQuery('(max-width:960px)')

	let history = useHistory()
	const contextAuth = useContext(authContext)

	// hauteur pour le reste de la div (on veut pas une page qui scroll)
	const [hauteur, setHauteur] = useState(window.innerWidth)

	const resizeEvent = () => {
		setHauteur(window.innerHeight)
	}

	window.addEventListener('resize', resizeEvent)

	useEffect(() => {
		setHauteur(window.innerHeight)
	}, [])

	console.log('club selected: ' + Props.clubSelected?.name)

	const [clubToUse, error, clubName] = useLoadedClub(Props.clubSelected)
	const isUserAdmin = useAdminCheck(clubToUse, contextAuth.authState.user)

	const [openedModal, setModal] = useState(true)

	const themeContext = useContext(styleContext)
	// news du club
	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	// Requetes pour clubs
	const {
		data: posts,
		error: postsError,
		mutate
	} = useSWR<ClubPost[]>(
		clubToUse !== undefined
			? `${contextAuth.apiUrl}/news/posts?club=${clubToUse.id}&index=${0}`
			: '',
		fetcher
	)

	const useStyles = makeStyles((theme) => ({
		cardRoot: {
			[theme.breakpoints.down('md')]: {
				width: '100%',
				margin: 'auto',
				// height: `${hauteur - 64}px`,
				overflow: 'hidden'
			},
			[theme.breakpoints.up('md')]: {
				width: '44%',
				minWidth: '44%',
				margin: 0,
				height: `${hauteur - 72}px`,
				overflowY: 'auto'
			},
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			borderRadius: 0,
			boxShadow: 'none',
			backgroundColor: themeContext.theme.backgroundColor,
			color: themeContext.theme.textColor
		},
		imageHeader: {
			[theme.breakpoints.down('md')]: {
				width: '100vw',
				height: '30vw'
			},
			[theme.breakpoints.up('md')]: {
				width: '44vw',
				height: '14.6vw'
			}
		},
		avatarRoot: {
			marginLeft: '6%',
			height: 'fit-content',
			position: 'relative',
			backgroundColor: themeContext.theme.primary,
			[theme.breakpoints.down('md')]: {
				width: '22vw',
				top: '-13vw',
				border: '2vw solid ' + themeContext.theme.backgroundColor
			},
			[theme.breakpoints.up('md')]: {
				width: '8vw',
				top: '-4.7vw',
				border: '0.7vw solid ' + themeContext.theme.backgroundColor
			},
			borderRadius: '50%',
			boxShadow: '3px 3px 6px black'
		},
		titreTypo: {
			fontWeight: 'bold',
			marginLeft: '1vw',
			marginTop: '5px',
			textAlign: 'left',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.2rem'
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: '1.7rem'
			},
			[theme.breakpoints.up('md')]: {
				fontSize: '2.25rem'
			}
		},
		sndCardRoot: {
			flexGrow: 1,
			textAlign: 'left',
			paddingLeft: '25px',
			paddingRight: '25px',
			paddingTop: '10px',
			paddingBottom: '10px',
			backgroundColor: themeContext.theme.backgroundColor,
			color: themeContext.theme.textColor
		},
		sideTypo: {
			// marginLeft: '20px',
			fontWeight: 'bold',
			lineHeight: 2.3
		},
		divider: {
			marginBottom: '25px',
			backgroundColor: themeContext.theme.primary
		}
	}))
	const classes = useStyles()
	const biggerScreen = useMediaQuery('(min-width:1330px)')

	return (
		<div style={{ display: 'flex', flexDirection: mediumScreen ? 'column' : 'row' }}>
			{/* titre de la page */}
			<Helmet>
				<title>{`CLUBS | ${clubToUse?.name || '...'}`}</title>
			</Helmet>

			{/* premier panel */}
			<Card classes={{ root: classes.cardRoot }}>
				{/* header  */}
				{clubToUse ? (
					<CardMedia
						image={
							clubToUse.imagePresentation !== null
								? contextAuth.apiUrl + clubToUse.imagePresentation.url
								: 'https://i.imgur.com/4mW4wGF.png'
						}
						alt={clubToUse.name}
						component="img"
						classes={{ root: classes.imageHeader }}
					/>
				) : (
					<Skeleton variant="rect" classes={{ root: classes.imageHeader }} />
				)}

				<div style={{ display: 'flex', height: mediumScreen ? '16vw' : '6.5vw' }}>
					{/* Avatar du club */}
					{clubToUse ? (
						<Avatar
							src={
								clubToUse.avatar !== null
									? contextAuth.apiUrl + clubToUse.avatar.url
									: 'https://i.imgur.com/b8UtZfg.png'
							}
							alt={clubToUse.name}
							classes={{ root: classes.avatarRoot }}
						/>
					) : (
						<Skeleton variant="rect" classes={{ root: classes.imageHeader }} />
					)}

					<div>
						{/* Nom du club et boutons */}
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{clubToUse ? (
								<Typography variant="h4" classes={{ root: classes.titreTypo }}>
									{clubToUse.name}
								</Typography>
							) : (
								<Skeleton variant="rect" classes={{ root: classes.imageHeader }} />
							)}
						</div>

						{/* recrute ou pas */}
						{/*clubToUse ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-end'
								}}
							>
								<Check style={{ marginRight: '5', color: 'green' }} /> Recrute
							</div>
						) : (
							<Skeleton variant="rect" classes={{ root: classes.imageHeader }} />
						)*/}
					</div>
				</div>
				{/* si l'ecran trop petit je mets en dessosu les boutons */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						alignItems: 'baseline',
						justifyContent: 'space-around'
					}}
				>
					{isUserAdmin && clubToUse && (
						<ButtonType
							style={{ margin: '1vw' }}
							color="white"
							backgroundColor={themeContext.theme.secondary}
							onClick={(event) => {
								history.push(`/clubs/admin/${clubToUse.name}`)
							}}
						>
							<Settings style={{ marginRight: '0.65%' }} /> Administration
						</ButtonType>
					)}

					{/*clubToUse ? (
						<ButtonType
							style={{ margin: '1vw' }}
							disabled={clubToUse.contactUrl === null}
							onClick={(event) => {
								window.open(clubToUse.contactUrl)
								event.stopPropagation()
							}}
						>
							<Mail style={{ marginRight: '0.65%' }} /> Contacter
						</ButtonType>
					) : (
						<Skeleton variant="rect" classes={{ root: classes.imageHeader }} />
					)*/}
				</div>

				{/* description */}
				{clubToUse ? (
					<div
						style={{
							margin: '25px',
							display: 'flex',
							flexDirection: 'column',
							textAlign: 'left'
						}}
					>
						<ReactMarkdown>{clubToUse.description}</ReactMarkdown>
					</div>
				) : (
					<Skeleton variant="rect" classes={{ root: classes.imageHeader }} />
				)}
			</Card>

			<Card classes={{ root: classes.sndCardRoot }}>
				{posts && posts.length !== 0 && (
					<>
						<div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
							<Typography variant="h5" classes={{ root: classes.sideTypo }}>
								Les dernières news
							</Typography>
							<Divider classes={{ root: classes.divider }} />
						</div>

						{/* container des news */}
						<VerticalList childAmount={posts?.length} slideDistance={340}>
							{posts?.map((post, key) => (
								<PostPreview
									details={false}
									mutate={mutate}
									mobile={!biggerScreen}
									post={post}
									// upperStyle={{
									// 	width: 250
									// }}
									key={key}
									style={{
										marginLeft: 20,
										marginTop: 40,
										marginRight: 20,
										minWidth: 300,
										borderRadius: 15,
										maxHeight: 300
									}}
								/>
							))}
						</VerticalList>
					</>
				)}

				{/* liste des membres */}
				<div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
					<Typography variant="h5" classes={{ root: classes.sideTypo }}>
						Les membres
					</Typography>
					<Divider classes={{ root: classes.divider }} />
				</div>
				<VerticalList childAmount={clubToUse?.members.length}>
					{clubToUse?.members.map((member, index) => (
						<MemberButton
							key={index}
							member={member}
							role={getRole(clubToUse, member)}
						/>
					))}
				</VerticalList>
			</Card>

			<Snackbar
				open={error && openedModal}
				autoHideDuration={3000}
				onClose={() => setModal(false)}
				onEnter={() => setModal(true)}
			>
				<MuiAlert
					onClose={() => setModal(false)}
					severity="error"
					elevation={6}
					variant="filled"
				>
					Erreur lors du chargement du club {clubName}
				</MuiAlert>
			</Snackbar>
		</div>
	)
}

function getRole(
	club: ClubInterface,
	member: UserInterface
): 'Président' | 'Trésorier' | 'Secrétaire' | 'Vice-Président' | 'Membre' {
	if (club.president.id === member.id) return 'Président'
	else if (club.vps.some((vp) => vp.id === member.id)) return 'Vice-Président'
	else if (club.tresoriers.some((treso) => treso.id === member.id)) return 'Trésorier'
	else if (club.secretaires.some((secre) => secre.id === member.id)) return 'Secrétaire'
	return 'Membre'
}
