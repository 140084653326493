import React from 'react'
import { COLORS } from '../constants'

interface HeaderProps {
  title: string
  subtitle?: string
}

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle
}) => {
	return (
		<div>
			<h3
				style={{
					fontSize: 32,
					fontWeight: 700
				}}
			>
				{title}
			</h3>
			<p
				style={{
					fontSize: 14,
					fontWeight: 400,
					color: COLORS.textGray
				}}
			>
				{subtitle}
			</p>
		</div>
	)
}

export default Header
