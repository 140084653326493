import { IconButton, MenuItem, Select } from '@material-ui/core'
import { Menu, Sort } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useHistory } from 'react-router-dom'
import { useSWRInfinite } from 'swr'
import PostPreview from '../../../Components/Home/PostPreview'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'
import { ClubInterface, ClubPost } from '../../../Interfaces'
import './Home.css'
import { COLORS } from '../../../constants'

interface HomeFeed {
	preSelectedPost?: ClubPost
	mobile?: boolean
	setDrawerState?: (state: boolean) => void
}

export default function HomeFeed(Props: HomeFeed) {
	const contextAuth = useContext(authContext)

	const [popupPost, setPopupPost] = useState<ClubPost | undefined>()
	const [opened, setOpened] = useState<boolean>(false)
	const [openedOnce, setOpenedOnce] = useState<boolean>(false)
	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	// Requetes pour clubs
	// const { data: posts, error: postsError, mutate } = useSWR<ClubPost[]>(`${contextAuth.apiUrl}/news/posts?club=${Props.club.id}`, fetcher)

	// scroll
	const scrollCallback = () => {
		setSize(size + 1)
	}

	useBottomScrollListener(scrollCallback)

	const [sort, setSort] = useState<'date' | 'score'>('date')
	const [order, setOrder] = useState<1 | -1>(-1)

	const {
		data: postsPage,
		error: postsError,
		setSize,
		size,
		mutate
	} = useSWRInfinite<ClubPost[]>(
		(index) => `${contextAuth.apiUrl}/news/posts?sort=${sort}&index=${index}&order=${order}`,
		fetcher
	)

	useEffect(() => {
		setOpened(Props.preSelectedPost !== undefined)
		console.log(Props.preSelectedPost !== undefined)
	}, [Props.preSelectedPost])

	let history = useHistory()

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				width: '100%'
			}}
		>
			<h5
				style={{
					fontSize: 16,
					fontWeight: 500,
					width: 'fit-content'
				}}
			>
				Actualités
			</h5>

			{postsError || !postsPage || postsPage.length === 0 ? (
				<p
					style={{
						marginTop: 80
					}}
				>
					Rien pour le moment ...
				</p>
			) : (
				<>
					{postsPage?.map((posts, index) => (
						<div
							key={index}
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 25
							}}
						>
							{posts.map((post) => (
								<>
									<PostPreview
										mobile={Props.mobile}
										key={post.id}
										post={post}
										mutate={mutate}
										openPopup={false}
									/>
									<span
										style={{
											backgroundColor: COLORS.gray,
											width: '100%',
											height: 1
										}}
									/>
								</>
							))}
						</div>
					))}
				</>
			)}
		</div>
	)
}
