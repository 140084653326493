import {
	Checkbox,
	Container,
	Divider,
	Grow,
	IconButton,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import React, { useContext } from 'react'
import { styleContext } from '../../../Context/StyleContext'
import { Commande, Plat, Side } from './Commander'

interface SubCommand {
	type: 'boissons' | 'plats' | 'accompagnements'
	removeElement: (type: 'boissons' | 'plats' | 'accompagnements', name: string) => void
	ajouterElement: (
		type: 'boissons' | 'plats' | 'accompagnements',
		elementSide?: Side,
		elementPlat?: Plat
	) => void
	addIngredientToPlat?: (
		platIndex: number,
		ingredientIndex: number,
		type: 'sauce' | 'ingredient'
	) => void
	commande: Commande
	sides?: Side[]
	plats?: Plat[]
	sidesBag?: Side[]
	platsBag?: Plat[]
	// setCommande: (bag: Commande) => void
}

export default function SubCommand(Props: SubCommand) {
	const themeContext = useContext(styleContext)
	const useStyles = makeStyles({
		container: {
			textAlign: 'left'
		},
		listElement: {
			display: 'flex',
			justifyContent: 'left',
			alignItems: 'center',
			padding: 0
		},
		elementPickerRoot: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'left',
			alignItems: 'flex-start',
			// padding: 15,
			margin: 10,
			backgroundColor: themeContext.theme.backgroundColor,
			border: 'solid',
			boxSizing: 'border-box',
			borderColor: themeContext.theme.primary,
			borderRadius: 5,
			borderWidth: 'thin',
			minWidth: 150
		},
		colorSecondary: {
			color: themeContext.theme.textColor
		},
		checkedBox: {
			color: themeContext.theme.textColor
		},
		typoRoot: {
		}
	})
	const classes = useStyles()

	const getNumberInBag = (elementId: string) => {
		let occurences: number = 0
		let bag = Props.type !== 'plats' ? Props.sidesBag : Props.platsBag
		if (bag === undefined) return 0
		for (let i = 0; i < bag.length; i++) {
			if (elementId === bag[i]._id) {
				occurences = occurences + 1
			}
		}
		return occurences
	}

	const handleAddIngredient = (
		platIndex: number,
		ingredientIndex: number,
		type: 'sauce' | 'ingredient'
	) => {
		if (Props.addIngredientToPlat) Props.addIngredientToPlat(platIndex, ingredientIndex, type)
	}

	return (
		<Container classes={{ root: classes.container }}>
			<Typography variant="h5" classes={{ root: classes.typoRoot }}>
				{Props.type === 'plats' && 'Plats disponibles:'}
				{Props.type === 'boissons' && 'Boissons disponibles:'}
				{Props.type === 'accompagnements' && 'Accompagnements disponibles:'}
			</Typography>
			<Typography variant="h6" classes={{ root: classes.typoRoot }}>
				Choisissez le contenu de votre commande:
			</Typography>

			{/* Sélection des sides */}
			{Props.sides?.map((element: Side, index) => (
				<Container classes={{ root: classes.listElement }}>
					<IconButton
						disabled={(getNumberInBag(element._id) || 0) === 0}
						onClick={() => Props.removeElement(Props.type, element.name)}
					>
						<Remove />
					</IconButton>
					<Typography>{getNumberInBag(element._id) || 0}</Typography>
					<IconButton
						onClick={() => Props.ajouterElement(Props.type, element, undefined)}
					>
						<Add />
					</IconButton>
					<Typography classes={{ root: classes.typoRoot }}>{element.name} </Typography>
					<Typography
						style={{ marginRight: 4, marginLeft: 4 }}
						classes={{ root: classes.typoRoot }}
					>
						-
					</Typography>
					<Typography classes={{ root: classes.typoRoot }}>{element.price} €</Typography>
				</Container>
			))}

			<Container>
				{/* Sélection des plats */}
				{Props.type === 'plats' && (
					<Typography variant="body2">0.8€ par ingrédient au dessus de 4.</Typography>
				)}

				{Props.plats?.map((element: Plat, index) => (
					<Container classes={{ root: classes.listElement }}>
						<IconButton
							disabled={(getNumberInBag(element._id) || 0) === 0}
							onClick={() => Props.removeElement(Props.type, element.name)}
						>
							<Remove />
						</IconButton>
						<Typography>{getNumberInBag(element._id) || 0}</Typography>
						{/* JSON parse et stringify pour la deep copy sinon les elements restent linked */}
						<IconButton
							onClick={() =>
								Props.ajouterElement(
									Props.type,
									undefined,
									JSON.parse(JSON.stringify(element))
								)
							}
						>
							<Add />
						</IconButton>
						<div>
							<Typography>{element.name} </Typography>
							{/* <Typography classes={{ root: classes.typoRoot }} style={{marginRight: 4, marginLeft: 4}}>-</Typography> */}
							<Typography classes={{ root: classes.typoRoot }}>
								{element.price} €
							</Typography>
						</div>
					</Container>
				))}

				{/* Plats choisis */}
				{Props.platsBag && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap'
						}}
					>
						{Props.platsBag?.map((plat, index) => (
							<Grow in={true}>
								<Paper
									elevation={3}
									classes={{ root: classes.elementPickerRoot }}
									key={index}
								>
									<div
										style={{
											width: '100%',
											padding: 5,
											textAlign: 'center',
											border: 'solid',
											boxSizing: 'border-box',
											borderColor: themeContext.theme.primary,
											color: themeContext.theme.textColor,
											borderBottomRightRadius: 5,
											borderBottomLeftRadius: 5,
											paddingRight: 10,
											paddingLeft: 10,
											boxShadow: '0 4px 7px 0px rgba(0,0,0,0.75)'
										}}
									>
										<Typography variant="h6">{plat.name}</Typography>
									</div>
									{/* <Typography variant='body2'>Ingrédients disponibles:</Typography> */}
									{plat.ingredients.map((ingredient, indexI) => (
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												paddingLeft: 5,
												paddingBottom: 5,
												paddingRight: 5,
												textDecoration: ingredient.available
													? 'none'
													: 'line-through',
												color: themeContext.theme.textColor
											}}
										>
											{/* @ts-ignore */}
											<Checkbox
												disabled={!ingredient.available}
												checked={ingredient.chosen || false}
												onClick={() =>
													handleAddIngredient(index, indexI, 'ingredient')
												}
												style={{
													color: themeContext.theme.textColor
												}}
											/>
											{ingredient.name}
										</div>
									))}
									<Divider
										style={{
											width: '100%',
											backgroundColor: themeContext.theme.primary
										}}
									/>
									{plat.sauces.map((sauce, indexS) => (
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												paddingLeft: 5,
												paddingBottom: 5,
												paddingRight: 5,
												width: '100%',
												textDecoration: sauce.available
													? 'none'
													: 'line-through',
												color: themeContext.theme.textColor
											}}
										>
											{/* @ts-ignore */}
											<Checkbox
												disabled={!sauce.available}
												checked={sauce.chosen || false}
												onClick={() =>
													handleAddIngredient(index, indexS, 'sauce')
												}
												style={{
													color: themeContext.theme.textColor
												}}
											/>
											{sauce.name}
										</div>
									))}
								</Paper>
							</Grow>
						))}
					</div>
				)}
			</Container>
		</Container>
	)
}
