import React, { useContext, useState } from 'react'
import { styleContext } from '../../Context/StyleContext'

interface SelectButton{
    texte: string
    selected: boolean
    onClick: (event: any) => void
    style?: React.CSSProperties
}

export default function SelectButton(Props: SelectButton){
    const themeContext = useContext(styleContext)

    const [hovered, setHovered] = useState<boolean>(false)

    return(
        <button 
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="selectButton" 
            onClick={Props.onClick} 
            // fusionner le props style et le style en fonction du thème
            style={
                    {
                    ...{
                        backgroundColor: Props.selected ? themeContext.theme.primary : "rgba(0,0,0,0.1)",
                        color: Props.selected ? themeContext.theme.third : themeContext.theme.primary,
                        boxShadow: hovered ? "5px 5px 0px 0px " + themeContext.theme.buttonFocus : "none",
                        cursor: "pointer",
                        }, 
                    ...Props.style}
                    }
        >
            {Props.texte}
        </button>
    )
}