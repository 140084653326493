import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'

const useStyles = makeStyles({
	table: {
		minWidth: 400
	}
})

export default function Salles(props: { salles: any[]; bigScreen: boolean }) {
	const useStyles = makeStyles((theme) => ({
		tableRoot: {
			minWidth: 150
		}
	}))
	const classes = useStyles()

	return (
		<TableContainer
			component={Paper}
			style={{
				height: 'fit-content',
				maxHeight: props.bigScreen ? 601 : 'none',
				overflowY: 'scroll',
				width: 'fit-content'
			}}
		>
			<Table classes={{ root: classes.tableRoot }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>
							<b>Salle</b>
						</TableCell>
						<TableCell align="right">
							<b>Numéro</b>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.salles.map(
						(salle: { name: {} | null | undefined; id: React.ReactNode }, index) => (
							<TableRow key={index}>
								<TableCell component="th" scope="row">
									{salle.name}
								</TableCell>
								<TableCell align="right">{salle.id}</TableCell>
							</TableRow>
						)
					)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
