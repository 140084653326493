import React, { useContext } from 'react'
import { styleContext } from '../../Context/StyleContext'

interface PostPreviewUpperContainer {
	children?: React.ReactNode
	style?: React.CSSProperties
	onClick?: () => void
}

export default function PostPreviewUpperContainer(Props: PostPreviewUpperContainer) {
	const themeContext = useContext(styleContext)
	const style: React.CSSProperties = {
		display: 'flex',
		flexDirection: 'column',
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		color: themeContext.theme.textColor,
		backgroundColor: themeContext.theme.backgroundColor,
		cursor: 'pointer'
	}
	return (
		<div style={{ ...style, ...Props.style }} onClick={Props.onClick}>
			{Props.children}
		</div>
	)
}
