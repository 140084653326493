import { Card, Container, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import useSWR from 'swr'
import CommandePreview from '../../../Components/Cafeteria/CommandePreview'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'
import { CommandeAPIResult } from './Desk'

export default function MesCommandes(){
    const themeContext = useContext(styleContext)
    const useStyles = makeStyles((theme) => ({
        cardRoot: {
            textAlign: 'left',
            padding: '20px',
            color: themeContext.theme.textColor,
            // filter: (themeContext.theme.name === 'dark') ? 'invert(1)' : 'invert(0)',
            filter: (themeContext.theme.name === 'dark') ? 'brightness(0.8)' : 'brightness(1)',
            backgroundColor: (themeContext.theme.name === 'dark') ? 'transparent' : themeContext.theme.backgroundColor,
            boxShadow: 'none',
            [theme.breakpoints.down('md')]: {
                width: '90vw'
            },
            [theme.breakpoints.up('sm')]: {
                width: '70vw'
            },
            marginTop: '10px'
        },
        container:{
            display: 'flex', 
            justifyContent: 'center'
        },
        typoFont: {
            marginBottom: '4px'
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            // justifyContent: ''
        }
    }))
    const classes = useStyles()

    const contextAuth = useContext(authContext)
    const fetcher = (url: string) => fetch(url, {
        headers: new Headers({
            'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
        })
    }).then(r => { if (!r.ok) { throw new Error(r.statusText) } else { return r } }).then(r => r.json())

    const { data: mesCommandes, error: mesCommandesError } = useSWR<CommandeAPIResult[]>(`${contextAuth.apiUrl}/cafet/allCommandes?today=false&advanced=true&me=true`, fetcher, { refreshInterval: 2000 })
    
    const [commandesAjd, setCommandesAjd] = useState<CommandeAPIResult[]>([])
    const [commandesTjrs, setCommandesTjrs] = useState<CommandeAPIResult[]>([])
    
    // trier ajd toujours
    useEffect(() => {
        const todayDateTemp = new Date()
        const todayDate = new Date(todayDateTemp.getFullYear() + "-" + (todayDateTemp.getMonth() + 1).toString() + "-" + todayDateTemp.getDate())
        if(mesCommandes !== undefined){
            setCommandesAjd(mesCommandes?.filter(commande => new Date(commande.createdAt) > todayDate))
            setCommandesTjrs(mesCommandes?.filter(commande => new Date(commande.createdAt) < todayDate))
        }
    }, [mesCommandes]) 

    return(
        <Container classes={{root: classes.container}}>
            <Helmet>
                <title>{`CAFET | Mes Commandes`}</title>
            </Helmet>
            <Card classes={{ root: classes.cardRoot }}>
                <Typography variant='h4'>Mes commandes</Typography>
                <Typography variant='h5' classes={{ root: classes.typoFont }}>Aujourd'hui</Typography>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly'
                }}>
                    {commandesAjd.length > 0 ? commandesAjd.map((commande) => 
                        <CommandePreview commande={commande} deployed={true}/>
                    ) : 
                        <Typography>Pas de commandes aujourd'hui!</Typography>
                    }
                </div>
                <Typography variant='h5' classes={{ root: classes.typoFont }}>Depuis toujours</Typography>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly'
                }}>
                    {commandesTjrs.length > 0 ? commandesTjrs.map((commande) => 
                        <CommandePreview commande={commande} />
                    ) : 
                        <Typography>Vous n'avez jamais commandé à la cafet!</Typography>
                    }
                </div>
            </Card>
        </Container>
    )
}