import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { authContext } from '../../Context/AuthContext'
import { ClubInterface } from '../../Interfaces'
import ClubAvatar from '../ClubAvatar'
import { COLORS } from '../../constants'
import { useMediaQuery } from '@material-ui/core'

interface ClubPreviewProps {
	club: ClubInterface
	loaded: boolean
	setClubSelected?: (val: ClubInterface) => void
}

export default function ClubPreview(Props: ClubPreviewProps) {
	let history = useHistory()
	const contextAuth = useContext(authContext)

	const buttonCallback = () => {
		if (Props.loaded && Props.setClubSelected) {
			Props.setClubSelected(Props.club)
			history.push('infos/' + Props.club.name.replace(' ', '_'))
		}
	}

	const biggerScreen = useMediaQuery('(min-width:1330px)')

	return (
		<div
			onClick={() => buttonCallback()}
			style={{ textDecoration: 'none', flex: 1, width: '100%' }}
		>
			<div
				style={{
					width: '100%',
					height: '100%',
					cursor: 'pointer',
					borderRadius: '10px',
					display: 'flex',
					flexDirection: !biggerScreen ? 'column' : 'row',
					backgroundColor: COLORS.gray,
					gap: '40px',
					padding: '20px'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100px'
					}}
				>
					<ClubAvatar
						src={
							Props.club.avatar !== null
								? `${contextAuth.apiUrl}${Props.club.avatar.url}`
								: 'https://i.imgur.com/b8UtZfg.png'
						}
						size={100}
					/>
					<p
						style={{
							fontWeight: 600,
							fontSize: '16',
							textAlign: 'center'
						}}
					>
						{Props.club.name}
					</p>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '20px'
					}}
				>
					<img
						src={
							Props.club.imagePresentation !== null
								? `${contextAuth.apiUrl}${Props.club.imagePresentation.url}`
								: 'https://i.imgur.com/4mW4wGF.png'
						}
						style={{
							height: '100px',
							width: '100%',
							objectFit: 'cover',
							borderRadius: '10px'
						}}
					/>
					<p
						style={{
							width: biggerScreen ? '300px' : '100%',
							textAlign: 'start'
						}}
					>
						{Props.club.shortPresentation}
					</p>
				</div>
			</div>
		</div>
	)
}
