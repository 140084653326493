import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { styleContext } from '../Context/StyleContext'

interface ButtonProps {
	children?: React.ReactNode
	style?: React.CSSProperties
	disabled?: boolean
	onClick?: (event?: any) => void
	color?: string
	backgroundColor?: string
	loading?: boolean
	type?: 'button' | 'reset' | 'submit' | undefined
}

export default function ButtonType(Props: ButtonProps) {
	const themeContext = useContext(styleContext)
	const useStyles = makeStyles({
		buttonRoot: {
			width: '43.3%',
			height: '42px',
			backgroundColor: Props.backgroundColor || themeContext.theme.primary,
			color: Props.color || themeContext.theme.backgroundColor,
			boxShadow: '3px 3px 6px 0px rgba(0,0,0,0.75)',
			'&:hover': {
				backgroundColor: themeContext.theme.third,
				color: themeContext.theme.primary,
				border: '1px solid ' + themeContext.theme.primary
			}
		},
		loadingIcon: {
			color: 'white',
			margin: '8px',
			height: '20px!important',
			width: '20px!important'
		}
	})
	const classes = useStyles()
	return (
		<Button
			type={Props.type}
			classes={{ root: classes.buttonRoot }}
			onClick={(event) => (Props.onClick ? Props.onClick(event) : {})}
			disabled={Props.disabled || false}
			style={Props.style ? Props.style : {
				backgroundColor: Props.disabled
					? themeContext.theme.buttonFocus
					: themeContext.theme.primary,
				color: 'white',
				marginTop: 10,
				marginLeft: 20
			}}
		>
			{!Props?.loading ? (
				Props.children
			) : (
				<CircularProgress classes={{ root: classes.loadingIcon }} />
			)}
		</Button>
	)
}
