import { IconButton, Modal } from '@material-ui/core'
import { ArrowLeft, ArrowRight } from '@material-ui/icons'
import React, { useContext } from 'react'
import { authContext } from '../Context/AuthContext'
import { styleContext } from '../Context/StyleContext'
import { FileUpload } from '../Interfaces'

interface FullImageModal{
    images: FileUpload[]
    opened: boolean
    onClose: () => void
    onChange: (index: number) => void,
    selectedIndex: number
}

export default function FullImageModal(Props: FullImageModal){

    const contextAuth = useContext(authContext)
    const themeContext = useContext(styleContext)

    const handleArrowClick = (event: React.MouseEvent, side: -1|1) => {
        event.stopPropagation()
        if (side === -1 && Props.selectedIndex > 0) Props.onChange(Props.selectedIndex - 1)
        if (side === 1 && Props.selectedIndex < Props.images.length - 1) Props.onChange(Props.selectedIndex + 1)
    }

    const arrowStyle: React.CSSProperties = {
        borderRadius: '50%',
        padding: 10,
        color: themeContext.theme.backgroundColor,
        backgroundColor: themeContext.theme.primary
    }

    return(
        <Modal
            open={Props.opened}
            onClose={Props.onClose}
            aria-labelledby="image-modal"
            aria-describedby="image-modal"
            onBackdropClick={Props.onClose}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
            }} onClick={Props.onClose}>

                <IconButton onClick={(event) => handleArrowClick(event, -1)} style={{ ...arrowStyle, opacity: Props.selectedIndex === 0 ? 0 : 100}}>
                    <ArrowLeft/>
                </IconButton>
                {Props.images.length > 0 &&
                    <img src={contextAuth.apiUrl + Props.images[Props.selectedIndex].url} style={{ width: '80%' }} onClick={(event) => event.stopPropagation()}/>
                }
                <IconButton onClick={(event) => handleArrowClick(event, 1)} style={{ ...arrowStyle, opacity: !(Props.selectedIndex < Props.images.length - 1) ? 0 : 100 }}>
                    <ArrowRight/>
                </IconButton>

            </div>
        </Modal>
    )
}