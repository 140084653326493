import { CircularProgress, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { authContext } from '../../Context/AuthContext';
import { styleContext } from '../../Context/StyleContext';

interface IProps {
}

interface IState {
    jwt: string
}

export default function HomeNotConnected(){
    const contextAuth = useContext(authContext)
    const themeContext = useContext(styleContext)

    useEffect(() => {
        window.location.replace(`${contextAuth.apiUrl}/login/oauth/site`)
    })
    
    return (
        <div style={{
                position: 'absolute',
                backgroundColor: themeContext.theme.backgroundColor,
                color: themeContext.theme.textColor,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Typography variant="h4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                Redirection
                <CircularProgress />
            </Typography>
        </div>
    )
}