import React from 'react';
import light from '../themes/light'

export interface styleContextI {
    name: string
    textColor: string
    backgroundColor: string
    primary: string
    secondary: string
    third: string
    buttonFocus: string
    toggle: Function
}

interface themeContextI {
    theme: styleContextI
    toggle: Function
}

export var defaultContext: themeContextI = {
    theme: light,
    toggle: () => {}
}

export const styleContext = React.createContext(defaultContext);
