import { Collapse, Divider, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import { AccessTime, ExpandMore } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { styleContext } from '../../Context/StyleContext'
import { CommandeAPIResult } from '../../pages/Tabs/Cafeteria/Desk'

interface CommandePreview {
	commande: CommandeAPIResult
	deployed?: boolean
}

export default function CommandePreview(Props: CommandePreview) {
	const themeContext = useContext(styleContext)
	const useStyles = makeStyles((theme) => ({
		paperRoot: {
			margin: 20,
			padding: 10,
			width: 250,
			textAlign: 'left',
			color: 'white',
			fontFamily: 'Product Sans',
			height: 'fit-content'
		},
		divider: {
			backgroundColor: 'white',
			margin: 5
		},
		dialogScroll: {
			alignItems: 'flex-end'
		},
		typoRoot: {
			fontFamily: 'Product Sans'
		},
		dropDown: {
			color: 'white'
		}
	}))
	const classes = useStyles()
	const stateCouleurs = ['#e84a45', '#f1c42b', '#58b973', '#3734eb']

	const [expanded, setExpanded] = useState<boolean>(false)
	const [rotation, setRotation] = useState<number>(0)

	const buttonClick = () => {
		setExpanded(!expanded)
		setRotation(expanded ? 0 : -180)
	}

	return (
		<Paper
			elevation={3}
			classes={{ root: classes.paperRoot }}
			style={{ backgroundColor: stateCouleurs[Props.commande.statusCode] }}
		>
			<Typography
				variant="h6"
				style={{ fontWeight: 'bold' }}
				classes={{ root: classes.typoRoot }}
			>
				Commande du {formatDate(Props.commande.createdAt)}
			</Typography>
			<div
				onClick={buttonClick}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					cursor: 'pointer'
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<AccessTime style={{ marginRight: 3 }} />
					{new Date(Props.commande.createdAt).toTimeString().split(' ')[0]} |{' '}
					{Props.commande.price} € | {Props.commande.statusMessage}
				</div>
				<IconButton onClick={buttonClick} style={{ color: 'white' }}>
					<ExpandMore
						style={{
							transform: 'rotate(' + rotation + 'deg)',
							transition: 'transform 0.3s ease-in-out 0s'
						}}
					/>
				</IconButton>
			</div>
			<Collapse in={expanded} mountOnEnter unmountOnExit>
				<div>
					<Divider classes={{ root: classes.divider }} />
					<Typography variant="h6" classes={{ root: classes.typoRoot }}>
						Plats:
					</Typography>

					{Props.commande.plats.map((plat) => (
						<div style={{ marginLeft: 20 }}>
							<Typography variant="h6" classes={{ root: classes.typoRoot }}>
								- {plat.name}:
							</Typography>
							<div style={{ marginLeft: 20 }}>
								{plat.ingredients.map((ingredient) => (
									<Typography
										variant="body1"
										classes={{ root: classes.typoRoot }}
									>
										• {ingredient.name}
									</Typography>
								))}
							</div>
							{plat.sauces.length > 0 && (
								<div style={{ marginLeft: 20 }}>
									<Typography
										variant="body2"
										classes={{ root: classes.typoRoot }}
									>
										- Sauces:
									</Typography>
									<div style={{ marginLeft: 20 }}>
										{plat.sauces.map((sauce) => (
											<Typography
												variant="body1"
												classes={{ root: classes.typoRoot }}
											>
												• {sauce.name}
											</Typography>
										))}
									</div>
								</div>
							)}
						</div>
					))}

					{Props.commande.accompagnements.length !== 0 && (
						<div>
							<Divider classes={{ root: classes.divider }} />
							<Typography variant="h6" classes={{ root: classes.typoRoot }}>
								Accompagnements:
							</Typography>
							{Props.commande.accompagnements.map((accompagnement) => (
								<div style={{ marginLeft: 20 }}>• {accompagnement.name}</div>
							))}
						</div>
					)}

					{Props.commande.boissons.length !== 0 && (
						<div>
							<Divider classes={{ root: classes.divider }} />
							<Typography variant="h6" classes={{ root: classes.typoRoot }}>
								Boissons:
							</Typography>
							{Props.commande.boissons.map((boisson) => (
								<div style={{ marginLeft: 20 }}>• {boisson.name}</div>
							))}
						</div>
					)}

					{Props.commande.additionalMessage !== '' && (
						<div>
							<Divider classes={{ root: classes.divider }} />
							<Typography variant="h6" classes={{ root: classes.typoRoot }}>
								Message:
							</Typography>
							<Typography variant="body1" classes={{ root: classes.typoRoot }}>
								{Props.commande.additionalMessage}
							</Typography>
						</div>
					)}
				</div>
			</Collapse>
		</Paper>
	)
}

/**
 * Returns date to readable format
 * @param date
 */
function formatDate(dateStr: Date): string {
	let date = new Date(dateStr)
	return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
}
