import { Button, ClickAwayListener, makeStyles, Paper } from '@material-ui/core'
import { AccountCircle, ExpandMore } from '@material-ui/icons'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { authContext } from '../Context/AuthContext'
import { styleContext } from '../Context/StyleContext'
import ContenuSettingsButton from './ContenuSettingsButton'
import { COLORS } from '../constants'

export default function ProfileButton() {
	const [buttonWidth, setButtonWidth] = useState(0)
	const [buttonHeight, setButtonHeight] = useState(0)

	const [opened, setOpened] = useState(false)

  const contextAuth = useContext(authContext)
  const user = contextAuth.authState.user

	const themeContext = useContext(styleContext)
	const useStyles = makeStyles({
		root: {
			background: opened ? themeContext.theme.backgroundColor : 'rgba(0, 0, 0, 0)',
			color: opened ? themeContext.theme.primary : 'white',
			height: 48,
			padding: '0 5px',
			border: '2.5px solid #ffffff',
			borderRadius: '9px',
			zIndex: 2,
			'&:hover': {
				backgroundColor: themeContext.theme.backgroundColor,
				color: themeContext.theme.primary,
				boxShadow:
					'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
				transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms'
				// border: '2.5px solid ' + themeContext.theme.primary,
			}
		},
		contained: {
			'&:active': {
				boxShadow:
					'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
			}
		},
		label: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			fontSize: 'x-small',
			textTransform: 'capitalize'
		},
		buttonText: {
			margin: '0 10px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'baseline',
			justifyContent: 'space-between'
		},
		paper: {
			width: buttonWidth + 'px',
			color: themeContext.theme.primary,
			paddingTop: buttonHeight + 'px',
			borderTopRightRadius: '9px',
			borderTopLeftRadius: '9px',
			backgroundColor: themeContext.theme.backgroundColor,
			transition: 'opacity 0.2s ease-in-out 0s, visibility 0.2s ease-in-out 0s;',
			opacity: opened ? 1 : 0,
			visibility: opened ? 'visible' : 'hidden',
			zIndex: 1
		},
		iconRoot: {
			transition: 'transform 0.3s ease-in-out 0s;'
		},
		menuItem: {
			fontWeight: 'bold',
			padding: '6px 6px',
			fontSize: 13,
			display: 'flex',
			justifyContent: 'space-between'
		}
	})
	const classes = useStyles()

	const handleClose = () => {
		setOpened(false)
	}

	// pour la largeur du menu
	const ref = useRef<HTMLButtonElement>(null)
	useEffect(() => {
		setButtonWidth(ref.current ? ref.current.offsetWidth + 7 : 0)
		setButtonHeight(ref.current ? ref.current.offsetHeight : 0)
	}, [ref.current])

	const handleClickAway = () => {
		if (opened === true) {
			handleClose()
		}
	}

	const buttonClick = () => {
		setOpened(!opened)
	}

	return (
		<authContext.Consumer>
			{(auth) => (
				<ClickAwayListener onClickAway={handleClickAway}>
					<div style={{ display: 'flex' }}>
						<div onClick={buttonClick} style={{
              height: '40px',
              width: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: COLORS.gray2,
              borderRadius: '50%',
              cursor: 'pointer',
            }}>
              <p style={{
                fontSize: '14px',
              }}>
                {user?.givenName.slice(0, 2)}
              </p>
						</div>

						<Paper
							classes={{ root: classes.paper }}
							style={{ position: 'absolute', width: 'fit-content' }}
						>
							<ContenuSettingsButton handleClose={handleClose} />
						</Paper>
					</div>
				</ClickAwayListener>
			)}
		</authContext.Consumer>
	)
}
