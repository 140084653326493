import React, { useContext, useState } from 'react'
import { authContext } from '../../Context/AuthContext'
import { ClubPost } from '../../Interfaces'
import ClubAvatar from '../ClubAvatar'
import { COLORS } from '../../constants'

interface PostPreview {
	onClick?: () => void
	onClose?: () => void
	openPopup?: boolean
	post: ClubPost
	mutate: () => void
	details?: boolean
	mobile?: boolean
	upperStyle?: React.CSSProperties
}

export default function PostPreview(Props: PostPreview) {
	const contextAuth = useContext(authContext)

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 10
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 10
				}}
			>
				<ClubAvatar
					src={
						Props.post.club.avatar !== null
							? `${contextAuth.apiUrl}${Props.post.club.avatar.url}`
							: 'https://i.imgur.com/b8UtZfg.png'
					}
					size={32}
				/>
				<p
					style={{
						fontSize: 14,
						fontWeight: 400
					}}
				>
					{Props.post.club.name}
				</p>
			</div>

			<div
				style={{
					...{
						flexDirection: Props.mobile ? 'column' : 'row'
					},
					display: 'flex',
					gap: 40
				}}
			>
				{Props.post.images?.map((image, index) => (
					<img
						key={index}
						src={contextAuth.apiUrl + image.url}
						alt={image.name}
						style={{
							width: Props.mobile ? '100%' : '50%',
							flex: 1,
							height: 'fit-content',
							objectFit: 'contain',
							borderRadius: 10
						}}
					/>
				))}
				{Props.post.instagramImage && (
					<img
						src={Props.post.instagramImage}
						alt={Props.post.titre}
						style={{
							width: Props.mobile ? '100%' : '50%',
							flex: 1,
							height: 'fit-content',
							objectFit: 'contain',
							borderRadius: 10
						}}
					/>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 10,
						width: Props.mobile ? '100%' : '50%',
						flex: 1,
						height: Props.mobile ? '100%' : 'fit-content'
					}}
				>
					<h5
						style={{
							fontSize: 16,
							fontWeight: 600,
							textAlign: 'start',
							margin: 0
						}}
					>
						{Props.post.titre}
					</h5>
					<p
						style={{
							fontSize: 14,
							fontWeight: 400,
							textAlign: 'start',
							margin: 0,
							whiteSpace: 'pre-line'
						}}
					>
						{Props.post.content}
						{Props.post.instagramId && Props.post.url && (
							<>
								<br /> <br />
								<a
									href={Props.post.url}
									target="_blank"
									rel="noopener noreferrer"
									style={{
										color: 'blue',
										textDecoration: 'none'
									}}
								>
									Lien vers le post Instagram
								</a>
							</>
						)}
					</p>
				</div>
			</div>
		</div>
	)
}

PostPreview.defaultProps = {
	details: true,
	openPopup: true
}
