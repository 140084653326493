import { styleContextI } from '../Context/StyleContext'

const light: styleContextI = {
    name: 'light',
    primary: '#171a29', 
    secondary: 'black',
    third: 'white',
    textColor: 'black',
    buttonFocus: '#171a29',
    backgroundColor: 'white',
    toggle: () => { }
}

export default light