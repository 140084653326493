import { Checkbox, CircularProgress, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../Context/AuthContext';
import { ClubInterface, UserInterface } from '../../Interfaces';
import useAutoCompleteData from './useAutoCompleteData';

interface AdminTableProps{
    club?: ClubInterface
    adminPost: (url: string, options: any) => Promise<any>
}

interface TableMember{
    displayName: string
    id: string
    isPres: boolean
    isVp: boolean
    isTres: boolean
    isSecr: boolean
}

export default function ClubAdminTable(Props: AdminTableProps){
    const contextAuth = useContext(authContext)
    const [userData, setUserData] = useState<TableMember[]>([])
    const [autoCompleteData, autoCompleteEntry, setAutocompleteEntry, isValidating, autoCompleteError] = useAutoCompleteData()
    const [requestError, setRequestError] = useState<string | undefined>()
    const [stringError, setStringError] = useState<string | undefined>()

    var getRoles = (user: UserInterface):TableMember => {
        let tableMember: TableMember = {displayName: user.displayName, id: user.id, isPres: false, isVp: false, isTres: false, isSecr: false}
        if(Props.club === undefined){
            return tableMember
        }
        [Props.club?.vps, Props.club?.tresoriers, Props.club?.secretaires].forEach((roleList: UserInterface[], index: number) => {
            roleList.forEach((roleUser: UserInterface) => {
                if(roleUser.id === user.id){
                    let role = ''
                    switch (index) {
                        case 0:
                            tableMember['isVp'] = true
                            break;
                        case 1:
                            tableMember['isTres'] = true
                            break;
                        case 2:
                            tableMember['isSecr'] = true
                            break;
                        default:
                            role = 'none'
                            break;
                    }
                }
            })
        });
        if (Props.club?.president.id === user.id) tableMember["isPres"] = true
        return tableMember
    }

    useEffect(() => {
        let tempUsers: TableMember[] = []
        Props.club?.members.forEach(member => {
            tempUsers.push(getRoles(member))
        });
        setUserData(tempUsers)
        if(autoCompleteError) setRequestError("Erreur de récupération de l'autocomplétion")
    }, [Props.club, autoCompleteError])
    
    const kickMember = (id: string, index: number) => {
        if(Props.club === undefined) return
        let tempUserData: TableMember[] = [...userData]
        Props.adminPost(`${contextAuth.apiUrl}/clubs/deletemember`, new URLSearchParams({
            clubId: Props.club?.id,
            targetId: id
        })).then(r => {
            tempUserData.splice(index, 1)
            setUserData(tempUserData)
        }).catch((err) => console.log(err))

    }

    const addMember = (newMember: UserInterface) => {
        if(Props.club === undefined) return
        let tempUserData: TableMember[] = [...userData]
        let newUserTable: TableMember = getRoles(newMember)
        for (let index = 0; index < tempUserData.length; index++) {
            if (tempUserData[index].id === newUserTable.id) {
                setStringError('Membre déjà présent')
                return
            }
        }
        Props.adminPost(`${contextAuth.apiUrl}/clubs/addmember`, new URLSearchParams({
            clubId: Props.club?.id,
            targetId: newMember.id
        })).then(r => {
            tempUserData.push(newUserTable)
            setUserData(tempUserData)
        }).catch((err) => console.log(err))
    }

    const changeRole = (role: 'isVp' | 'isSecr' | 'isTres', userId: string, index: number) => {
        if (Props.club === undefined) return

        let tempUserData: TableMember[] = [...userData]
        let user: TableMember = tempUserData[index]
        let endpoint: string = user[role] ? 'removerole' : 'addrole'

        let targetRole: number = {
            isVp: 3,
            isTres: 2,
            isSecr: 1
        }[role]

        Props.adminPost(`${contextAuth.apiUrl}/clubs/${endpoint}`, new URLSearchParams({
            clubId: Props.club?.id,
            targetId: userId,
            targetRole: targetRole.toString()
        })).then(r => {
            user[role] = !user[role]
            setUserData(tempUserData)
        }).catch((err) => console.log(err))
    }


    
    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label='Tableau des membres'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell align="center">Président</TableCell>
                            <TableCell align="center">Vice président</TableCell>
                            <TableCell align="center">Trésorier</TableCell>
                            <TableCell align="center">Secrétaire</TableCell>
                            <TableCell align="center">Membre/Exclure</TableCell>
                        </TableRow>
                    </TableHead>
                    {Props.club ?
                        <TableBody>
                            {userData.map((user, index: number) => {
                                return(
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {user.displayName}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox checked={user.isPres} disabled={true}/>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox checked={user.isVp} onClick={() => changeRole('isVp', user.id, index)}/>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox checked={user.isTres} onClick={() => changeRole('isTres', user.id, index)}/>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox checked={user.isSecr} onClick={() => changeRole('isSecr', user.id, index)}/>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={user.isPres} aria-label="Exclure" onClick={() => kickMember(user.id, index)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>

                                    </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                        :
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} style={{padding: 0}}>
                                    <Skeleton variant='rect' style={{width: '100%', height: '80px'}}></Skeleton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: '16px', marginBottom: '8px', alignItems: 'center'}}>
                <Typography variant='subtitle1' style={{marginRight: '10px', fontFamily: 'Product Sans'}}>Ajouter un membre: </Typography>
                <Autocomplete 
                    onInputChange={(event, newValue) => setAutocompleteEntry(newValue || '')}
                    onChange={(event, newValue) => {if(newValue) addMember(newValue)}}
                    style={{flexGrow: 1}}
                    getOptionLabel={(option) => option.displayName}
                    options={autoCompleteData || []} 
                    renderInput={(params) => 
                        <TextField {...params} 
                            label="Recherchez un membre de l'ESEO" 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isValidating ? <CircularProgress color="inherit" size={18} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            variant="outlined" />
                        }/>
            </div>
            <Snackbar open={requestError !== undefined} autoHideDuration={2000} onClose={() => setRequestError(undefined)}>
                <Alert onClose={() => setRequestError(undefined)} severity="error" variant='filled'>
                    {requestError}
                </Alert>
            </Snackbar>
            <Snackbar open={stringError !== undefined} autoHideDuration={2000} onClose={() => setStringError(undefined)}>
                <Alert onClose={() => setStringError(undefined)} severity="warning" variant="filled">
                    {stringError}
                </Alert>
            </Snackbar>
        </div>
    );
}