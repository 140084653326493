import { makeStyles, MenuItem, MenuList } from '@material-ui/core';
import { ExitToApp, Security } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { authContext } from '../Context/AuthContext';
import { styleContext } from '../Context/StyleContext';

interface HandleCloseProps {
    handleClose: () => void
}

export default function ContenuSettingsButton(props: HandleCloseProps){
    const themeContext = useContext(styleContext);
    let history = useHistory();
    const useStyles = makeStyles({
        menuItem: {
            fontWeight: 'bold',
            padding: '6px 6px',
            fontSize: 13,
            display: 'flex',
            justifyContent: 'space-between',
            color: themeContext.theme.primary,
        }
    })
    const classes = useStyles()
    // bouton mes infos
    const mesInfosHandler = () => {
        props.handleClose()
        history.push("/moi");
    }
    return(
        <authContext.Consumer>
            {auth =>
                <MenuList id="menu-list-grow">
                    <MenuItem classes={{ root: classes.menuItem }} onClick={mesInfosHandler}>
                        Mes informations<Security />
                    </MenuItem>
                    <MenuItem classes={{ root: classes.menuItem }} onClick={() => auth.clearAuth()}>
                        Se déconnecter<ExitToApp />
                    </MenuItem>
                </MenuList>
            }
        </authContext.Consumer>
    )
}