import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Snackbar,
	TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useContext, useState } from 'react'
import { mutate } from 'swr'
import { authContext } from '../../Context/AuthContext'
import { styleContext } from '../../Context/StyleContext'
import { ClubInterface, FileUpload } from '../../Interfaces'
import UploadButton from '../UploadButton'
import ImageGallery from './ImageGallery'

interface CreatePost {
	opened: boolean
	onClose: () => void
	selectedClub: ClubInterface
}

export default function CreatePost(Props: CreatePost) {
	const contextAuth = useContext(authContext)
	const themeContext = useContext(styleContext)
	const [postTitre, setPostTitre] = useState<string>('')
	const [postContent, setPostContent] = useState<string>('')
	const [postError, setPostError] = useState<string | undefined>()

	const adminPost = (url: string, options: FormData | URLSearchParams) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			method: 'POST',
			body: options
		}).then((r) => {
			if (!r.ok) {
				setPostError("Erreur lors de l'action: " + r.statusText)
				throw new Error(r.statusText)
			} else {
				return r
			}
		})

	const handlePostCreation = () => {
		let formData: FormData = new FormData()
		formData.append('title', postTitre)
		formData.append('club', Props.selectedClub.id)
		formData.append('content', postContent)
		formData.append('images', JSON.stringify(postImages.map((image) => image._id)))
		formData.append('type', 'texte')
		adminPost(contextAuth.apiUrl + '/news/createpost', formData)
			.then(() => mutate(contextAuth.apiUrl + '/news/posts?club=' + Props.selectedClub.id))
			.then(Props.onClose)
			.catch((err) => console.error(err))
	}

	const [postImages, setPostImages] = useState<FileUpload[]>([])

	const handleFinishedUpload = (file: FileUpload) => {
		setPostImages([...postImages, file])
	}

	const handleDeleteFile = (file: FileUpload) => {
		setPostImages(postImages.filter((image) => image.name !== file.name))
	}

	return (
		<Dialog open={Props.opened} onClose={Props.onClose} fullWidth>
			<DialogTitle>Créer un nouveau post</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Vous allez créer un post pour le club {Props.selectedClub.name}.
				</DialogContentText>
				<TextField
					autoFocus
					margin="normal"
					id="name"
					label="Titre"
					type="string"
					fullWidth
					required
					onChange={(event) => setPostTitre(event.target.value)}
				/>
				<TextField
					margin="normal"
					id="name"
					label="Contenu"
					type="string"
					fullWidth
					required
					multiline={true}
					rows={5}
					onChange={(event) => setPostContent(event.target.value)}
				/>
				<DialogContentText>Rajouter des images:</DialogContentText>
				<UploadButton
					type="postImage"
					adminPost={adminPost}
					clubID={Props.selectedClub.id}
					onSuccess={handleFinishedUpload}
				/>
				<ImageGallery images={postImages} delete={handleDeleteFile} />
			</DialogContent>
			<DialogActions>
				<Button onClick={Props.onClose} style={{ color: themeContext.theme.primary }}>
					Annuler
				</Button>
				<Button onClick={handlePostCreation} style={{ color: themeContext.theme.primary }}>
					Poster
				</Button>
			</DialogActions>
			<Snackbar
				open={postError !== undefined}
				autoHideDuration={4000}
				onClose={() => setPostError(undefined)}
			>
				<Alert onClose={() => setPostError(undefined)} severity="error" variant="filled">
					{postError}
				</Alert>
			</Snackbar>
		</Dialog>
	)
}
