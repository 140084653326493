import React, { useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { authContext } from '../../Context/AuthContext'
import { styleContext } from '../../Context/StyleContext'
import { ClubPost } from '../../Interfaces'
import ClubAvatar from '../ClubAvatar'
import ImageGallery from './ImageGallery'
import PostPreviewAuteur from './PostPreviewAuteur'
import PostPreviewContainer from './PostPreviewContainer'
import PostPreviewContentContainer from './PostPreviewContentContainer'
import PostPreviewTexte from './PostPreviewTexte'
import PostPreviewTitleContainer from './PostPreviewTitleContainer'
import PostPreviewTitre from './PostPreviewTitre'
import Upvotes from './Upvotes'

interface PostPopUpPost {
	post: ClubPost
	mobile?: boolean
}

export default function PostPopUpPost(Props: PostPopUpPost) {
	const themeContext = useContext(styleContext)
	const contextAuth = useContext(authContext)

	const [post, setPost] = useState<ClubPost>(Props.post)

	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	// Requetes pour clubs
	const {
		data: postSWR,
		error: postsError,
		mutate
	} = useSWR<ClubPost>(`${contextAuth.apiUrl}/news/posts?post=${Props.post.id}`, fetcher)

	useEffect(() => {
		if (postSWR !== undefined) setPost(postSWR)
		console.log(post)
	}, [postSWR])

	const postDate = new Date(Props.post.createdAt)

	return (
		<div
			style={{
				display: 'flex',
				width: Props.mobile ? '100%' : 'inherit',
				flexDirection: 'row',
				alignItems: 'baseline',
				marginRight: 10,
				position: Props.mobile ? 'static' : 'sticky',
				top: 0
			}}
			onClick={(event) => event.stopPropagation()}
		>
			<div
				style={{
					borderTopLeftRadius: 15,
					borderBottomLeftRadius: 15,
					backgroundColor: themeContext.theme.backgroundColor,
					height: 'fit-content',
					padding: 10
				}}
			>
				<Upvotes
					score={post.upvotes}
					post={post}
					voteState={post.voteState}
					mutate={mutate}
				/>
			</div>
			{post !== undefined && (
				<PostPreviewContainer style={{ border: 'none' }}>
					<PostPreviewTitleContainer>
						<ClubAvatar
							src={
								Props.post.club.avatar !== null
									? `${contextAuth.apiUrl}${Props.post.club.avatar.url}`
									: 'https://i.imgur.com/b8UtZfg.png'
							}
						/>
						<PostPreviewAuteur>
							{post.club.name} -{' '}
							{postDate.getDate() +
								'/' +
								(postDate.getMonth() + 1).toString() +
								'/' +
								postDate.getFullYear()}
						</PostPreviewAuteur>
					</PostPreviewTitleContainer>
					<PostPreviewContentContainer>
						<PostPreviewTitre>{post.titre}</PostPreviewTitre>
						<PostPreviewTexte>{post.content}</PostPreviewTexte>
					</PostPreviewContentContainer>
					{Props.post.images !== undefined && <ImageGallery images={Props.post.images} />}
				</PostPreviewContainer>
			)}
		</div>
	)
}
