import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { authContext } from '../../Context/AuthContext'
import { ClubInterface } from '../../Interfaces'

interface ClubSmallItemProps {
	club: ClubInterface
	loaded: boolean
	setClubSelected?: (val: ClubInterface) => void
}

export default function ClubSmallItem(Props: ClubSmallItemProps) {
	let history = useHistory()
	const contextAuth = useContext(authContext)

	const buttonCallback = () => {
		if (Props.loaded && Props.setClubSelected) {
			Props.setClubSelected(Props.club)
			history.push('infos/' + Props.club.name.replace(' ', '_'))
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: '5px',
				flex: 1,
				cursor: 'pointer'
			}}
			onClick={() => buttonCallback()}
		>
			<img
				style={{
					width: '64px',
					height: '64px',
					borderRadius: '50px'
				}}
				src={
					Props.club.avatar !== null
						? `${contextAuth.apiUrl}${Props.club.avatar.url}`
						: 'https://i.imgur.com/b8UtZfg.png'
				}
			/>
			<p
				style={{
					maxWidth: '100px',
					width: '100px',
					textAlign: 'center'
				}}
			>
				{Props.club.name}
			</p>
		</div>
	)
}
