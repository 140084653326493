import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core'
import { SendOutlined } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { mutate } from 'swr'
import { authContext } from '../../Context/AuthContext'
import { ClubPost } from '../../Interfaces'

interface PostPreviewCommentBox{
    post: ClubPost
}

export default function PostPreviewCommentBox(Props: PostPreviewCommentBox){

    const [content, setContent] = useState("")
    const contextAuth = useContext(authContext)

    const handleEnvoiComment = (event?: React.MouseEvent) => {
        if(content === "") return
        fetch(`${contextAuth.apiUrl}/news/createcomment`,
            {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }),
                body: new URLSearchParams({
                    isEnfant: "false",
                    post: Props.post.id,
                    content: content
                })
            })
            .then(() => setContent(""))
            .then(() => mutate(`${contextAuth.apiUrl}/news/comments?post=${Props.post?.id}`))
    }

    const [shiftPressed, setShiftPressed] = useState<boolean>(false)
    const [enterPressed, setEnterPressed] = useState<boolean>(false)

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 16) setShiftPressed(true)
        if (event.keyCode === 13) setEnterPressed(true)
    }

    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.keyCode === 16) setShiftPressed(false)
        if (event.keyCode === 13) setEnterPressed(false)
    }

    useEffect(() => {
        if (shiftPressed && enterPressed) setContent(content + "\r\n")
        else if (enterPressed) handleEnvoiComment()
    }, [shiftPressed, enterPressed])

    return(
        <div 
        style={{
            backgroundColor: 'rgba(0,0,0,0.1)',
            minHeight: 80,
            paddingTop: 19,
            top: '-19px',
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15,
            position: 'relative',
            zIndex: 9,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <OutlinedInput
                id="outlined-adornment-password"
                type='text'
                style={{width: '80%', borderRadius: 15, margin: 15}}
                placeholder="Rédigez votre message"
                multiline
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                value={content}
                onChange={(event) => setContent(event.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={handleEnvoiComment}
                        >
                            <SendOutlined/>
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    )
}