import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import { Create, Delete } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useState, useContext } from 'react'
import { styleContext } from '../../Context/StyleContext'

interface Props{
    ingredients: Ingredient[]
    adminPost: (url: string, options: any, method: string) => Promise<any>
}
export interface Ingredient{
    "_id": string,
    "name": string,
    "available": boolean,
    "price": number,
    "type": string,
    "createdAt": Date,
    "updatedAt": Date,
    "__v": number
}
export default function IngredientsTable(Props: Props){
    const themeContext = useContext(styleContext)

    const [dialogOpened, setDialogOpened] = useState<boolean>(false)
    const [ingredientSelected, setIngredientSelected] = useState<number>(0)
    const [newName, setNewName] = useState<string>('')

    const supprimerIngredient = (id: string) => {
        Props.adminPost('/cafet/removeIngredient', {
            id
        }, "DELETE").then(r => {
            console.log("ok")
        }).catch((err) => console.log(err))
    }

    const updateIngredient = (id: string, name: string, available: boolean, prix = -50) => {
        Props.adminPost('/cafet/updateIngredient', {
            id,
            name,
            available,
            price: prix !== -50 ? prix : 0
        }, "POST").then(r => {
            console.log("ok")
        }).catch((err) => console.log(err))
    }

    return(
        <div>
            <TableContainer component={Paper}>
                <Table aria-label='Tableau des membres'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Prix</TableCell>
                            <TableCell align="center">Disponibilité</TableCell>
                            <TableCell align="center">Supprimer</TableCell>
                        </TableRow>
                    </TableHead>
                    {Props.ingredients.length > 0 ?
                        <TableBody>
                            {Props.ingredients.map((ingredient, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                                                {ingredient.name}
                                                <IconButton style={{ marginLeft: '10px' }} onClick={() => setDialogOpened(!dialogOpened)}>
                                                    <Create/>
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{textAlign: 'center'}}>
                                            {ingredient.type}
                                        </TableCell>
                                        <TableCell align="center">
                                            <TextField
                                                margin="normal"
                                                id="price"
                                                label="Prix"
                                                type="number"
                                                disabled={['ingredient', 'sauce'].includes(ingredient.type)}
                                                onChange={(event) => updateIngredient(ingredient._id, ingredient.name, ingredient.available, parseFloat(event.target.value))}
                                                defaultValue={ingredient.price}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox checked={ingredient.available} onClick={() => updateIngredient(ingredient._id, ingredient.name, !ingredient.available)}/>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton aria-label="Supprimer" onClick={() => supprimerIngredient(ingredient._id)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>

                                    </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                        :
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} style={{ padding: 0 }}>
                                    <Skeleton variant='rect' style={{ width: '100%', height: '80px' }}></Skeleton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <Dialog open={dialogOpened} onClose={() => setDialogOpened(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Editer l'ingrédient</DialogTitle>
                <DialogContentText style={{ padding: '16px 24px'}}>
                    Ce nouveau nom sera représenté dans tous les plats directement.
                </DialogContentText>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nouveau nom de l'ingrédient"
                        type="string"
                        fullWidth
                        onChange={(event) => setNewName(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpened(false)} style={{color: themeContext.theme.primary}}>
                        Annuler
                    </Button>
                    <Button onClick={() => {
                        updateIngredient(Props.ingredients[ingredientSelected]._id, newName, Props.ingredients[ingredientSelected].available)
                        setDialogOpened(false)
                        }} style={{color: themeContext.theme.primary}}>
                        Appliquer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}