import React from 'react'

interface PostPreviewAuteur {
	children?: React.ReactNode
	style?: React.CSSProperties
}

export default function PostPreviewAuteur(Props: PostPreviewAuteur) {
	const defaultStyle: React.CSSProperties = {
		marginLeft: '15px',
		fontSize: '20px',
		fontWeight: 'bold',
		fontFamily: 'Product Sans'
	}

	return <p style={{ ...defaultStyle, ...Props.style }}>{Props.children}</p>
}
