import React from 'react'

interface PostPreviewContentContainer{
    children?: React.ReactNode,
    style?: React.CSSProperties,
}

export default function PostPreviewContentContainer(Props: PostPreviewContentContainer){
    const style: React.CSSProperties = {
        boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.16)',
        zIndex: 9,
        top: '-19px',
        padding: 19,
        position: 'relative',
        textAlign: 'left',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
    }
    return(
        <div style={{...style, ...Props.style}}>
            {Props.children}
        </div>
    )
}