import { makeStyles, Switch } from "@material-ui/core";
import React, { useContext } from 'react';
import { styleContext } from "../Context/StyleContext";

export default function ThemeSwitcher(){
    const themeContext = useContext(styleContext);

    const useStyles = makeStyles({
        root:{
            marginRight: 10
        },
        colorSecondary: {
            color: themeContext.theme.third,
            '&$checked': {
                color: themeContext.theme.primary,
            },
        }
    })
    const classes = useStyles()
    return(
        <Switch
            onChange={() => themeContext.toggle()}
            checked={themeContext.theme.name == 'dark'}
            classes={classes}
            name="ThemeSwitch"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
    )
}