import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import useSWR from 'swr'
import { authContext } from '../../../Context/AuthContext'
import { ClubInterface } from '../../../Interfaces'

export default function useLoadedClub(
	clubSelected: ClubInterface | undefined
): [ClubInterface | undefined, any, string] {
	let history = useHistory()
	const contextAuth = useContext(authContext)

	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())
			.then((r) => {
				if (!r.name) {
					console.error('CLUB NOT FOUND')
					throw new Error('Club non trouvé')
				} else {
					return r
				}
			})

	// obtention du club name
	let currentUrl: string = history.location.pathname
	let clubName: string = currentUrl.split('/')[3]
	clubName = clubName.replace('_', ' ')

	const [clubToUse, setClubToUse] = useState<ClubInterface>()

	const {
		data: club,
		error,
		mutate
	} = useSWR<ClubInterface>(
		!clubSelected ? `${contextAuth.apiUrl}/clubs/search/${clubName}` : null,
		fetcher,
		{
			onSuccess: (data, key, config) => {
				setClubToUse(data)
			}
		}
	)
	useEffect(() => {
		// si props club selected ca veut dire qu'il est deja chargé
		if (clubSelected) setClubToUse(clubSelected)
		mutate()
	}, [clubSelected, history.location.pathname])

	return [clubToUse, error, clubName]
}
