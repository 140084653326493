import { makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { Link, Route } from 'react-router-dom'
import '../../Assets/Fonts/fonts.css'
import ProfileButton from '../ProfileButton'
import { authContext } from '../../Context/AuthContext'
import './Appbar.css'
import { ReactComponent as Logo } from '../../Assets/svg/logo.svg'
import { COLORS } from '../../constants'

export default function Appbar() {
	// récuperer le theme en dehors du render
	const contextAuth = useContext(authContext)
	const useStyles = makeStyles({
		appBar: {
			position: 'static',
			backgroundColor: COLORS.bg,
			display: 'flex',
			justifyContent: 'center',
			width: '100%'
		},
		listItems: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			gap: '2vw',
			margin: 'auto',
			padding: '0px',
			listStyle: 'none',
			color: COLORS.bg
		},
		item: {
			textDecoration: 'none',
			color: COLORS.onBg,
			padding: '10px 20px',
			transition: 'all 0.2s ease-in-out',
			borderRadius: '50px'
		}
	})
	const styles = useStyles()

	function handleMouseOver(e: any) {
		e.currentTarget.style.backgroundColor = COLORS.gray2
	}
	function handleMouseOut(e: any) {
		e.currentTarget.style.backgroundColor = 'transparent'
	}
	return (
		<div className={styles.appBar}>
			<Route
				path="/"
				render={({ location }) => (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '20px',
							width: '100%',
							padding: '10px 10vw'
						}}
					>
						<Link
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '10px'
							}}
							className={styles.item}
							to="/home"
						>
							<Logo
								style={{ height: '54px', width: 'auto', margin: 'auto' }}
								fill={COLORS.primary}
							/>
							<h3>ESEODYSSEY</h3>
						</Link>
						<ul className={styles.listItems}>
							<Link
								className={styles.item}
								to="/home"
								onMouseOver={handleMouseOver}
								onMouseOut={handleMouseOut}
							>
								Accueil
							</Link>
							<Link
								className={styles.item}
								to="/calendrier"
								onMouseOver={handleMouseOver}
								onMouseOut={handleMouseOut}
							>
								Calendrier
							</Link>
							<Link
								className={styles.item}
								to="/clubs"
								onMouseOver={handleMouseOver}
								onMouseOut={handleMouseOut}
							>
								Clubs
							</Link>
							{contextAuth.authState.user?.cafetAdmin && (
								<Link
									className={styles.item}
									to="/cafeteria"
									onMouseOver={handleMouseOver}
									onMouseOut={handleMouseOut}
								>
									Cafeteria
								</Link>
							)}
							{(contextAuth.authState.user?.isBde || contextAuth.votesOpened) && (
								<>
									<Link
										className={styles.item}
										to="/votes"
										onMouseOver={handleMouseOver}
										onMouseOut={handleMouseOut}
									>
										Votes
									</Link>
								</>
							)}
						</ul>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<ProfileButton />
						</div>
					</div>
				)}
			/>
		</div>
	)
}
