import React, { useContext, useState } from 'react'
import { authContext } from '../../Context/AuthContext'
import { ClubPost } from '../../Interfaces'
import PostPopUp from '../../pages/Tabs/News/PostPopUp'
import ClubAvatar from '../ClubAvatar'
import ImageGallery from './ImageGallery'
import PostPreviewAuteur from './PostPreviewAuteur'
import PostPreviewContainer from './PostPreviewContainer'
import PostPreviewContentContainer from './PostPreviewContentContainer'
import PostPreviewTexte from './PostPreviewTexte'
import PostPreviewTitleContainer from './PostPreviewTitleContainer'
import PostPreviewTitre from './PostPreviewTitre'
import PostPreviewUpperContainer from './PostPreviewUpperContainer'
import PostPreviewUpvoteContainer from './PostPreviewUpvoteContainer'
import Upvotes from './Upvotes'

interface PostPreview {
	onClick?: () => void
	onClose?: () => void
	openPopup?: boolean
	post: ClubPost
	mutate: () => void
	style?: React.CSSProperties
	details?: boolean
	mobile?: boolean
	upperStyle?: React.CSSProperties
}

export default function PostPreview(Props: PostPreview) {
	const contextAuth = useContext(authContext)
	const handleVoteClick = (type: 'up' | 'down', event: React.MouseEvent) => {
		event.stopPropagation()
	}

	const [popupPost, setPopupPost] = useState<ClubPost | undefined>()

	const handleClick = () => {
		setPopupPost(Props.post)
		if (Props.onClick) Props.onClick()
	}

	const handleClose = () => {
		setPopupPost(undefined)
		if (Props.onClose) Props.onClose()
	}

	return (
		<PostPreviewContainer
			style={{
				...{
					width: Props.mobile ? '100%' : '450px',
					marginBottom: Props.mobile ? 30 : 60,
					marginTop: Props.mobile ? 30 : 60,
					borderRadius: Props.mobile ? 0 : 15
				},
				...Props.style
			}}
		>
			<PostPreviewUpperContainer style={Props.upperStyle} onClick={handleClick}>
				<PostPreviewTitleContainer>
					<ClubAvatar
						src={
							Props.post.club.avatar !== null
								? `${contextAuth.apiUrl}${Props.post.club.avatar.url}`
								: 'https://i.imgur.com/b8UtZfg.png'
						}
					/>
					<PostPreviewAuteur>{Props.post.club.name}</PostPreviewAuteur>
				</PostPreviewTitleContainer>
				<PostPreviewContentContainer>
					<PostPreviewTitre>{Props.post.titre}</PostPreviewTitre>
					<PostPreviewTexte>
						{Props.post.content.length > 300
							? Props.post.content.substring(0, 300) + '...'
							: Props.post.content}
					</PostPreviewTexte>
				</PostPreviewContentContainer>
			</PostPreviewUpperContainer>

			{Props.details === true && (
				<div className="postPreviewBottomPart">
					<PostPreviewUpvoteContainer>
						<Upvotes
							score={Props.post.upvotes}
							post={Props.post}
							voteState={Props.post.voteState}
							mutate={Props.mutate}
						/>
					</PostPreviewUpvoteContainer>
					{Props.post.images !== undefined && <ImageGallery images={Props.post.images} />}
				</div>
			)}

			{popupPost !== undefined && Props.openPopup && (
				<PostPopUp
					mobile={Props.mobile}
					opened={popupPost !== undefined}
					onClose={handleClose}
					selectedPost={popupPost}
				/>
			)}
		</PostPreviewContainer>
	)
}

PostPreview.defaultProps = {
	details: true,
	openPopup: true
}
