import { Fade, Modal } from '@material-ui/core'
import React from 'react'
import PostPopUpComments from '../../../Components/News/PostPopupComments'
import PostPopUpPost from '../../../Components/News/PostPopupPost'
import { ClubPost } from '../../../Interfaces'

interface PostPopUp {
	opened: boolean
	onClose?: () => void
	selectedPost: ClubPost
	mobile?: boolean
}

export default function PostPopUp(Props: PostPopUp) {
	const handleBackDrop = (event: React.MouseEvent) => {
		event.stopPropagation()
		if (Props.onClose !== undefined) Props.onClose()
	}

	return (
		<Modal
			open={Props.opened}
			onClose={Props.onClose}
			style={{ overflowY: 'scroll', width: '100%' }}
		>
			<Fade in={Props.opened} timeout={{ enter: 150, exit: 50 }}>
				<div
					style={{
						flexDirection: Props.mobile ? 'column' : 'row',
						width: Props.mobile ? '100%' : 'fit-content',
						justifyContent: 'center',
						alignItems: 'start',
						display: 'flex',
						margin: 'auto'
					}}
					onClick={handleBackDrop}
				>
					<PostPopUpPost post={Props.selectedPost} mobile={Props.mobile} />
					<PostPopUpComments post={Props.selectedPost} mobile={Props.mobile} />
				</div>
			</Fade>
		</Modal>
	)
}
