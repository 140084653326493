import { IconButton } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import React, { useContext } from 'react'
import { mutate } from 'swr'
import { authContext } from '../../Context/AuthContext'
import { styleContext } from '../../Context/StyleContext'
import { ClubPost, PostComment } from '../../Interfaces'
import PostPreviewUpvoteContainer from './PostPreviewUpvoteContainer'

interface Upvotes{
    voteState: 1|2|undefined
    score: number
    post?: ClubPost
    comment?: PostComment
    // mutate sert à réactualiser les données une fois l'upvote passé
    mutate?: () => void
}

export default function Upvotes(Props: Upvotes){
    const themeContext = useContext(styleContext)
    const contextAuth = useContext(authContext)

    const handleVoting = (type: "upvote"|"downvote", event: React.MouseEvent) => {
        event.stopPropagation()
        if (Props.comment !== undefined){
            fetch(`${contextAuth.apiUrl}/news/comment`,
            {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }), 
                body: new URLSearchParams({
                    type: type,
                    id: Props.comment.id
                })
                }).then(() => mutate(`${contextAuth.apiUrl}/news/comments?post=${Props.comment?.post}`))
        }
        else if(Props.post !== undefined){
            fetch(`${contextAuth.apiUrl}/news/post`,
                {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }),
                    body: new URLSearchParams({
                        type: type,
                        id: Props.post.id
                    })
                }).then(() => { if (Props.mutate !== undefined) Props.mutate() })
        }
    }

    return(
        <PostPreviewUpvoteContainer>
            <IconButton style={{ padding: 0 }} onClick={(event) => handleVoting("upvote", event)}>
                <ArrowBackIos style={{ transform: 'rotate(90deg)', cursor: 'pointer', color: Props.voteState === 1 ? themeContext.theme.primary : themeContext.theme.textColor }} />
            </IconButton>
            <p style={{lineHeight: 0, margin: 0}}>
                {Props.score}
            </p>
            <IconButton style={{ padding: 0 }} onClick={(event) => handleVoting("downvote", event)}>
                <ArrowBackIos style={{ transform: 'rotate(270deg)', cursor: 'pointer', color: Props.voteState === 2 ? themeContext.theme.primary : themeContext.theme.textColor }} />
            </IconButton>
        </PostPreviewUpvoteContainer>
    )
}