import React, { useContext, useState, useEffect, useRef } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { styleContext } from '../Context/StyleContext'

interface VerticalListProps{
    children?: React.ReactNode,
    style?: React.CSSProperties,
    childAmount?: number
    slideDistance?: number
}

export default function VerticalList(Props: VerticalListProps){
    const [width, setWidth] = useState<number>(0)
    const themeContext = useContext(styleContext)
    const ref = useRef<HTMLDivElement>(null);
    const useStyles = makeStyles({
        buttonRoot: {
            boxShadow: '0 0px 2px 0px black',
            backgroundColor: themeContext.theme.backgroundColor,
            color: themeContext.theme.primary,
            '&:hover': {
                backgroundColor: themeContext.theme.primary,
                color: themeContext.theme.third,
            }
        },

    })
    const [leftOffset, setLeftOffset] = useState<number>(0)
    const classes = useStyles()

    const SLIDE_DISTANCE: number = Props?.slideDistance || 200

    const handleScroll = (side: 'left'|'right') => {
        if(!Props.childAmount) return
        let scrollAmount: number = Math.floor(leftOffset / SLIDE_DISTANCE)
        if (side === 'right'){
            if (Math.abs(scrollAmount) < Props.childAmount - 2) setLeftOffset(leftOffset - SLIDE_DISTANCE)
        }
        else if (scrollAmount < 0) setLeftOffset(leftOffset + SLIDE_DISTANCE)
    }

    useEffect(() => {
        console.log('width', ref.current ? ref.current.offsetWidth : 0);
        setWidth(ref.current ? ref.current.offsetWidth + 7 : 0)
    }, [ref.current])

    return(
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            <IconButton classes={{root: classes.buttonRoot}} onClick={() => handleScroll('left')}>
                <ArrowBackIos viewBox='0 0 18 24'/>
            </IconButton>
            <div style={{
                flexGrow: 1,
                width: 'inherit',
                overflow: 'hidden',}}>
                <div style={{
                    position: 'relative',
                    left: leftOffset + 'px',
                    transition: 'left 200ms ease-in-out 0s'
                }}>
                    <div style={{ display: 'flex' }} ref={ref}>
                        {Props.children}
                    </div>
                </div>
            </div>
            <IconButton classes={{ root: classes.buttonRoot }} onClick={() => handleScroll('right')}>
                <ArrowForwardIos viewBox='0 0 18 24'/>
            </IconButton>
        </div>
    )
}