import premier from './+1.jpg'
import deuxieme from './+2.jpg'
import troisieme from './+3.jpg'
import quatrieme from './+4.jpg'
import soussol from './-1.jpg'
import rdc from './0.jpg'

const plans = [soussol, rdc, premier, deuxieme, troisieme, quatrieme]

export default plans
