import { makeStyles, useMediaQuery } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Switch, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { authContext } from '../../Context/AuthContext'
import { ClubInterface, ClubPost } from '../../Interfaces'
import NewsDesktopLayout from './News/NewsDesktopLayout'
import NewsMobileLayout from './News/NewsMobileLayout'

interface UrlParams {
	clubParam: string
	postParam: string
}

export default function News() {
	const useStyles = makeStyles({
		leftSideContainer: {
			position: 'sticky'
		}
	})
	const classes = useStyles()
	let { clubParam, postParam } = useParams<UrlParams>()

	const smallScreen = useMediaQuery('(max-width:600px)')
	const biggerScreen = useMediaQuery('(min-width:1330px)')

	const contextAuth = useContext(authContext)

	const [preSelectedsClub, setPreSelectedsClub] = useState<ClubInterface | undefined>(undefined)
	const [preSelectedPost, setPreSelectedPost] = useState<ClubPost | undefined>(undefined)

	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	// Requetes pour clubs
	const { data: zones, error: zonesError } = useSWR<ClubInterface[]>(
		`${contextAuth.apiUrl}/clubs/all?adminBde=true`,
		fetcher
	)
	// request pour le club dans l'url
	const { data: preZone, error: preZoneError } = useSWR<ClubInterface>(
		clubParam && `${contextAuth.apiUrl}/clubs/search/${clubParam}`,
		fetcher
	)
	const { data: prePost, error: prePostError } = useSWR<ClubPost>(
		postParam && `${contextAuth.apiUrl}/news/posts?post=${postParam}`,
		fetcher
	)

	useEffect(() => {
		if (clubParam !== undefined && zones !== undefined && preZone !== undefined) {
			let filteredClubs: ClubInterface[] = zones.filter((zone) => zone.id === preZone.id)
			if (filteredClubs.length === 1) setPreSelectedsClub(filteredClubs[0])
		}

		if (postParam !== undefined && prePost) {
			setPreSelectedPost(prePost)
		}

		if (postParam === undefined && clubParam === undefined && zones !== undefined) {
			let filteredClubs: ClubInterface[] = zones.filter((zone) => zone.name === 'BDE Eseo')
			if (filteredClubs.length === 1) setPreSelectedsClub(filteredClubs[0])
		}
	}, [zones, preZone, prePost])

	return (
		<div>
			<Helmet>
				<title>{`BDE ESEO | NEWS`}</title>
			</Helmet>
			<Switch>
				{biggerScreen ? (
					<NewsDesktopLayout
						clubs={zones}
						preSelectedClub={preSelectedsClub}
						preSelectedPost={preSelectedPost}
					/>
				) : (
					<NewsMobileLayout
						clubs={zones}
						preSelectedClub={preSelectedsClub}
						preSelectedPost={preSelectedPost}
					/>
				)}
			</Switch>
		</div>
	)
}
