import React, { useState } from 'react'
import { Ingredient } from './IngredientsTable'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

interface SelectIngredientsTable{
    ingredients: Ingredient[]
    onChangeSelected: (selected: string[]) => void
    defaultSelected?: string[]
}

export default function SelectIngredientsTable(Props: SelectIngredientsTable){
    const [selected, setSelected] = useState<string[]>(Props.defaultSelected || [])

    const removeSelectedFromId = (id: string) => {
        let filteredArray = selected.filter(item => item !== id)
        setSelected(filteredArray)
        Props.onChangeSelected(filteredArray)
    }

    return(
        <div>
            <TableContainer component={Paper}>
                <Table aria-label='Tableau des membres'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell align="center">Ajouter</TableCell>
                        </TableRow>
                    </TableHead>
                    {Props.ingredients.length > 0 ?
                        <TableBody>
                            {Props.ingredients.map((ingredient, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {ingredient.name}
                                            {!ingredient.available && ' (Non disponible)'}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox disabled={!ingredient.available} checked={selected.includes(ingredient._id)} 
                                                onClick={() => {
                                                    if (!selected.includes(ingredient._id)){
                                                        let newSelected = selected.concat(ingredient._id)
                                                        setSelected(newSelected)
                                                        Props.onChangeSelected(newSelected)
                                                    }
                                                    else removeSelectedFromId(ingredient._id)
                                                }} 
                                            />
                                        </TableCell>

                                    </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                        :
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} style={{ padding: 0 }}>
                                    <Skeleton variant='rect' style={{ width: '100%', height: '80px' }}></Skeleton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    }
                </Table>
            </TableContainer>
        </div>
    )
}