import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Fab,
	Grow,
	IconButton,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Snackbar,
	TextField,
	Typography
} from '@material-ui/core'
import { AccessTime, Check, Close, Fullscreen, FullscreenExit } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import useSWR, { mutate } from 'swr'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'
import { UserInterface } from '../../../Interfaces'

export interface CommandeAPIResult {
	_id: string
	accompagnements: Accompagnement[]
	additionalMessage: string
	boissons: Accompagnement[]
	price: number
	statusCode: number
	statusMessage: string
	createdAt: Date
	payType: 'Non payée' | 'Lydia' | 'Carte bancaire'
	user: UserInterface
	plats: Plat[]
}

interface Plat {
	available: boolean
	description: string
	name: string
	price: number
	stock: number
	ingredients: Ingredient[]
	sauces: Ingredient[]
}

interface Ingredient {
	available: boolean
	name: string
	type: string
}

interface Accompagnement {
	_id: string
	name: string
	available: boolean
	type: string
	price: number
	createdAt: string
	updatedAt: string
	__v: number
}

export default function Desk() {
	const contextAuth = useContext(authContext)

	// définition du fetcher pour SWR
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())
			.then((r) => {
				return r
			})

	const { data: commandes, error: commandesError } = useSWR<CommandeAPIResult[]>(
		`${contextAuth.apiUrl}/cafet/allCommandes?advanced=true&today=true`,
		fetcher,
		{ refreshInterval: 500 }
	)

	interface cafetState {
		state: boolean
	}
	const { data: cafetState, error: cafetError } = useSWR<cafetState>(
		`${contextAuth.apiUrl}/cafet/getCafetState`,
		fetcher,
		{ refreshInterval: 10000 }
	)

	const themeContext = useContext(styleContext)
	const useStyles = makeStyles((theme) => ({
		paperRoot: {
			margin: 20,
			padding: 10,
			width: 250,
			textAlign: 'left',
			color: 'white'
		},
		divider: {
			backgroundColor: 'white',
			margin: 5
		},
		dialogScroll: {
			alignItems: 'flex-end'
		},
		typoRoot: {},
		dropDown: {
			color: 'white'
		}
	}))
	const classes = useStyles()

	const stateCouleurs = ['#e84a45', '#f1c42b', '#58b973', '#3734eb']

	const [fullscreen, setFullscreen] = useState<boolean>(false)
	const [updateDialog, setUpdateDialog] = useState<boolean>(false)

	document.addEventListener(
		'keydown',
		function (e) {
			switch (e.keyCode) {
				case 27:
					setFullscreen(false)
					break
				case 13:
					if (updateDialog && idChange !== undefined) handleSendChange()
					else if (updateDialog && !idChange) setUpdateDialog(false)
					else setUpdateDialog(true)
					break
				default:
					break
			}
		},
		{ once: true }
	)

	const [idChange, setIdChange] = useState<number | undefined>()

	const handleCommandChange = (id: number) => {
		console.log(id)
		if (id !== idChange) setIdChange(id)
	}

	const adminPost = (url: string, options: any, method: string) =>
		fetch(`${contextAuth.apiUrl}${url}`, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			method: method,
			body: new URLSearchParams(options)
		}).then((r) => {
			if (!r.ok) {
				setStringError("Erreur lors de l'action: " + r.statusText)
				console.error(r.statusText)
			} else {
				mutate(`${contextAuth.apiUrl}/cafet/allCommandes?advanced=true&today=true`)
				mutate(`${contextAuth.apiUrl}/cafet/getCafetState`)
				return r
			}
		})

	const handleSendChange = (newStatus?: number, id?: number, payType = false) => {
		console.log('HANDLE CHANGE: ' + idChange)
		setUpdateDialog(false)

		let idToChange = idChange !== undefined ? idChange : id

		if (idToChange === undefined || commandes === undefined) {
			return
		} else if (idToChange > (commandes === undefined ? 0 : commandes.length)) {
			setStringError("L'id n'existe pas")
			setIdChange(undefined)
			return
		} else {
			console.log({
				id: commandes[idToChange]._id,
				newStatus:
					newStatus !== undefined ? newStatus : commandes[idToChange].statusCode + 1
			})
			let nvStatus =
				newStatus !== undefined ? newStatus : commandes[idToChange].statusCode + 1
			if (nvStatus > 3) {
				setStringError('La commande est déjà encaissée')
				return
			}
			const query = payType
				? {
						id: commandes[idToChange]._id,
						newPayType: ['Non payée', 'Lydia', 'Carte bancaire'][
							newStatus !== undefined ? newStatus : 0
						]
				  }
				: {
						id: commandes[idToChange]._id,
						newStatus: nvStatus
				  }
			console.log(query)
			adminPost('/cafet/updateCommande', query, 'POST')
		}
		setIdChange(undefined)
	}

	const setCafetState = (state: boolean) => {
		adminPost(
			'/cafet/updateCafet',
			{
				state
			},
			'POST'
		)
	}

	const handleMenuChange = (
		event: React.ChangeEvent<{ value: unknown }>,
		index: number,
		payType = false
	) => {
		if (payType) handleSendChange(event.target.value as number, index, true)
		else handleSendChange(event.target.value as number, index)
	}

	const getValue = (val: string): number => {
		return ['Non payée', 'Lydia', 'Carte bancaire'].indexOf(val)
	}

	const [stringError, setStringError] = useState<string | undefined>()

	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					position: fullscreen ? 'absolute' : 'static',
					top: fullscreen ? '0' : 'auto',
					width: fullscreen ? '100%' : 'auto',
					zIndex: fullscreen ? 500 : 'auto',
					backgroundColor: fullscreen ? 'white' : 'transparent'
				}}
			>
				<Helmet>
					<title>{`BDE ESEO | DESK`}</title>
				</Helmet>
				{commandes?.map((commande, index) => (
					<Paper
						elevation={3}
						classes={{ root: classes.paperRoot }}
						style={{ backgroundColor: stateCouleurs[commande.statusCode] }}
					>
						<Typography
							variant="h6"
							style={{ fontWeight: 'bold' }}
							classes={{ root: classes.typoRoot }}
						>
							#{index} - {commande.user.displayName}
						</Typography>

						<div style={{ display: 'flex', alignItems: 'center' }}>
							<AccessTime style={{ marginRight: 3 }} />
							{new Date(commande.createdAt).toTimeString().split(' ')[0]} |{' '}
							{commande.price} €
						</div>
						<Divider classes={{ root: classes.divider }} />
						<Typography variant="h6" classes={{ root: classes.typoRoot }}>
							Plats:
						</Typography>

						{commande.plats.map((plat) => (
							<div style={{ marginLeft: 20 }}>
								<Typography variant="h6" classes={{ root: classes.typoRoot }}>
									- {plat.name}:
								</Typography>
								<div style={{ marginLeft: 20 }}>
									{plat.ingredients.map((ingredient) => (
										<Typography
											variant="body1"
											classes={{ root: classes.typoRoot }}
										>
											• {ingredient.name}
										</Typography>
									))}
								</div>
								{plat.sauces.length > 0 && (
									<div style={{ marginLeft: 20 }}>
										<Typography
											variant="body2"
											classes={{ root: classes.typoRoot }}
										>
											- Sauces:
										</Typography>
										<div style={{ marginLeft: 20 }}>
											{plat.sauces.map((sauce) => (
												<Typography
													variant="body1"
													classes={{ root: classes.typoRoot }}
												>
													• {sauce.name}
												</Typography>
											))}
										</div>
									</div>
								)}
							</div>
						))}

						{commande.accompagnements.length !== 0 && (
							<div>
								<Divider classes={{ root: classes.divider }} />
								<Typography variant="h6" classes={{ root: classes.typoRoot }}>
									Accompagnements:
								</Typography>
								{commande.accompagnements.map((accompagnement) => (
									<div style={{ marginLeft: 20 }}>• {accompagnement.name}</div>
								))}
							</div>
						)}

						{commande.boissons.length !== 0 && (
							<div>
								<Divider classes={{ root: classes.divider }} />
								<Typography variant="h6" classes={{ root: classes.typoRoot }}>
									Boissons:
								</Typography>
								{commande.boissons.map((boisson) => (
									<div style={{ marginLeft: 20 }}>• {boisson.name}</div>
								))}
							</div>
						)}

						{commande.additionalMessage !== '' && (
							<div>
								<Divider classes={{ root: classes.divider }} />
								<Typography variant="h6" classes={{ root: classes.typoRoot }}>
									Message:
								</Typography>
								<Typography variant="body1" classes={{ root: classes.typoRoot }}>
									{commande.additionalMessage}
								</Typography>
							</div>
						)}

						<Select
							classes={{ root: classes.dropDown }}
							fullWidth
							value={commande.statusCode}
							onChange={(event) => handleMenuChange(event, index)}
						>
							<MenuItem value={0}>Enregistrée</MenuItem>
							<MenuItem value={1}>En préparation</MenuItem>
							<MenuItem value={2}>Finie</MenuItem>
							<MenuItem value={3}>Encaissée</MenuItem>
						</Select>

						{commande.statusCode === 3 && (
							<Select
								classes={{ root: classes.dropDown }}
								fullWidth
								value={getValue(commande.payType)}
								onChange={(event) => handleMenuChange(event, index, true)}
							>
								<MenuItem value={0}>Non payée</MenuItem>
								<MenuItem value={1}>Lydia</MenuItem>
								<MenuItem value={2}>Carte bancaire</MenuItem>
							</Select>
						)}
					</Paper>
				))}
				<Dialog
					TransitionComponent={Grow}
					classes={{ scrollPaper: classes.dialogScroll }}
					open={updateDialog}
					onClose={() => setUpdateDialog(false)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle>Modifier une commande</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							margin="dense"
							id="id"
							label="ID"
							type="text"
							fullWidth
							onChange={(e) => handleCommandChange(Number(e.currentTarget.value))}
						/>
					</DialogContent>
				</Dialog>
			</div>

			{/* bouton d'ouverture du fullscreen */}
			<Fab
				variant="extended"
				style={{
					position: 'fixed',
					right: 20,
					bottom: 20,
					padding: 0,
					zIndex: 600
				}}
			>
				<IconButton onClick={() => setFullscreen(!fullscreen)}>
					{fullscreen ? <FullscreenExit /> : <Fullscreen />}
				</IconButton>
			</Fab>

			{/* bouton d'ouverture de cafet */}
			<Fab
				variant="extended"
				style={{
					position: 'fixed',
					right: 84,
					bottom: 20,
					padding: 0,
					zIndex: 600,
					backgroundColor: cafetState?.state ? 'green' : 'red'
				}}
			>
				<IconButton
					onClick={() => setCafetState(!cafetState?.state)}
					style={{ color: 'white' }}
				>
					<Typography variant="caption">
						{cafetState?.state ? 'Ouverte' : 'Fermée'}
					</Typography>
					{cafetState?.state ? <Check /> : <Close />}
				</IconButton>
			</Fab>

			{/* snackbar d'erreur */}
			<Snackbar
				open={stringError !== undefined}
				autoHideDuration={2000}
				onClose={() => setStringError(undefined)}
			>
				<Alert
					onClose={() => setStringError(undefined)}
					severity="warning"
					variant="filled"
				>
					{stringError}
				</Alert>
			</Snackbar>
		</div>
	)
}
