import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { styleContext } from '../../Context/StyleContext'

interface CommanderTabButton{
    label: string
    onClick?: (event?: any) => void
    index: number
    currentSelection: number
    disabled?: boolean
    labelPosition: 'right' | 'under'
}

export default function CommanderTabButton(Props: CommanderTabButton){
    const themeContext = useContext(styleContext)
    const useStyles = makeStyles({
        buttonRoot: {
            backgroundColor: Props.currentSelection === Props.index ? themeContext.theme.primary : themeContext.theme.backgroundColor,
            color: Props.currentSelection === Props.index ? themeContext.theme.backgroundColor : themeContext.theme.textColor,
            boxShadow: '3px 3px 6px 0px rgba(0,0,0,0.75)',
            '&:hover': {
                backgroundColor: themeContext.theme.third,
                color: themeContext.theme.primary,
                border: "1px solid " + themeContext.theme.primary
            },
            height: 54,
            width: 54,
            minWidth: 54,
            minHeight: 54,
            borderRadius: 27,
            padding: 0,
            marginRight: 10,
            marginTop: 10,
            marginBottom: 10,

        },
        container:{
            display: 'flex',
            flexDirection: Props.labelPosition === 'right' ? 'row' : 'column',
            alignItems: 'center',
            justifyContent: 'left',
            padding: Props.labelPosition === 'under' ? 0 : 16
        },
        typoRoot:{
            fontFamily: 'Product Sans'
        }
    })
    const classes = useStyles()
    return(
        <Container classes={{root: classes.container}}>
            <Button classes={{root: classes.buttonRoot}} onClick={(event) => Props.onClick ? Props.onClick(event) : {}} disabled={Props.disabled}>
                <Typography variant="h5" classes={{ root: classes.typoRoot }}>
                    {Props.index}
                </Typography>
            </Button>
            {Props.labelPosition === 'right' && 
                <Typography variant="h6" classes={{ root: classes.typoRoot }}>
                    {Props.label}
                </Typography>
            }
        </Container>
    )
}