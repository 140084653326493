import React from 'react'

interface PostPreviewTexte {
	children?: React.ReactNode
}

export default function PostPreviewTexte(Props: PostPreviewTexte) {
	return (
		<p
			style={{
				fontSize: '16px',
				fontFamily: 'Roboto',
				whiteSpace: 'break-spaces'
			}}
		>
			{Props.children}
		</p>
	)
}
