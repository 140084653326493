//define colors constants

export const COLORS = {
  primary: '#171a29',
  bg: '#FFFFFF',
  bg2: '#F3F3F7',
  onBg: '#171a29',
  onBgSecondary: '#838383',
  gray: '#EDEDF3',
  gray2: "#DFE3EB",
  gray3: '#D0D6E2',
  textGray: '#838383',
}
