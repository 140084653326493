import React from 'react'

interface PostPreviewTitleContainer{
    children?: React.ReactNode
}

export default function PostPreviewTitleContainer(Props: PostPreviewTitleContainer){
    return(
        <div style={{
            zIndex: 10,
            display: 'flex',
            paddingLeft: 20,
            alignItems: 'center',
            backgroundColor: 'inherit',
            borderRadius: 15,
            height: 60,
            position: 'relative',
            boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.16)'
        }}>
            {Props.children}
        </div>
    )
}