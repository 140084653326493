import { Avatar, makeStyles, Typography } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import React, { useContext } from 'react'
import { styleContext } from '../Context/StyleContext'
import { UserInterface } from '../Interfaces'

interface MemberButtonProps {
	member?: UserInterface
	role?: 'Président' | 'Trésorier' | 'Secrétaire' | 'Vice-Président' | 'Membre'
}

export default function MemberButton(Props: MemberButtonProps) {
	const themeContext = useContext(styleContext)
	const useStyles = makeStyles({
		avatarRoot: {
			height: '60px',
			width: '60px',
			border: '4px solid white',
			boxShadow: '-0.6px 0 14px -5px black',
			zIndex: 1
		}
	})
	const classes = useStyles()
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				padding: '5px'
			}}
		>
			<Avatar
				alt={Props.member?.displayName || 'Chargement...'}
				classes={{ root: classes.avatarRoot }}
			>
				<Person />
			</Avatar>
			<div
				style={{
					position: 'relative',
					left: '-20px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'flex-start',
					paddingLeft: '27px',
					paddingTop: '7px',
					paddingBottom: '7px',
					paddingRight: '7px',
					borderRadius: '8px',
					boxShadow: '0px 0 14px -5px black',
					width: 'max-content'
				}}
			>
				<Typography
					variant="subtitle1"
					style={{ lineHeight: 1, fontSize: 14, fontWeight: 'bold' }}
				>
					{Props.member?.displayName}
				</Typography>
				<Typography variant="body2" style={{ fontSize: 10 }}>
					{Props?.role || 'Membre'}
				</Typography>
			</div>
		</div>
	)
}
