import React, { useContext } from 'react'
import { styleContext } from '../../Context/StyleContext'

interface PostPreviewUpvoteContainer{
    children?: React.ReactNode
}

export default function PostPreviewUpvoteContainer(Props: PostPreviewUpvoteContainer){
    const themeContext = useContext(styleContext)
    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: themeContext.theme.textColor
        }}>
            {Props.children}
        </div>
    )
}