import FullCalendar, { EventApi, formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import iCalendarPlugin from '@fullcalendar/icalendar'
import timeGridPlugin from '@fullcalendar/timegrid'
import React, { useContext, useState } from 'react'
import useSWR from 'swr'
import { authContext } from '../../Context/AuthContext'
import { styleContext } from '../../Context/StyleContext'
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	InputAdornment,
	Snackbar,
	TextField,
	Tooltip,
	useMediaQuery
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Alert } from '@material-ui/lab'
import Header from '../../Components/Header'

interface Event {
	title: string
	start: string
	end: string
	location: string
	extendedProps?: any
}

export default function Calendrier() {
	const contextAuth = useContext(authContext)
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	const { data: events, error: error } = useSWR<Event[]>(
		contextAuth.apiUrl + '/calendar/events',
		fetcher
	)
	const [copied, setCopied] = useState<Boolean>(false)
	const [focus, setFocus] = useState<Boolean>(false)

	const [BDEcopied, setBDECopied] = useState<Boolean>(false)
	const [BDEfocus, setBDEFocus] = useState<Boolean>(false)

	const [url, setUrl] = useState<string>('')
	const [status, setStatus] = useState<'notsaved' | 'saved' | 'error'>('notsaved')
	const saveURL = () => {
		var urlencoded = new URLSearchParams()
		urlencoded.append('url', url)
		fetch(`${contextAuth.apiUrl}/calendar/save`, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			method: 'POST',
			body: urlencoded
		})
			.then((response) => {
				if (!response.ok) {
					setStatus('error')
					throw new Error(response.statusText)
				} else {
					return response.json()
				}
			})
			.then((response) => {
				if (response.success) {
					setStatus('saved')
				} else {
					setStatus('error')
				}
			})
			.catch((response) => console.error(response))
	}

	const [open, setOpen] = useState<boolean>(false)
	const [event, setEvent] = useState<EventApi | undefined>()
	const handleClose = () => setOpen(false)

	const themeContext = useContext(styleContext)
	const bigScreen = useMediaQuery('(min-width:760px)')
	const smallScreen = useMediaQuery('(max-width:430px)')

	const calendarRef = React.useRef<FullCalendar | null>(null)

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '30px',
				padding: '30px 10vw'
			}}
		>
			<Header title="Calendrier" subtitle="Voir les cours et les évènements à venir" />
			<form style={{ marginBottom: 20 }}>
				<TextField
					placeholder="Ajoute ton calendrier ESEO ici"
					defaultValue={contextAuth.authState.user?.calendar_url}
					variant="outlined"
					style={{ width: smallScreen ? '100%' : 400 }}
					onChange={(event) => setUrl(event.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end" onMouseEnter={() => setCopied(false)}>
								<Tooltip
									title={
										copied
											? 'Copié !'
											: 'Cliquez pour copier : https://reseaueseo.sharepoint.com/sites/etu/Pages/synchro-agenda.aspx'
									}
								>
									<CopyToClipboard
										onCopy={() => setCopied(true)}
										text="https://reseaueseo.sharepoint.com/sites/etu/Pages/synchro-agenda.aspx"
									>
										<InfoIcon
											style={{
												cursor: 'pointer',
												color: themeContext.theme.primary
											}}
										/>
									</CopyToClipboard>
								</Tooltip>
							</InputAdornment>
						)
					}}
				/>
				<Button
					variant="contained"
					onClick={() => saveURL()}
					onMouseEnter={() => setFocus(true)}
					onMouseLeave={() => setFocus(false)}
					style={{
						backgroundColor: focus
							? themeContext.theme.buttonFocus
							: themeContext.theme.primary,
						color: 'white',
						marginTop: 10,
						marginLeft: 20
					}}
				>
					Enregister
				</Button>
			</form>
			{/*<Tooltip
				title={
					BDEcopied
						? 'Copié !'
						: 'Cliquez pour copier : https://api.bdeeseo.fr/calendar/bde.ics'
				}
			>
				<CopyToClipboard
					onCopy={() => setBDECopied(true)}
					text="https://api.bdeeseo.fr/calendar/bde.ics"
				>
					<Button
						variant="contained"
						onMouseEnter={() => setBDEFocus(true)}
						onMouseLeave={() => setBDEFocus(false)}
						onBlur={() => setBDEFocus(false)}
						style={{
							backgroundColor: BDEfocus
								? themeContext.theme.buttonFocus
								: themeContext.theme.primary,
							color: 'white',
							marginTop: 10,
							marginBottom: 15
						}}
					>
						Lien du calendrier BDE pour le synchroniser
					</Button>
				</CopyToClipboard>
					</Tooltip>*/}
			<FullCalendar
				locale="fr"
				timeZone="UTC"
				plugins={[dayGridPlugin, timeGridPlugin, iCalendarPlugin]}
				weekends={true}
				headerToolbar={{
					left: bigScreen ? 'prev,next today' : 'prev,next',
					center: bigScreen ? 'title' : '',
					right: bigScreen ? 'dayGridMonth,timeGridWeek,timeGridDay' : 'today'
				}}
				initialView={bigScreen ? 'timeGridWeek' : 'timeGridDay'}
				slotMinTime="07:00:00"
				contentHeight="auto"
				firstDay={1}
				allDaySlot={false}
				events={
					events !== undefined
						? events.map((event) => {
								return {
									...event,
									...{ extendedProps: { location: event.location } }
								}
						  })
						: []
				}
				eventColor={themeContext.theme.primary}
				buttonText={{
					today: "Aujourd'hui",
					month: 'Mois',
					week: 'Semaine',
					day: 'Jour'
				}}
				eventClick={function (arg) {
					setOpen(true)
					setEvent(arg.event)
					console.log(arg.event)
				}}
				ref={calendarRef}
				windowResize={function (arg) {
					if (calendarRef.current !== null) {
						calendarRef.current
							.getApi()
							.changeView(bigScreen ? 'timeGridWeek' : 'timeGridDay')
					}
				}}
			/>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle
					style={{ backgroundColor: themeContext.theme.primary, color: 'white' }}
				>
					{event?.start
						? formatDate(event?.start, {
								month: 'long',
								year: 'numeric',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
								locale: 'fr',
								timeZone: 'UTC'
						  })
						: ''}
					{' - '}
					{event?.end
						? formatDate(event?.end, {
								hour: 'numeric',
								minute: 'numeric',
								locale: 'fr',
								timeZone: 'UTC'
						  })
						: ''}
				</DialogTitle>
				<DialogContent dividers>
					<p>{event?.title}</p>

					<p>{event?.extendedProps.location}</p>
				</DialogContent>
			</Dialog>
			<Snackbar
				open={status !== 'notsaved'}
				autoHideDuration={6000}
				onClose={() => setStatus('notsaved')}
			>
				<Alert
					onClose={() => setStatus('notsaved')}
					severity={status === 'saved' ? 'success' : 'error'}
					variant="filled"
				>
					{status === 'saved'
						? 'Votre calendrier ESEO a bien été sauvegardé'
						: "Une erreur s'est produite, veuillez réessayer"}
				</Alert>
			</Snackbar>
		</div>
	)
}
