import { Button, Checkbox, colors, Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { authContext } from '../../Context/AuthContext'
import { styleContext } from '../../Context/StyleContext'
import { Plat } from '../../pages/Tabs/Cafeteria/Plats'
import { Ingredient } from './IngredientsTable'
import SelectIngredientsTable from './SelectIngredientsTable'

interface PlatCreator{
    opened: boolean
    onClose: () => void
    type: 'editing'|'creating'
    adminPost: (url: string, options: any, method: string) => Promise<any>
    plat?: Plat
}

export default function PlatCreator(Props: PlatCreator){

    const themeContext = useContext(styleContext)
    const useStyles = makeStyles((theme) => ({
        cardRoot: {
            textAlign: 'left',
            padding: '20px',
            boxShadow: 'none',
            [theme.breakpoints.down('md')]: {
                width: '90vw'
            },
            [theme.breakpoints.up('sm')]: {
                width: '70vw'
            },
            marginTop: '10px'
        },
        typoFont: {
            fontFamily: 'Product Sans',
            marginBottom: '4px'
        },
        textFieldRoot: {
            '&.Mui-focused fieldset': {
                borderColor: themeContext.theme.primary
            },
        },
        divider: {
            marginTop: '8px',
            marginBottom: '8px'
        }
    }))
    const classes = useStyles()

    // définition du fetcher pour SWR
    const contextAuth = useContext(authContext)
    const fetcher = (url: string) => fetch(url, {
        headers: new Headers({
            'Authorization': 'Bearer ' + contextAuth.authState.jwtoken,
        })
    }).then(r => { if (!r.ok) { throw new Error(r.statusText) } else { return r } }).then(r => r.json())

    const { data: ingredients, error: ingredientsError } = useSWR<Ingredient[]>(`${contextAuth.apiUrl}/cafet/allIngredients?type=ingredient`, fetcher)

    // hooks du nouveau plat
    const [newName, setNewName] = useState<string>(Props.plat?.name || '')
    const [newDescription, setNewDescription] = useState<string>(Props.plat?.description || '')
    const [newAvailable, setNewAvailable] = useState<boolean>(Props.plat?.available || true)
    const [newPrice, setNewPrice] = useState<number>(Props.plat?.price || 0)
    const [newStock, setNewStock] = useState<number>(Props.plat?.stock || 0)
    const [newSelected, setSelected] = useState<string[]>([])

    const applyHandler = () => {
        if(Props.type === 'creating') createPlat()
        else updatePlat()
    }

    const createPlat = () => {
        Props.adminPost('/cafet/addPlat', {
            name: newName,
            description: newDescription,
            available: newAvailable,
            price: newPrice,
            ingredients: newSelected,
            stock: newStock
        }, 'POST')
    }

    const updatePlat = () => {
        Props.adminPost('/cafet/updatePlat', {
            name: newName,
            description: newDescription,
            available: newAvailable,
            price: newPrice,
            ingredients: newSelected,
            stock: newStock,
            id: Props.plat?._id
        }, 'POST')
    }

    useEffect(() => {
        setNewName(Props.plat?.name || '')
        setNewDescription(Props.plat?.description || '')
        setNewAvailable(Props.plat?.available !== undefined ? Props.plat?.available : true)
        setNewPrice(Props.plat?.price || 0)
        setNewStock(Props.plat?.stock || 0)
        setSelected(Props.plat?.ingredients.map((ingr) => ingr._id) || [])
    },[Props.opened, Props.plat])

    return(
        <Dialog open={Props.opened} onClose={Props.onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">Créer un nouveau plat</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Nom du plat"
                    type="string"
                    fullWidth
                    required
                    onChange={(event) => setNewName(event.target.value)}
                    defaultValue={Props.plat?.name}
                />
                <TextField
                    margin="normal"
                    id="description"
                    rowsMax={2}
                    rows={2}
                    multiline={true}
                    label="Description du plat"
                    type="string"
                    fullWidth
                    required
                    onChange={(event) => setNewDescription(event.target.value)}
                    defaultValue={Props.plat?.description}
                />
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' classes={{ root: classes.typoFont }}>Disponible?: </Typography>
                    <Checkbox checked={newAvailable} disabled={false} onClick={() => setNewAvailable(!newAvailable)} />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <TextField
                        required
                        margin="normal"
                        id="price"
                        label="Prix du plat"
                        type="number"
                        onChange={(event) => setNewPrice(parseFloat(event.target.value))}
                        defaultValue={Props.plat?.price}
                    />
                    <TextField
                        required
                        margin="normal"
                        id="stock"
                        label="Stock"
                        type="number"
                        onChange={(event) => setNewStock(parseFloat(event.target.value))}
                        defaultValue={Props.plat?.stock}
                    />
                </div>
                <Divider classes={{ root: classes.divider }} />
                <SelectIngredientsTable ingredients={ingredients || []} onChangeSelected={setSelected} defaultSelected={Props.plat?.ingredients.map(ingredient => ingredient._id)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => Props.onClose()} style={{color: themeContext.theme.primary}}>
                    Annuler
                    </Button>
                <Button onClick={() => {
                    applyHandler()
                    Props.onClose()
                }} style={{color: themeContext.theme.primary}}>
                    {Props.type === 'creating' ? 'Créer' : 'Appliquer'}
                    </Button>
            </DialogActions>
        </Dialog>
    )
}