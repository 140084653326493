import { Fade, MenuItem, Select, useMediaQuery } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { useContext } from 'react'
import Salles from '../../../Components/Dashboard/Salles'
import { styleContext } from '../../../Context/StyleContext'
import plans from './plans/export'
import liste from './plans/salles.json'
import Header from '../../../Components/Header'

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props
	const bigScreen = useMediaQuery('(min-width:1470px)')

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box
					p={3}
					style={{
						display: bigScreen ? 'flex' : 'block',
						paddingTop: bigScreen ? 20 : 60
					}}
				>
					{children}
				</Box>
			)}
		</div>
	)
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`
	}
}

export default function VerticalTabs() {
	const themeContext = useContext(styleContext)
	const useStyles = makeStyles((theme) => ({
		buttonRoot: {
			color: themeContext.theme.textColor,
			borderColor: themeContext.theme.textColor
		}
	}))
	const classes = useStyles()

	const [value, setValue] = React.useState<number | unknown>(0)

	const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}
	const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		setValue(event.target.value)
	}
	const bigScreen = useMediaQuery('(min-width:1470px)')
	const smallScreen = useMediaQuery('(max-width:1200px)')

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '30px',
				padding: '30px 10vw'
			}}
		>
			<Header title="Plans" subtitle="Les plans et salles de l'ESEO" />
			<div
				style={{
					marginTop: 10,
					background: themeContext.theme.backgroundColor,
					display: 'flex',
					flexDirection: bigScreen ? 'row' : 'column',
				}}
			>
				{bigScreen ? (
					<Tabs
						orientation="vertical"
						variant="standard"
						value={value}
						onChange={handleChangeTabs}
						aria-label="Vertical tabs"
						style={{ minWidth: '15%'}}
						TabIndicatorProps={{ style: { background: themeContext.theme.primary } }}
					>
						<Tab label="Sous-sol" {...a11yProps(0)} />
						<Tab label="Rez de chaussée" {...a11yProps(1)} />
						<Tab label="Premier étage" {...a11yProps(2)} />
						<Tab label="Deuxième étage" {...a11yProps(3)} />
						<Tab label="Troisième étage" {...a11yProps(4)} />
						<Tab label="Quatrième étage" {...a11yProps(5)} />
					</Tabs>
				) : (
					<Select
						value={value}
						onChange={handleChangeSelect}
						displayEmpty
						inputProps={{ 'aria-label': 'Without label' }}
						variant="outlined"
						classes={{ root: classes.buttonRoot }}
						style={{ width: 170 }}
					>
						<MenuItem value={0}>Sous-sol</MenuItem>
						<MenuItem value={1}>Rez de chaussée</MenuItem>
						<MenuItem value={2}>Premier étage</MenuItem>
						<MenuItem value={3}>Deuxième étage</MenuItem>
						<MenuItem value={4}>Troisième étage</MenuItem>
						<MenuItem value={5}>Quatrième étage</MenuItem>
					</Select>
				)}
				{liste.map((plan, index) => (
					<TabPanel value={value} index={index}>
						{/* animation pour alleger l'effet clignotant */}
						<Fade in={value === index}>
							<img
								src={plans[index]}
								style={{
									maxWidth: smallScreen ? '100%' : '80%',
									height: 'auto',
									objectFit: 'contain',
									borderRadius: 4
								}}
							/>
						</Fade>
						<Salles salles={plan.salles} bigScreen={bigScreen} />
					</TabPanel>
				))}
			</div>
		</div>
	)
}
