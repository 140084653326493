//@ts-nocheck
import { makeStyles, Paper, Typography, Divider  } from '@material-ui/core'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { authContext } from '../../Context/AuthContext'

export default function MesInfos(){
    const contextAuth = useContext(authContext)
    const user = contextAuth.authState.user
    const propertyList = {
        displayName: 'Nom/Prénom',
        givenName: 'Prénom',
        surname: 'Nom',
        email: 'Email',
        id: 'Identifiant Microsoft',
        jobTitle: 'Titre Microsoft',
        cafetAdmin: 'Administrateur Caféteria'
    }
    const useStyles = makeStyles((theme) => ({
        cardRoot: {
            textAlign: 'left',
            padding: '20px',
            // boxShadow: 'none',
            width: '70vw',
            marginTop: '10px'
        },
        divider: {
            marginTop: '8px',
            marginBottom: '8px'
        }
    }))
    const classes = useStyles()
    return(
        <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Helmet>
                <title>{`BDE | Mes infos`}</title>
            </Helmet>
            <Paper elevation={0} classes={{root: classes.cardRoot}}>
                <Typography variant='h4'>Mes informations</Typography>
                <Divider classes={{ root: classes.divider }} />
                {user && Object.keys(user).map((property: string, index) => {
                    if (Object.keys(propertyList).includes(property)) return(
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 10,
                            marginTop: 10
                        }}>
                            <Typography variant='body1' style={{ fontWeight: 'bold', flex: 2, textAlign: 'right' }}>
                                {propertyList[property]}
                            </Typography>
                            <Typography variant='body1' style={{ flex: 5, marginLeft: 10 }}>
                                {(property === 'cafetAdmin') ? ((user[property]) ? 'Oui' : 'Non') : user[property]}
                            </Typography>
                        </div>
                    )
                }
                )}
                <Typography style={{ textAlign: 'left'}}>
                    Ces données ne sont pas modifiables et représentent les données accessibles par sharepoint.
                </Typography>
            </Paper>
        </div>
    )
}