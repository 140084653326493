import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import SubNavBar from '../../Components/SubNavBar'
import { useLocation, useHistory, Route } from 'react-router-dom';
import { authContext } from '../../Context/AuthContext';
import Ingredients from './Cafeteria/Ingredients';
import Menus from './Cafeteria/Plats';
import Commander from './Cafeteria/Commander';
import Desk from './Cafeteria/Desk';
import MesCommandes from './Cafeteria/MesCommandes';

export default function Cafeteria(){
    let location = useLocation();

    let history = useHistory()
    if (location.pathname === '/cafeteria') {
        // replace au lieu de push pour pouvoir utiliser le go back du navigateur
        history.replace("/cafeteria/commander")
    }

    const auth = useContext(authContext)

    let cafetRoutes = [
        {
            label: 'Commander',
            value: 'commander',
            url: 'commander'
        },
        {
            label: 'Mes commandes',
            value: 'mescommandes',
            url: 'mescommandes'
        },
    ]
    if (auth.authState.user?.cafetAdmin){
        cafetRoutes.push(
            {
                label: 'Ingrédients',
                value: 'ingredients',
                url: 'ingredients'
            },
            {
                label: 'Plats',
                value: 'plats',
                url: 'plats'
            },
            {
                label: 'Desk',
                value: 'desk',
                url: 'desk'
            },
        )
    }
    return(
        <div>
            <Helmet>
                <title>{`BDE ESEO | CAFET`}</title>
            </Helmet>
            <SubNavBar
                navBarName='Cafeteria'
                prefix='cafeteria'
                routes={cafetRoutes}
            />

            <Route path={'/cafeteria/commander'} render={() =>
                <Commander/>}
            />
            <Route path={'/cafeteria/desk'} render={() =>
                <Desk/>}
            />
            <Route path={'/cafeteria/ingredients'} render={() =>
                <Ingredients/>}
            />
            <Route path={'/cafeteria/plats'} render={() =>
                <Menus/>}
            />
            <Route path={'/cafeteria/mescommandes'} render={() =>
                <MesCommandes/>}
            />

        </div>
    )
}