import {
	Card,
	Divider,
	FormControl,
	makeStyles,
	Snackbar,
	Switch,
	TextField,
	Typography
} from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import { mutate } from 'swr'
import '../../../Assets/Fonts/fonts.css'
import ButtonType from '../../../Components/BoutonType'
import ClubAdminTable from '../../../Components/Clubs/ClubAdminTable'
import UploadButton from '../../../Components/UploadButton'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'
import { ClubInterface } from '../../../Interfaces'
import useLoadedClub from './useLoadedClub'

interface ClubAdminProps {
	clubSelected: ClubInterface | undefined
}

interface ChangedField {
	field: string
	newValue: string | boolean
}

export default function (Props: ClubAdminProps) {
	const [clubLoaded, error, clubName] = useLoadedClub(Props.clubSelected)
	const [clubToUse, setClubToUse] = useState<ClubInterface | undefined>()
	const [saving, setSaving] = useState<'notsaved' | 'saving' | 'saved'>('saved')
	const [changedFields, setChangedFields] = useState<ChangedField[]>([])
	const [postError, setPostError] = useState<string | undefined>()
	const contextAuth = useContext(authContext)

	const [desc, setDesc] = useState<string>('')

	const [urlError, setUrlError] = useState<boolean>(false)

	const adminPost = (url: string, options: FormData | URLSearchParams) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			method: 'POST',
			body: options
		}).then((r) => {
			if (!r.ok) {
				setPostError("Erreur lors de l'action: " + r.statusText)
				throw new Error(r.statusText)
			} else {
				return r
			}
		})

	const saveChanges = () => {
		setSaving('saving')
		// sauvegarde
		console.log('saveugarde diff')

		let searchParams = new URLSearchParams()
		if (clubToUse?.id) searchParams.append('clubId', clubToUse?.id)
		searchParams.append('changes', JSON.stringify(changedFields))

		adminPost(`${contextAuth.apiUrl}/clubs/changeinfos`, searchParams)
			.then((r) => {
				setChangedFields([])
				setSaving('saved')
				mutate(`${contextAuth.apiUrl}/clubs/mine?members=true`)
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		// pour eviter le revalidation des données pendant l'édition
		if (clubLoaded && !clubToUse) setClubToUse({ ...clubLoaded })
	}, [clubLoaded])

	const themeContext = useContext(styleContext)

	const useStyles = makeStyles((theme) => ({
		cardRoot: {
			textAlign: 'left',
			padding: '20px',
			boxShadow: 'none',
			[theme.breakpoints.down('md')]: {
				width: '90vw'
			},
			[theme.breakpoints.up('sm')]: {
				width: '70vw'
			},
			marginTop: '10px'
		},
		typoFont: {
			marginBottom: '4px'
		},
		textFieldRoot: {
			'&.Mui-focused fieldset': {
				// borderColor: 'green',
				borderColor: themeContext.theme.primary
			}
		},
		divider: {
			marginTop: '8px',
			marginBottom: '8px'
		}
	}))

	const classes = useStyles()

	const appendChangedField = (field: string, newValue: string | boolean) => {
		setSaving('notsaved')
		let changes: ChangedField = {
			field,
			newValue
		}
		let tempChangedFields: ChangedField[] = [...changedFields]
		let changed: boolean = false
		for (let index = 0; index < tempChangedFields.length; index++) {
			if (tempChangedFields[index].field === changes.field) {
				tempChangedFields[index] = changes
				changed = true
				break
			}
		}
		if (!changed) tempChangedFields.push(changes)
		setChangedFields(tempChangedFields)
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Helmet>
				<title>{`${clubToUse?.name || 'CLUB'} | Administration`}</title>
			</Helmet>
			<Card classes={{ root: classes.cardRoot }}>
				{clubToUse?.name ? (
					<Typography variant="h5"> {clubToUse.name} | Administration </Typography>
				) : (
					<Skeleton width="50%" height="16px" variant="text" />
				)}

				<FormControl style={{ width: 'inherit', marginTop: '12px' }}>
					<Typography
						variant="caption"
						style={{
							marginBottom: '12px',
							lineHeight: 2,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						Changements appliqués en temps réel seulement pour les membres.
					</Typography>
					<Typography variant="h6" classes={{ root: classes.typoFont }}>
						Administration des membres
					</Typography>

					<ClubAdminTable club={clubToUse} adminPost={adminPost} />

					<Divider classes={{ root: classes.divider }} />

					<Typography variant="h6" classes={{ root: classes.typoFont }}>
						Petite description
					</Typography>

					<TextField
						classes={{ root: classes.textFieldRoot }}
						type="text"
						rowsMax={3}
						rows={3}
						helperText="max 140 caractères"
						inputProps={{
							maxLength: 140
						}}
						multiline={true}
						fullWidth={true}
						variant="outlined"
						onChange={(event) =>
							appendChangedField('shortPresentation', event.target.value)
						}
						defaultValue={clubToUse?.shortPresentation}
					/>

					<Divider classes={{ root: classes.divider }} />

					<Typography variant="h6" classes={{ root: classes.typoFont }}>
						Longue description
					</Typography>

					{/* description */}
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<TextField
							classes={{ root: classes.textFieldRoot }}
							type="text"
							rowsMax={20}
							rows={10}
							helperText="Sera affichée dans la page présentation du club. Supporte le markdown."
							onChange={(event) => {
								appendChangedField('description', event.target.value)
								setDesc(event.target.value)
							}}
							multiline={true}
							fullWidth={true}
							variant="outlined"
							defaultValue={clubToUse?.description}
						/>
						<Typography variant="h6" classes={{ root: classes.typoFont }}>
							Preview
						</Typography>
						<ReactMarkdown>{desc || clubToUse?.description}</ReactMarkdown>
					</div>

					<Divider classes={{ root: classes.divider }} />

					<Typography variant="h6" classes={{ root: classes.typoFont }}>
						Recrutements/Contact
					</Typography>

					<Typography variant="subtitle1" classes={{ root: classes.typoFont }}>
						Recrute:
						<Switch
							onChange={(event) =>
								appendChangedField('recrute', event.target.checked)
							}
							defaultChecked={!clubToUse?.recrute}
						/>
					</Typography>

					<Typography
						variant="subtitle1"
						classes={{ root: classes.typoFont }}
						style={{ display: 'flex', alignItems: 'center', width: '100%' }}
					>
						<div style={{ marginRight: '16px', minWidth: 'fit-content' }}>
							Url de contact:{' '}
						</div>
						<TextField
							error={urlError}
							fullWidth={true}
							label="Url"
							variant="outlined"
							onChange={(event) => {
								if (/^(ftp|http|https):\/\/[^ "]+$/.test(event.target.value)) {
									setUrlError(false)
									appendChangedField('contactUrl', event.target.value)
								} else {
									setUrlError(true)
								}
							}}
							defaultValue={clubToUse?.contactUrl}
						/>
					</Typography>

					<Divider classes={{ root: classes.divider }} />

					<Typography variant="h6" classes={{ root: classes.typoFont }}>
						Images
					</Typography>
					<Typography
						variant="subtitle1"
						style={{ color: 'red', display: 'flex' }}
						classes={{ root: classes.typoFont }}
					>
						<Warning />
						ATTENTION, les uploads sont directement actifs.
					</Typography>
					<Typography variant="subtitle1" classes={{ root: classes.typoFont }}>
						Bannière, mettez quelque chose sans logos qui pourraient être cuts dans des
						ratios différents, une image de "fond". Le logo de votre club ira dans
						l'avatar. Resized en 666x200
					</Typography>
					<UploadButton
						type="clubBanniere"
						adminPost={adminPost}
						clubID={clubToUse?.id}
					/>

					<Typography variant="subtitle1" classes={{ root: classes.typoFont }}>
						Logo, il sera affiché dans un cercle donc essayez de le centrer. Resized en
						200x200
					</Typography>
					<UploadButton type="clubAvatar" adminPost={adminPost} clubID={clubToUse?.id} />
				</FormControl>
			</Card>
			<Snackbar
				open={saving === 'notsaved' || saving === 'saving'}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<ButtonType
					loading={saving === 'saving'}
					style={{ width: 'auto' }}
					onClick={() => saveChanges()}
				>
					{saving === 'notsaved' && 'Sauvegarder'}
				</ButtonType>
			</Snackbar>
			<Snackbar
				open={postError !== undefined}
				autoHideDuration={4000}
				onClose={() => setPostError(undefined)}
			>
				<Alert onClose={() => setPostError(undefined)} severity="error" variant="filled">
					{postError}
				</Alert>
			</Snackbar>
		</div>
	)
}
