import {
	Card,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	makeStyles,
	Radio,
	RadioGroup,
	Snackbar,
	TextField,
	Typography
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import useSWR, { mutate } from 'swr'
import ButtonType from '../../../Components/BoutonType'
import IngredientsTable, { Ingredient } from '../../../Components/Cafeteria/IngredientsTable'
import { authContext } from '../../../Context/AuthContext'
import { styleContext } from '../../../Context/StyleContext'

export default function Ingredients() {
	const [nameIngredient, setNameIngredient] = useState('')
	const [availabilityIngredient, setAvailabilityIngredient] = useState(true)
	const [type, setNewType] = useState<string>('ingredient')
	const [prix, setPrix] = useState<number>(0)

	const [savingNewIngredient, setSavingNewIngredient] = useState<'notsaved' | 'saving' | 'saved'>(
		'notsaved'
	)
	const [stringError, setStringError] = useState<string | undefined>()

	const [focus, setFocus] = useState<Boolean>(false)

	// définition du fetcher pour SWR
	const contextAuth = useContext(authContext)
	const fetcher = (url: string) =>
		fetch(url, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			})
		})
			.then((r) => {
				if (!r.ok) {
					throw new Error(r.statusText)
				} else {
					return r
				}
			})
			.then((r) => r.json())

	const { data: ingredients, error: ingredientsError } = useSWR<Ingredient[]>(
		`${contextAuth.apiUrl}/cafet/allIngredients`,
		fetcher
	)

	//middleware request utilisé dans la table
	const adminPost = (url: string, options: any, method: string) =>
		fetch(`${contextAuth.apiUrl}${url}`, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			method: method,
			body: new URLSearchParams(options)
		}).then((r) => {
			if (!r.ok) {
				setStringError("Erreur lors de l'action: " + r.statusText)
				throw new Error(r.statusText)
			} else {
				mutate(`${contextAuth.apiUrl}/cafet/allIngredients`)
				return r
			}
		})

	const themeContext = useContext(styleContext)
	const useStyles = makeStyles((theme) => ({
		cardRoot: {
			textAlign: 'left',
			padding: '20px',
			backgroundColor: 'transparent',
			boxShadow: 'none',
			filter: themeContext.theme.name === 'dark' ? 'invert(1)' : 'invert(0)',
			[theme.breakpoints.down('md')]: {
				width: '90vw'
			},
			[theme.breakpoints.up('sm')]: {
				width: '70vw'
			},
			marginTop: '10px'
		},
		typoFont: {
			marginBottom: '4px'
		},
		textFieldRoot: {
			marginRight: 20,
			'&.Mui-focused fieldset': {
				// borderColor: 'green',
				borderColor: themeContext.theme.primary
			}
		},
		divider: {
			marginTop: '8px',
			marginBottom: '8px'
		}
	}))

	const classes = useStyles()

	const saveNewIngredient = () => {
		setSavingNewIngredient('saving')
		var urlencoded = new URLSearchParams()
		urlencoded.append('name', nameIngredient)
		urlencoded.append('type', type)
		urlencoded.append('prix', prix.toString())
		urlencoded.append('available', JSON.stringify(availabilityIngredient))
		fetch(`${contextAuth.apiUrl}/cafet/addIngredient`, {
			headers: new Headers({
				Authorization: 'Bearer ' + contextAuth.authState.jwtoken
			}),
			method: 'POST',
			body: urlencoded
		})
			.then((response) => {
				if (!response.ok) {
					setStringError("Erreur lors de l'action: " + response.statusText)
					setSavingNewIngredient('notsaved')
					throw new Error(response.statusText)
				} else return response.json()
			})
			.then((response) => {
				if (response.success) {
					mutate(`${contextAuth.apiUrl}/cafet/allIngredients`)
					setSavingNewIngredient('saved')
				} else setStringError("Erreur lors de l'action: " + response.statusText)
			})
			.catch((response) => console.error(response))
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Helmet>
				<title>{`CAFET | Ingrédients`}</title>
			</Helmet>
			<Card classes={{ root: classes.cardRoot }}>
				<Typography variant="h4">Ingrédients</Typography>
				<FormControl style={{ width: 'inherit', marginTop: '12px' }}>
					<Typography
						variant="caption"
						style={{
							marginBottom: '12px',
							lineHeight: 2,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						Changements appliqués en temps réel.
					</Typography>
					<Typography
						variant="body1"
						style={{
							marginBottom: '12px',
							lineHeight: 2,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						Les ingrédients comptent les boissons mais sont séparées en deux catégories
						(boissons/ingrédients). Rajoutez les dans la bonne catégorie pour ensuite
						pouvoir les sélectionner dans la création de menu.
					</Typography>
					<Typography variant="h5" classes={{ root: classes.typoFont }}>
						La suppression des ingrédients les enlève des menus et ils devront être
						rerajoutés après.
					</Typography>

					<Divider classes={{ root: classes.divider }} />

					<Typography variant="h5" classes={{ root: classes.typoFont }}>
						Ajout d'un élement
					</Typography>
					<Typography variant="h6" classes={{ root: classes.typoFont }}>
						Type
					</Typography>

					<RadioGroup
						style={{ marginLeft: 20 }}
						value={type}
						onChange={(e) => setNewType(e.currentTarget.value)}
					>
						<FormControlLabel
							value="ingredient"
							control={<Radio />}
							label="Ingrédient de plat"
						/>
						<FormControlLabel value="boisson" control={<Radio />} label="Boisson" />
						<FormControlLabel
							value="accompagnement"
							control={<Radio />}
							label="Accompagnement"
						/>
						<FormControlLabel value="sauce" control={<Radio />} label="Sauce" />
					</RadioGroup>

					<Typography variant="h6" classes={{ root: classes.typoFont }}>
						Nom/Prix
					</Typography>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'baseline',
							flexGrow: 1
						}}
					>
						<TextField
							classes={{ root: classes.textFieldRoot }}
							type="text"
							rowsMax={1}
							rows={1}
							helperText="Nom de l'ingrédient"
							inputProps={{
								maxLength: 50
							}}
							multiline={false}
							fullWidth={true}
							variant="outlined"
							label="Nom"
							onChange={(event) => setNameIngredient(event.target.value)}
						/>
						<TextField
							margin="normal"
							variant="outlined"
							id="price"
							label="Prix"
							helperText="Seulement disponible pour boisson/accompagnement"
							type="number"
							disabled={['ingredient', 'sauce'].includes(type)}
							onChange={(event) => setPrix(parseFloat(event.target.value))}
							defaultValue={0}
						/>
					</div>

					<div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
						<Typography variant="h6" classes={{ root: classes.typoFont }}>
							Disponible?:{' '}
						</Typography>
						<Checkbox
							checked={availabilityIngredient}
							disabled={false}
							onClick={() => setAvailabilityIngredient(!availabilityIngredient)}
						/>
					</div>
					<ButtonType
						disabled={nameIngredient === ''}
						loading={savingNewIngredient === 'saving'}
						style={{
							width: 'fit-content'
						}}
						onClick={() => saveNewIngredient()}
					>
						{(savingNewIngredient === 'notsaved' || savingNewIngredient === 'saved') &&
							'Ajouter'}
					</ButtonType>

					<Divider classes={{ root: classes.divider }} />
					<Typography variant="h5" classes={{ root: classes.typoFont }}>
						Gestions des ingrédients/boissons déjà présentes
					</Typography>

					<IngredientsTable ingredients={ingredients || []} adminPost={adminPost} />
				</FormControl>
			</Card>

			<Snackbar
				open={stringError !== undefined}
				autoHideDuration={2000}
				onClose={() => setStringError(undefined)}
			>
				<Alert
					onClose={() => setStringError(undefined)}
					severity="warning"
					variant="filled"
				>
					{stringError}
				</Alert>
			</Snackbar>
		</div>
	)
}
