import { AppBar, IconButton, SwipeableDrawer, Toolbar, useScrollTrigger } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import React, { useState } from 'react'
import Headroom from 'react-headroom'
import { Link, useLocation } from 'react-router-dom'
import '../../Assets/Fonts/fonts.css'
import { ReactComponent as Logo } from '../../Assets/svg/logo.svg'
import CustomDrawer from '../CustomDrawer'
import { styleContext } from '../../Context/StyleContext'
import { COLORS } from '../../constants'

export default function Sidemenu() {
	let location = useLocation()
	const trigger = useScrollTrigger({ target: window })
	const [drawerOpened, toggleDrawer] = useState(false)
	return (
		<styleContext.Consumer>
			{(themeContext) => (
				<div>
					<Headroom style={{ zIndex: 50 }}>
						<AppBar
							position="static"
							style={{
								backgroundColor: COLORS.bg,
								height: 'fit-content',
								padding: '10px 10vw',
								boxShadow: 'none'
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row-reverse',
									justifyContent: 'space-between'
								}}
							>
								<IconButton
									edge="start"
									color="inherit"
									aria-label="menu"
									style={{
										color: COLORS.onBg
									}}
									onClick={() => toggleDrawer(!drawerOpened)}
								>
									<Menu fontSize={'large'} />
								</IconButton>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-around',
										alignItems: 'center'
									}}
								>
									<Link
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											alignItems: 'center',
											gap: '10px'
										}}
										
										to="/home"
									>
										<Logo
											style={{
												height: '54px',
												width: 'auto'
											}}
											fill={COLORS.onBg}
										/>
									</Link>
								</div>
							</div>
						</AppBar>
					</Headroom>
					<SwipeableDrawer
						anchor={'right'}
						onOpen={() => toggleDrawer(!drawerOpened)}
						open={drawerOpened}
						onClose={() => toggleDrawer(!drawerOpened)}
						style={{ position: 'absolute' }}
					>
						<CustomDrawer closeDrawer={() => toggleDrawer(!drawerOpened)} />
					</SwipeableDrawer>
				</div>
			)}
		</styleContext.Consumer>
	)
}
