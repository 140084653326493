import { Button, CircularProgress } from '@material-ui/core'
import { CloudUpload, FileCopy } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { mutate } from 'swr'
import { authContext } from '../Context/AuthContext'
import { styleContext } from '../Context/StyleContext'
import { FileUpload } from '../Interfaces'

interface UploadButton{
    type: 'avatar' | 'clubAvatar' | 'clubBanniere' | 'postImage',
    adminPost: (url: string, options: FormData | URLSearchParams) => Promise<Response>,
    clubID?: string
    onSuccess?: (file: FileUpload) => void
}

export default function UploadButton(Props: UploadButton){

    const themeContext = useContext(styleContext)
    const contextAuth = useContext(authContext)
    const [selectedFile, setSelectedFile] = useState<File|undefined>()
    const [loading, setLoading] = useState<boolean>(false)

    const handleUpload = (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if(!selectedFile) return
        setLoading(true)
        let formData: FormData = new FormData()
        formData.append("file", selectedFile, selectedFile?.name)
        formData.append("type", Props.type);
        if(Props.clubID) formData.append("clubID", Props.clubID);
        Props.adminPost(`${contextAuth.apiUrl}/upload/uploadFile`, formData)
            .then((res) => res.json())
            .then((res) => {
                if(Props.onSuccess) Props.onSuccess(res)
            })
            .then(() => setLoading(false))
            .then(() => setSelectedFile(undefined))
            .then(() => { if (['clubBanniere', 'clubAvatar'].includes(Props.type)) mutate(`${contextAuth.apiUrl}/clubs/mine`)})
            .catch((err) => console.log(err))
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(selectedFile !== undefined){
            handleUpload()
            return
        }
        if (event.target?.files) setSelectedFile(event.target?.files[0])
    }

    return(
        <div>
            <input
                accept="image/*"
                id={"icon-button-file-"+Props.type}
                type="file"
                onChange={handleFileChange}
                disabled={selectedFile !== undefined}
                style={{
                    display: 'none',
                }}
            />
            <label htmlFor={"icon-button-file-" + Props.type}>
                <Button 
                    variant="contained"
                    style={{
                        backgroundColor: !selectedFile ? themeContext.theme.buttonFocus : themeContext.theme.primary,
                        color: "white",
                    }}  
                    aria-label="upload picture" 
                    component="span" 
                    startIcon={loading ? <CircularProgress /> : (!selectedFile ? <FileCopy/> : <CloudUpload />)}
                    onClick={handleUpload}
                >
                    {!selectedFile ? "Selectionner un fichier" : "Upload"}
                </Button>
            </label>


        </div>
    )
}