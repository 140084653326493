import React, { useContext } from 'react'
import Appbar from './Appbar/Appbar'
import { Tabs, AppBar, Tab, makeStyles, useMediaQuery } from '@material-ui/core'
import { styleContext } from '../Context/StyleContext'
import { Link, useLocation } from 'react-router-dom'
import '../Assets/Fonts/fonts.css'

interface route {
	label: string
	value: string
	url: string
}

interface SubNavBarProps {
	prefix: string
	navBarName?: string
	routes: route[]
}
export default function SubNavBar(Props: SubNavBarProps) {
	let location = useLocation()

	const themeContext = useContext(styleContext)

	const smallScreen = useMediaQuery('(max-width:600px)')

	const useStyles = makeStyles((theme) => ({
		appbarRoot: {
			backgroundColor: themeContext.theme.backgroundColor,
			color: themeContext.theme.primary,
			textAlign: 'center',
			borderRadius: '0 0 15px 15px'
		},
		appbarFlexContainer: {
			justifyContent: smallScreen ? 'left' : 'center'
		},
		appbarIndicator: {
			backgroundColor: themeContext.theme.primary
		},
		appbarWrapper: {
			fontSize: 18,
			fontWeight: 300
		}
	}))

	const classes = useStyles()

	return (
		<AppBar
			position="static"
			style={{
				backgroundColor: themeContext.theme.backgroundColor,
				borderRadius: '0 0 15px 15px',
				boxShadow: 'none'
			}}
		>
			<Tabs
				value={location.pathname.split('/')[2]}
				aria-label={Props.navBarName || 'Navbar'}
				scrollButtons="auto"
				variant="scrollable"
				classes={{
					root: classes.appbarRoot,
					indicator: classes.appbarIndicator,
					flexContainer: classes.appbarFlexContainer
				}}
			>
				{Props.routes.map((route, index) => (
					<Tab
						key={index}
						className={'notDraggable'}
						disableRipple={true}
						label={route.label}
						value={route.value}
						component={Link}
						to={`/${Props.prefix}/${route.url}`}
						classes={{ wrapper: classes.appbarWrapper }}
					/>
				))}
			</Tabs>
		</AppBar>
	)
}
